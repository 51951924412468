import React, {FC, useCallback, useState} from "react";
import Api from "util/api/Api";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {Body1, Body2, Heading1} from "@unipal/ui/typography/Typography";
import {useResources} from "contexts/ResourcesProvider";
import {Dialog, DialogContent, Divider} from "@mui/material";
import colors from "res/colors";
import Button from "@unipal/ui/button/Button";
import Branch from "models/Branch";
import {DataPaginationOutput} from "@unipal/models/DataPagination";
import DynamicDataTable from "@unipal/common/DynamicDataTable";
import ImportGoogleMapsLocationsControl from "components/branch/ImportGoogleMapsLocationsControl";
import UpsertBranchControl from "components/branch/UpsertBranchControl";
import {MapOutlined} from "@mui/icons-material";
import PlusIcon from "@unipal/icons/PlusIcon";
import DeleteConfirmation from "@unipal/common/DeleteConfirmation";

const BranchesList: FC = () => {
    const {strings} = useResources()

    const [branches, setBranches] = useState<DataPaginationOutput<Branch>>()
    const [branch, setBranch] = useState<Branch>()

    const load = useCallback(async () => {
        const data = await Api.branches.getBranchesAsync()
        if (data) {
            setBranches({data: data, total: data.length})
        }
    }, [])

    const addBranch = () => setBranch({id: "", name: '', altName: ""})

    const onImportBranches = (branches: Branch[]) => {
        setBranches(ps => ps ?
            {data: [...ps.data, ...branches], total: ps.total + branches.length} :
            {data: branches, total: branches.length}
        )
    }

    function RenderRow(branch: Branch): (string | JSX.Element)[] {
        const btn = branch.latitude && branch.longitude ?
            <a target="_blank"
               href={`https://maps.google.com/?q=${branch.latitude},${branch.longitude}`}
               rel="noreferrer"
               onClick={e => e.stopPropagation()}>
                <Button
                    startIcon={<MapOutlined/>}
                    variant={"outlined"}>
                    {strings("ViewInMap")}
                </Button>
            </a> : ''

        return [
            <HorizontalFlexLayout
                spacing={2}
                wrap={"nowrap"}
                alignItems={"center"}>
                <GridItem sx={{flexGrow: 1}}>
                    <Body1>
                        {branch.name}
                        {branch.altName && ` | ${branch.altName}`}
                    </Body1>
                </GridItem>
                {btn}
                <DeleteConfirmation
                    onConfirm={async () => {
                        await Api.branches
                            .deleteBranchAsync(branch.id)
                            .then(() => {
                                setBranches(ps => ps ? {
                                    total: ps.total - 1,
                                    data: ps.data.filter(d => d.id !== branch.id)
                                } : undefined)
                            })
                    }}/>
            </HorizontalFlexLayout>
        ]
    }

    return (<>
        <VerticalFlexLayout spacing={4}>
            <HorizontalFlexLayout
                spacing={2}
                wrap={"nowrap"}
                alignItems={"center"}>
                <img
                    src={`${process.env.PUBLIC_URL}/assets/store-icon.png`}
                    style={{width: 56}}
                    alt={"store icon"}/>
                <Heading1>
                    {strings("ManageBranchesPageTitle")}
                </Heading1>
            </HorizontalFlexLayout>
            <Body2
                whiteSpace={"pre-wrap"}
                color={colors.copy}>
                {strings("ManageBranchesPageMessage")}
            </Body2>
            <Divider/>
            <ImportGoogleMapsLocationsControl
                onComplete={onImportBranches}/>
            <Divider/>
            <DynamicDataTable<Branch>
                total={branches?.total ?? 0}
                data={branches?.data ?? []}
                columns={[]}
                rowsPerPage={branches?.total}
                renderRow={p => RenderRow(p)}
                load={load}
                onClick={b => setBranch(b)}/>
            <Button
                fullWidth
                variant={"contained"}
                startIcon={<PlusIcon color={"white"}/>}
                onClick={addBranch}>
                {strings("AddBranch")}
            </Button>
        </VerticalFlexLayout>

        {branch &&
            <Dialog
                open
                fullWidth
                maxWidth={"sm"}>
                <DialogContent>
                    <UpsertBranchControl
                        branch={branch}
                        onClose={() => setBranch(undefined)}
                        onComplete={b => {
                            if (branch?.id) {
                                setBranches(ps => ps ? {
                                    total: ps.total,
                                    data: ps.data.map(d => d.id === branch.id ? b : d)
                                } : undefined)
                            } else {
                                onImportBranches([b])
                            }

                            setBranch(undefined)
                        }}/>
                </DialogContent>
            </Dialog>}
    </>)
}

export default BranchesList
