import BaseApi from "./BaseApi";
import StudentVerificationResult from "viewModels/StudentVerificationResult";
import appSettings from "appSettings";

class StudentApi extends BaseApi {
    async verifyStudentAsync(cardNumber: string):
        Promise<StudentVerificationResult | undefined> {
        const path = `${appSettings.urls.verifyStudent}?value=${cardNumber}`
        return this.get(path)
    }
}

export default StudentApi
