import React, {createRef, FC, useState, DragEvent, ChangeEvent} from "react";
import {Body1} from "@unipal/ui/typography/Typography";
import Button from "@unipal/ui/button/Button";
import UploadIcon from "@unipal/icons/UploadIcon";
import {useTheme} from "@unipal/contexts/ThemeProvider";

interface Props {
    action: string
    message: string
    onChange: (files: FileList) => Promise<void>
    accept?: string
    multiple?: boolean
}

const DragDropControl: FC<Props> = (props) => {
    const {colors} = useTheme()

    const [dragActive, setDragActive] = useState(false)
    const fileInputRef = createRef<HTMLInputElement>()

    const onSelectImageClick = () => fileInputRef.current?.click()

    const handleDrag = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true)
        } else if (e.type === "dragleave") {
            setDragActive(false)
        }
    }

    const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            await handleFiles(e.dataTransfer.files)
        }
    }

    const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            await handleFiles(e.target.files)
        }
    }

    const handleFiles = async (files: FileList) => await props.onChange(files)

    return (
        <div
            id="file-input-container"
            onDragEnter={handleDrag}>
            <input
                id="file-input"
                ref={fileInputRef}
                type="file"
                accept={props.accept}
                multiple={props.multiple}
                onChange={handleChange}/>
            <label
                id="file-input-label"
                htmlFor="file-input"
                className={dragActive ? "drag-active" : ""}>
                <div>
                    <Button
                        variant={"outlined"}
                        size={"small"}
                        endIcon={<UploadIcon size={16}/>}
                        onClick={onSelectImageClick}>
                        {props.action}
                    </Button>
                    <Body1
                        color={colors.copy}
                        paddingTop={3}>
                        {props.message}
                    </Body1>
                </div>
            </label>
            {dragActive &&
            <div
                id="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}/>}
        </div>
    )
}

export default DragDropControl
