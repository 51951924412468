import React from "react"
import {TextField} from "@mui/material"
import {TextFieldProps} from "@mui/material/TextField/TextField"
import {FieldError} from "react-hook-form/dist/types/errors";
import {Body2} from "@unipal/ui/typography/Typography";
import {useTheme} from "@unipal/contexts/ThemeProvider";

type Props = TextFieldProps & {
    fieldError?: Partial<FieldError>
}

const InputField: React.FC<Props> = (props) => {
    const {colors} = useTheme()
    const {fieldError, ...textFieldProps} = props
    const bg = textFieldProps.disabled ? colors.disabledFill : undefined
    const color = textFieldProps.disabled ? colors.gray : colors.text

    return (<>
        {props.label &&
        <Body2
            color={color}
            marginBottom={1}>
            <strong>
                {props.label}
            </strong>
        </Body2>}
        <TextField
            fullWidth
            variant="outlined"
            {...textFieldProps}
            InputProps={{style: {backgroundColor: bg}}}
            error={Boolean(fieldError)}
            helperText={fieldError?.message}
            label={undefined}/>
    </>)
}

export default InputField
