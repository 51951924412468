import {FC} from "react";

interface Props {
    color?: string;
}

const HaibaIcon: FC<Props> = (props) => {
    const color = props.color || "#F8B133";

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.225 7.1771C16.489 6.82073 17.0297 7.04155 16.9987 7.49303L16.5084 14.6281C16.4919 14.8678 16.3032 15.0533 16.076 15.0533H8.09443C7.87135 15.0533 7.68469 14.8743 7.66295 14.6396L7.00231 7.50448C6.96039 7.0518 7.50021 6.81783 7.7708 7.17139L10.0145 10.103L11.5654 7.23238C11.7307 6.92647 12.1469 6.92381 12.3156 7.22758L13.9751 10.2146L16.225 7.1771ZM15.6728 14.1364L16.0309 8.92616L14.2651 11.31C14.0765 11.5646 13.7048 11.5383 13.5499 11.2596L11.9471 8.37431L10.4528 11.1401C10.3015 11.4201 9.93124 11.4518 9.73935 11.2011L8.00613 8.93639L8.4876 14.1364H15.6728Z"
                fill={color}/>
            <path
                d="M8.19722 16.9999H16.1961C16.4356 16.9999 16.6297 16.7947 16.6297 16.5415C16.6297 16.2883 16.4356 16.083 16.1961 16.083H8.19722C7.95778 16.083 7.76367 16.2883 7.76367 16.5415C7.76367 16.7947 7.95778 16.9999 8.19722 16.9999Z"
                fill={color}/>
            <circle cx="12" cy="12" r="11" stroke={color} strokeWidth="2"/>
        </svg>
    );
};

export default HaibaIcon;