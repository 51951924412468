import React, {FC} from 'react';
import {Box, Button, Container, Typography} from "@mui/material";
import {VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import {useResources} from "contexts/ResourcesProvider";

const SuccessIntegration: FC = () => {
    const {strings} = useResources()

    return (<>
        <HtmlMetaDecorator title={strings("IntegrationSuccessPageTitle")}/>

        <div className={"login-page-bg"}>
            <div className={"login-page-content"}>
                <Container maxWidth="xs">
                    <Box px={2} py={4} my={2} style={{backgroundColor: "white", borderRadius: 12}}>
                        <VerticalFlexLayout spacing={4} alignItems={"center"}>
                            <Typography variant={"h4"} align={"center"}>
                                {strings("IntegrationSuccessPageTitle")}
                            </Typography>
                            <Typography variant={"body1"} align={"center"}>
                                {strings("IntegrationSuccessPageMessage")}
                            </Typography>
                            <Button
                                size={"large"}
                                variant={"contained"}
                                fullWidth
                                style={{textTransform: "none"}}>
                                <strong>
                                    {strings("ReturnToDashboard")}
                                </strong>
                            </Button>
                        </VerticalFlexLayout>
                    </Box>
                </Container>
            </div>
        </div>
    </>)
}

export default SuccessIntegration
