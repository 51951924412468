import React, {FC, useEffect, useState} from 'react';
import {Box, Button, Container, Typography} from "@mui/material";
import LoginControl from "components/account/LoginControl";
import {useAuth} from "contexts/AuthProvider";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import {useResources} from "contexts/ResourcesProvider";
import GlobeIcon from "components/svgs/GlobeIcon";
import colors from "res/colors";
import ChangeLanguageDialog from "components/common/ChangeLanguageDialog";
import i18n from "i18next";
import languages from "res/languages";
import {HorizontalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {useNavigate} from "react-router-dom";

const Login: FC = () => {
    const navigate = useNavigate()
    const {identity} = useAuth();
    const {strings} = useResources()

    const [open, setOpen] = useState(false)

    useEffect(() => {
        navigate("/")
    }, [identity.isAuthenticated, navigate])

    return (<>
        <HtmlMetaDecorator
            title={strings("Login")}/>

        <div className={"login-page-bg"}>
            <div className={"login-page-content"}>
                <Container maxWidth="xs">
                    <Box pt={8} pb={5} my={2} style={{
                        backgroundColor: "white",
                        borderRadius: 12,
                        position: "relative"
                    }}>
                        <LoginControl/>
                        <HorizontalFlexLayout style={{
                            position: "absolute",
                            top: 0,
                            textTransform: "none"
                        }}>
                            <Box px={2} pt={2}>
                                <Button
                                    startIcon={<GlobeIcon/>}
                                    onClick={() => setOpen(true)}>
                                    <Typography
                                        color={colors.text}
                                        variant={"body1"}>
                                        <strong>
                                            {languages.find(l => l.code === i18n.language)?.name ?? "English"}
                                        </strong>
                                    </Typography>
                                </Button>
                            </Box>
                        </HorizontalFlexLayout>
                    </Box>
                </Container>
            </div>
        </div>

        <ChangeLanguageDialog
            open={open}
            onChange={() => setOpen(false)}/>
    </>)
}

export default Login
