import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {TableFooter, TablePagination, TableSortLabel, Typography} from "@mui/material";
// import strings from "data/local/strings";
import PaginationActions from "@unipal/common/PaginationActions";
import {useTheme} from "@unipal/contexts/ThemeProvider";

type SortDirection = 'asc' | 'desc'

export interface Column<T> {
    id: keyof T
    label: string
}

interface Props<T> {
    total: number
    data: T[]
    size?: "small" | "medium"
    load: (limit: number, offset: number, sortBy?: keyof T, sortDirection?: SortDirection) => Promise<void>
    columns: Column<T>[]
    renderRow: (rowData: T, index: number, data?: T[]) => (string | JSX.Element)[]
    rowsPerPage?: number
    sortBy?: keyof T
    sortDirection?: SortDirection
    onClick?: (item: T) => void
    showPaging?: boolean
    showDivider?: boolean
}

const DynamicDataTable = <T extends any>(props: Props<T> & { children?: ReactNode }) => {
    const {colors} = useTheme()

    const [limit] = useState(props.rowsPerPage ?? 10)
    const [page, setPage] = useState(0)
    const [sortDirection, setSortDirection] = useState<SortDirection>(props.sortDirection ?? 'asc')
    const [sortBy, setSortBy] = useState<keyof T | undefined>(props.sortBy)
    const [isLoading, setIsLoading] = useState(false)

    const _load = props.load

    const load = useCallback(async () => {
        setIsLoading(true)
        await _load(limit, limit * page, sortBy, sortDirection)
        setIsLoading(false)
    }, [limit, page, sortBy, sortDirection, _load])

    useEffect(() => {
        void async function fetchData() {
            await load()
        }()
    }, [load])

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage)
    }

    const handlerSorting = (newSortBy: keyof T) => () => {
        const dir = sortBy === newSortBy && sortDirection === "asc" ? "desc" : "asc"
        setSortDirection(dir)
        setSortBy(newSortBy)
        setPage(0)
    }

    const rowsPerPage = props.rowsPerPage ?? 5
    // NOTE: Used to add empty space when reaching the last page and the available row are less than the rowsPerPage.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (props.total ?? 0)) : 0;

    return (<>
        <TableContainer sx={{position: 'relative'}}>
            {/* NOTE: This is used to block the interaction with the table while data are loading. */}
            {isLoading && <div style={{width: '100%', height: '100%', position: 'absolute', zIndex: 1}}/>}
            <Table size={props.size}>
                <TableHead>
                    <TableRow>
                        {props.columns.map(c => (
                            <TableCell>
                                <TableSortLabel
                                    active={sortBy === c.id}
                                    direction={sortDirection}
                                    onClick={handlerSorting(c.id)}>
                                    <strong>{c.label}</strong>
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data?.map((r, i) =>
                        // TODO: Add the 'key' and allow the parent to set the 'key' to be able to manage the row.
                        <TableRow
                            sx={{
                                cursor: props.onClick ? "pointer" : "default",
                                '&:hover': {backgroundColor: props.onClick ? colors.primary + "18" : "inherit"},
                                '&:last-child td, &:last-child th': {border: 0}
                            }}
                            onClick={() => {
                                if (props.onClick) {
                                    props.onClick(r)
                                }
                            }}>
                            {props.renderRow(r, i + (page * rowsPerPage)).map(i =>
                                <TableCell
                                    align={"left"}
                                    sx={{
                                        whiteSpace: "pre-line",
                                        borderBottom: props.showDivider === false ? "none" : undefined
                                    }}>
                                    {i}
                                </TableCell>
                            )}
                        </TableRow>
                    )}

                    {!isLoading && !props.total &&
                        <TableRow style={{height: 53}}>
                            <TableCell colSpan={props.columns.length}>
                                <Typography component={"p"} variant={"caption"} align={"center"}>
                                    {/* TODO: Localize it. */}
                                    No data are available!
                                </Typography>
                            </TableCell>
                        </TableRow>}

                    {emptyRows > 0 && (
                        <TableRow style={{height: 53 * emptyRows}}>
                            <TableCell colSpan={props.columns.length}/>
                        </TableRow>
                    )}
                </TableBody>

                {(props.showPaging !== false && props.total > rowsPerPage) &&
                    <TableFooter>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                rowsPerPage={rowsPerPage}
                                count={props.total}
                                page={page}
                                onPageChange={handleChangePage}
                                ActionsComponent={PaginationActions}/>
                        </TableRow>
                    </TableFooter>}
            </Table>
        </TableContainer>
    </>)
}

export default DynamicDataTable
