import React, {createContext, FC, PropsWithChildren, useContext, useState} from "react";
import Colors from "@unipal/types/Colors";
import colors from "@unipal/res/colors";

interface Props {
    colors?: Colors
}

interface ThemeContext {
    colors: Colors
}

export const themeContext = createContext<ThemeContext>({
    colors: colors
})

export const useTheme = () => useContext(themeContext)

const ThemeProvider: FC<PropsWithChildren<Props>> = (props) => {
    const [_colors] = useState<Colors>(props.colors ?? colors);

    return (
        <themeContext.Provider value={{colors: _colors}}>
            {props.children}
        </themeContext.Provider>
    )
}

export default ThemeProvider
