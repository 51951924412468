import React, {ChangeEvent, FC, useEffect, useState} from "react";
import Api from "util/api/Api";
import VoucherVerificationResult from "viewModels/VoucherVerificationResult";
import {Avatar, Box, Button, Collapse, Dialog, Grid, IconButton, TextField, Theme, Typography} from "@mui/material";
import {HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import Loading from "components/common/Loading";
import {enqueueSnackbar} from "util/SnackbarManager";
import colors from "res/colors";
import {CheckCircleOutlineRounded, Close, ExpandMore} from "@mui/icons-material";
import clsx from "clsx";
import {createStyles, makeStyles} from "@mui/styles";
import VoucherIcon from "components/svgs/VoucherIcon";
import {useResources} from "contexts/ResourcesProvider";
import {getCurrency} from "util/Utilities";
import {useMerchantAppData} from "contexts/MerchantAppDataProvider";

interface Props {
    code: string;
    onComplete: () => void;
}

const VerifyStudentControl: FC<Props> = (props) => {
    const appData = useMerchantAppData()
    const {strings} = useResources()

    const [voucher, setVoucher] = useState<VoucherVerificationResult>()
    const [isLoading, setIsLoading] = useState(false)
    const [saving, setSaving] = useState(0)
    const [savingError, setSavingError] = useState("")

    const [collapsed, setCollapsed] = React.useState(true)
    const toggleCollapse = () => setCollapsed(!collapsed)

    const classes = makeStyles((theme: Theme) =>
        createStyles({
            box: {
                border: `2px solid ${colors.primary}`,
                borderRadius: 8,
                backgroundColor: "white",
            },
            expand: {
                transform: 'rotate(180deg)',
                marginLeft: 'auto',
                color: colors.primary,
                transition: theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                }),
            },
            expandOpen: {
                transform: 'rotate(0deg)',
            }
        }),
    )()

    useEffect(() => {
        void async function fetchData() {
            const data = await Api.voucher.verifyVoucherAsync(props.code) as VoucherVerificationResult;
            data ? setVoucher(data) : props.onComplete();
        }()
    }, [props])

    const handleSavingChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setSaving(Number(event.target.value))
    }

    const confirmPurchase = async () => {
        const amount = voucher!.amount > 0 ? voucher!.amount : saving

        if (!amount) {
            setSavingError(strings("EnterValidAmount"))
            return
        }

        setSavingError("")
        setIsLoading(true)

        const purchaseId = await Api.voucher.redeemVoucherAsync(voucher!.id, amount)
        if (purchaseId) {
            enqueueSnackbar({
                message: strings(
                    "RedeemVoucherSuccessMessage_Amount",
                    `${saving.toFixed(3)} ${getCurrency(appData.shop.countryCode, strings)}`
                ),
                type: "success"
            })

            props.onComplete()
        }

        setIsLoading(false)
    }

    return (
        <Dialog fullWidth maxWidth={"xs"} open={true}
                style={{backdropFilter: "blur(10px)", WebkitBackdropFilter: "blur(10px)"}}>
            <Box p={2}>
                {!voucher ?
                    <HorizontalFlexLayout justify={"center"}>
                        <Loading size={"small"}/>
                    </HorizontalFlexLayout>
                    :
                    <VerticalFlexLayout spacing={3}>
                        <HorizontalFlexLayout justify={"flex-end"}>
                            <IconButton size={"small"} onClick={props.onComplete}>
                                <Close color={"disabled"}/>
                            </IconButton>
                        </HorizontalFlexLayout>

                        <HorizontalFlexLayout spacing={2} alignItems={"center"} justify={"center"}>
                            <Avatar alt={voucher.studentName} src={voucher.studentImage}
                                    style={{width: 100, height: 100}}/>
                            <VerticalFlexLayout spacing={0}>
                                <Typography variant="h6">
                                    <strong>{voucher.studentName}</strong>
                                </Typography>
                                <Typography variant="h6">
                                    {voucher.studentCardNumber}
                                </Typography>
                                <HorizontalFlexLayout wrap={"nowrap"} alignItems={"center"} spacing={1}>
                                    <CheckCircleOutlineRounded style={{color: colors.success}}/>
                                    <Typography variant="body1" style={{color: colors.success}}>
                                        {strings("Verified")}
                                    </Typography>
                                </HorizontalFlexLayout>
                            </VerticalFlexLayout>
                        </HorizontalFlexLayout>

                        <Box p={2} className={classes.box} onClick={toggleCollapse}>
                            <VerticalFlexLayout spacing={2}>
                                <HorizontalFlexLayout wrap={"nowrap"} spacing={1} alignItems={"center"}>
                                    <VoucherIcon/>
                                    <Grid item style={{flexGrow: 1}}>
                                        <Typography variant="body1">
                                            <strong>{strings("Voucher")} ({voucher.code})</strong>
                                        </Typography>
                                    </Grid>
                                    <ExpandMore className={clsx(classes.expand,
                                        {[classes.expandOpen]: collapsed})}/>
                                </HorizontalFlexLayout>

                                <VerticalFlexLayout spacing={1}>
                                    <Typography variant="body1">
                                        <strong>{strings("Offer")}:</strong>
                                    </Typography>
                                    <Typography variant="body1" color={colors.copy} style={{whiteSpace: "pre-wrap"}}>
                                        {voucher.name}
                                    </Typography>
                                </VerticalFlexLayout>

                                {/* TODO: Don't show the Grid Item if collapsed. */
                                    voucher.terms &&
                                    <Collapse in={!collapsed} timeout="auto">
                                        <Box pt={1}>
                                            <VerticalFlexLayout spacing={1}>
                                                <Typography variant="body1">
                                                    <strong>{strings("Details")}:</strong>
                                                </Typography>
                                                <Typography variant="body1" color={colors.copy}
                                                            style={{whiteSpace: "pre-wrap"}}>
                                                    {voucher.terms}
                                                </Typography>
                                            </VerticalFlexLayout>
                                        </Box>
                                    </Collapse>
                                }
                            </VerticalFlexLayout>
                        </Box>

                        <VerticalFlexLayout spacing={1}>
                            <Typography variant="body1">
                                <strong>{strings("SavingAmount")}:</strong>
                            </Typography>

                            {voucher.amount ?
                                <HorizontalFlexLayout spacing={1} justify={"space-between"}>
                                    <Typography variant="body1" color={colors.copy}>
                                        <strong>{voucher.amount.toFixed(3)}</strong>
                                    </Typography>
                                    <Typography variant="body1" color={colors.copy}>
                                        <strong>{getCurrency(appData.shop.countryCode, strings)}</strong>
                                    </Typography>
                                </HorizontalFlexLayout>
                                :
                                <TextField
                                    variant={"outlined"}
                                    placeholder={"0.000"}
                                    inputMode={"decimal"}
                                    fullWidth
                                    type={"number"}
                                    onChange={handleSavingChange}
                                    error={Boolean(savingError)}
                                    helperText={Boolean(savingError) ? savingError : ''}
                                    InputProps={{
                                        endAdornment: (
                                            <Typography variant="body1" color={"textSecondary"}>
                                                <strong>{getCurrency(appData.shop.countryCode, strings)}</strong>
                                            </Typography>
                                        )
                                    }}/>
                            }
                        </VerticalFlexLayout>

                        <Button
                            color={"error"}
                            variant={"outlined"}
                            size={"large"}
                            fullWidth
                            onClick={props.onComplete}
                            style={{
                                textTransform: "none",
                                fontWeight: 700,
                                minHeight: 64,
                                fontSize: 18
                            }}>
                            {strings("Cancel")}
                        </Button>

                        {isLoading ?
                            <HorizontalFlexLayout justify={"center"}>
                                <Loading size={"small"}/>
                            </HorizontalFlexLayout>
                            :
                            <Button
                                color={"primary"}
                                variant={"contained"}
                                size={"large"}
                                fullWidth
                                onClick={confirmPurchase}
                                style={{
                                    textTransform: "none",
                                    fontWeight: 700,
                                    minHeight: 64,
                                    fontSize: 18
                                }}>
                                {strings("ConfirmPurchase")}
                            </Button>
                        }
                    </VerticalFlexLayout>
                }
            </Box>
        </Dialog>
    )
}

export default VerifyStudentControl
