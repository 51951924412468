import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import React, {ChangeEvent, FC, useState} from 'react';
import LocationIcon from "components/svgs/LocationIcon";
import {useMerchantAppData} from "contexts/MerchantAppDataProvider";
import VerifyStudentControl from "merchant/components/VerifyStudentControl";
import {useAuth} from "contexts/AuthProvider";
import colors from "res/colors";
import {
    Box, Button, Collapse, Dialog, DialogActions,
    DialogTitle, TextField, Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import VerifyVoucherControl from "merchant/components/VerifyVoucherControl";
import {useResources} from "contexts/ResourcesProvider";
import appSettings from "appSettings";
import GtsAliveVerificationControl from "merchant/components/GtsAliveVerificationControl";
import OffersList from "components/offer/OffersList";
import {useNavigate} from "react-router-dom";
import ProfileIcon from "components/svgs/ProfileIcon";

type Stage = "Home" | "VerifyStudent" | "VerifyVoucher"

const useStyles = makeStyles(() => ({
    verifyInput: {
        border: "none",
        textAlign: "center",
        textTransform: "uppercase"
    },
    verifyBtn: {
        borderRadius: 0,
        textTransform: "none",
        fontWeight: 700,
        minHeight: 64,
        fontSize: 18
    }
}))

const Index: FC = () => {
    const auth = useAuth()
    const data = useMerchantAppData()
    const {strings} = useResources()
    const navigate = useNavigate()
    const classes = useStyles()

    const [stage, setStage] = useState<Stage>("Home")
    const [value, setValue] = useState("")
    const [cardNumber, setCardNumber] = useState("")
    const [showIdInput, setShowIdInput] = React.useState(false)
    const [showLogout, setShowLogout] = React.useState(false)

    const toggleIdInput = () => {
        setShowIdInput(ps => !ps)
        setValue("")
    };

    const toggleLogout = async () => {
        if (auth.identity.roles.some(r => r === "ShopAdmin") || data.user.isAdmin) {
            await navigate("/dashboard");
        } else {
            setShowLogout(ps => !ps);
        }
    }

    const logout = async () => {
        setShowLogout(ps => !ps);
        auth.clearAuth();
        await navigate("/account/login");
    }

    const handleValueChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.replace(" ", "");

        if (value.length <= appSettings.cardNumber.length) {
            setValue(value)
        }

        if (value.length === appSettings.cardNumber.length) {
            setStage(value.startsWith('9') ? "VerifyVoucher" : "VerifyStudent")
            setCardNumber(value)
            setValue("")
            setShowIdInput(false)
        }
    }

    const hasOffers = Boolean(data.offers.length)

    return (<>
        <HtmlMetaDecorator title={strings("StudentVerification")}/>

        <VerticalFlexLayout style={{height: "100%", backgroundColor: "white"}}>
            <GridItem item style={{flex: 1, overflow: "overlay"}}>
                <Box p={2}>
                    <VerticalFlexLayout spacing={2}>
                        <HorizontalFlexLayout spacing={1} wrap={"nowrap"}>
                            <GridItem style={{flexGrow: 1}}>
                                <Typography variant="h6">
                                    <strong>{data.shop.name}</strong>
                                </Typography>
                            </GridItem>
                            <Box onClick={toggleLogout} style={{cursor: "pointer"}}>
                                <ProfileIcon size={32} color={colors.primary}/>
                            </Box>
                        </HorizontalFlexLayout>

                        <GridItem>
                            {data.user.branchName &&
                                <HorizontalFlexLayout spacing={1} alignItems={"center"}>
                                    <LocationIcon/>
                                    <Typography variant="body1">
                                        {data.user.branchName}
                                    </Typography>
                                </HorizontalFlexLayout>}
                        </GridItem>

                        <OffersList
                            offers={data.offers}/>
                    </VerticalFlexLayout>
                </Box>
            </GridItem>

            <GridItem style={{flex: 0}}>
                <Box p={2}>
                    <Box sx={{
                        borderRadius: 1,
                        backgroundColor: "white",
                        border: `2px solid ${hasOffers ? colors.primary : colors.gray}`
                    }}>
                        {hasOffers &&
                            <Collapse in={showIdInput} timeout="auto" unmountOnExit>
                                <TextField
                                    onChange={handleValueChange}
                                    autoFocus={showIdInput}
                                    type={appSettings.cardNumber.type}
                                    inputMode={appSettings.cardNumber.type === "number" ? "numeric" : "text"}
                                    value={value}
                                    className={"force-ltr"}
                                    placeholder={strings("EnterVerificationValuePlaceholder")}
                                    variant={"outlined"}
                                    size={"medium"}
                                    fullWidth
                                    InputProps={{
                                        inputProps: appSettings.id === "unipal" ?
                                            {min: 0, step: 1} : undefined,
                                        classes: {
                                            input: classes.verifyInput,
                                            notchedOutline: classes.verifyInput
                                        }
                                    }}/>
                            </Collapse>}
                        <Button
                            color={"primary"}
                            variant={"contained"}
                            size={"large"}
                            fullWidth
                            disabled={!hasOffers}
                            onClick={toggleIdInput}
                            className={classes.verifyBtn}>
                            {showIdInput ? strings("Close") : strings("VerifyByValueButtonText")}
                        </Button>
                    </Box>
                </Box>
            </GridItem>
        </VerticalFlexLayout>

        {/* TODO: Make this mode dynamic. */}
        {stage === "VerifyStudent" && <>
            {appSettings.id === "unipal" ?
                <VerifyStudentControl
                    cardNumber={cardNumber}
                    onComplete={() => setStage("Home")}/>
                :
                <GtsAliveVerificationControl
                    cardNumber={cardNumber}
                    onComplete={() => setStage("Home")}/>
            }
        </>}

        {
            stage === "VerifyVoucher" &&
            <VerifyVoucherControl
                code={cardNumber}
                onComplete={() => setStage("Home")}/>
        }

        <Dialog open={showLogout}>
            <DialogTitle>{strings("LogoutConfirmationMessage")}</DialogTitle>
            <DialogActions>
                <Button onClick={toggleLogout} color="primary">{strings("Cancel")}</Button>
                <Button onClick={logout} color="secondary">{strings("Logout")}</Button>
            </DialogActions>
        </Dialog>
    </>)
}

export default Index
