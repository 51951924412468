import React, {
    forwardRef,
    ForwardRefRenderFunction,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState
} from "react";
import Api from "util/api/Api";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {Body1, Body2, Heading1, Heading6} from "@unipal/ui/typography/Typography";
import {useResources} from "contexts/ResourcesProvider";
import {Box, Button, Divider, Paper} from "@mui/material";
import colors from "res/colors";
import images from "@unipal/res/images";
import RatioContainer from "@unipal/common/RatioContainer";
import StarIcon from "@unipal/icons/StarIcon";
import {useMerchantAppData} from "contexts/MerchantAppDataProvider";
import DragDropImageCropper from "@unipal/common/DragDropImageCropper";
import {enqueueSnackbar} from "util/SnackbarManager";

export interface Ref {
    isValid: () => Promise<boolean>
}

interface Props {
    onLoad: (loading: boolean) => void
}

const UpsertMediaControl: ForwardRefRenderFunction<Ref, Props> = (props, ref) => {
    const {strings} = useResources()
    const {shop, offers} = useMerchantAppData()

    const [logo, setLogo] = useState<string>()
    const [cover, setCover] = useState<string>()
    const [thumbnail, setThumbnail] = useState<string>()

    useEffect(() => {
        void async function fetchData() {
            const shop = await Api.shops.getShopAsync()
            setLogo(shop?.logo)
            setCover(shop?.image)
            setThumbnail(shop?.thumbnail)
        }()
    }, [])

    useImperativeHandle(ref, () => ({isValid}))

    const isValid = useCallback(async () => {
        if (logo && cover) {
            return true
        } else {
            enqueueSnackbar({
                message: strings("InvalidShopImagesMessage"),
                type: "error"
            })
            return false
        }
    }, [logo, cover, strings])

    const uploadLogo = async (blob: Blob) => {
        await Api.media
            .uploadAsync("shop-logo", blob)
            .then(url => setLogo(url))
            .catch(() => {
                enqueueSnackbar({
                    message: "Unable to upload the image!",
                    type: "error"
                })
            })
    }

    const uploadCover = async (blob: Blob) => {
        await Api.media
            .uploadAsync("shop-cover", blob)
            .then(url => setCover(url))
            .catch(() => {
                enqueueSnackbar({
                    message: "Unable to upload the image!",
                    type: "error"
                })
            })
    }

    const uploadThumbnail = async (blob: Blob) => {
        await Api.media
            .uploadAsync("shop-thumbnail", blob)
            .then(url => setThumbnail(url))
            .catch(() => {
                enqueueSnackbar({
                    message: "Unable to upload the image!",
                    type: "error"
                })
            })
    }

    return (
        <VerticalFlexLayout spacing={4}>
            <HorizontalFlexLayout
                spacing={2}
                wrap={"nowrap"}
                alignItems={"center"}>
                <img src={`${process.env.PUBLIC_URL}/assets/media-icon.png`}
                     style={{width: 56}}
                     alt={shop?.name}/>
                <Heading1>
                    {strings("ManageShopMediaPageTitle")}
                </Heading1>
            </HorizontalFlexLayout>
            <Body2
                whiteSpace={"pre-wrap"}
                color={colors.copy}>
                {strings("ManageShopMediaPageMessage")}
            </Body2>
            <HorizontalFlexLayout spacing={4}>
                <GridItem xs={12} lg={6}>
                    <Body2 marginBottom={1}>
                        <strong>
                            {strings("UploadLogoLabel")}
                        </strong>
                    </Body2>
                    <Body2
                        marginBottom={2}
                        color={colors.copy}>
                        {strings("UploadLogoTip")}
                    </Body2>
                    <DragDropImageCropper
                        maxSize={320}
                        quality={.8}
                        onCrop={uploadLogo}/>
                </GridItem>
                <GridItem xs={12} lg={6}>
                    <Body2 marginBottom={1}>
                        <strong>
                            {strings("LivePreviewLabel")}
                        </strong>
                    </Body2>
                    <Body2
                        marginBottom={2}
                        color={colors.copy}>
                        {strings("LivePreviewTip")}
                    </Body2>
                    <Paper sx={{maxWidth: 400, borderRadius: 2}}>
                        <Box p={2}>
                            <HorizontalFlexLayout
                                spacing={2}
                                alignItems={"center"}>
                                <RatioContainer ratio={1} width={48}>
                                    <img
                                        src={logo || images.placeholder}
                                        width={"100%"} height={"100%"}
                                        style={{borderRadius: 4, objectFit: "cover"}}
                                        alt={shop?.name}/>
                                </RatioContainer>
                                <Body1>
                                    <strong>{shop?.name}</strong>
                                </Body1>
                            </HorizontalFlexLayout>
                        </Box>
                    </Paper>
                </GridItem>
            </HorizontalFlexLayout>
            <Divider sx={{margin: 4}}/>
            <HorizontalFlexLayout spacing={4}>
                <GridItem xs={12} lg={6}>
                    <Body2 marginBottom={1}>
                        <strong>
                            {strings("UploadCoverLabel")}
                        </strong>
                    </Body2>
                    <Body2
                        marginBottom={2}
                        color={colors.copy}>
                        {strings("UploadCoverTip")}
                    </Body2>
                    <DragDropImageCropper
                        maxSize={1200}
                        quality={.8}
                        onCrop={uploadCover}/>
                </GridItem>
                <GridItem xs={12} lg={6}>
                    <Body2 marginBottom={1}>
                        <strong>
                            {strings("LivePreviewLabel")}
                        </strong>
                    </Body2>
                    <Body2
                        marginBottom={2}
                        color={colors.copy}>
                        {strings("LivePreviewTip")}
                    </Body2>
                    <Paper sx={{maxWidth: 400, borderRadius: 2, overflow: "hidden"}}>
                        <RatioContainer ratio={1}>
                            <img
                                src={cover || images.placeholder}
                                width={"100%"} height={"100%"}
                                style={{objectFit: "cover"}}
                                alt={shop?.name}/>
                        </RatioContainer>
                        <Box p={2}>
                            <Heading6 paddingBottom={1}>
                                <strong>{shop?.name}</strong>
                            </Heading6>
                            <HorizontalFlexLayout
                                spacing={2}
                                alignItems={"center"}>
                                <StarIcon fill color={colors.premium}/>
                                <Body1>
                                    <strong>5.0/5</strong>
                                </Body1>
                                <Body1>-</Body1>
                                <GridItem sx={{flexGrow: 1}}>
                                    <Body1>
                                        {strings("TotalRatings_Count", "200")}
                                    </Body1>
                                </GridItem>
                                <Button>
                                    {strings("ViewRatings")}
                                </Button>
                            </HorizontalFlexLayout>
                        </Box>
                    </Paper>
                </GridItem>
            </HorizontalFlexLayout>
            <Divider sx={{margin: 4}}/>
            <HorizontalFlexLayout spacing={4}>
                <GridItem xs={12} lg={6}>
                    <Body2 marginBottom={1}>
                        <strong>
                            {strings("UploadThumbnailLabel")}
                        </strong>
                    </Body2>
                    <Body2
                        marginBottom={2}
                        color={colors.copy}>
                        {strings("UploadThumbnailTip")}
                    </Body2>
                    <DragDropImageCropper
                        aspect={16 / 9}
                        maxSize={640}
                        quality={.8}
                        onCrop={uploadThumbnail}/>
                </GridItem>
                <GridItem xs={12} lg={6}>
                    <Body2 marginBottom={1}>
                        <strong>
                            {strings("LivePreviewLabel")}
                        </strong>
                    </Body2>
                    <Body2
                        marginBottom={2}
                        color={colors.copy}>
                        {strings("LivePreviewTip")}
                    </Body2>
                    <Paper sx={{maxWidth: 400, borderRadius: 2, overflow: "hidden"}}>
                        <RatioContainer ratio={16 / 9}>
                            <img
                                src={thumbnail || cover || images.placeholder}
                                width={"100%"} height={"100%"}
                                style={{objectFit: "cover"}}
                                alt={shop?.name}/>
                        </RatioContainer>
                        <Box px={2} py={1}>
                            <Body1>
                                <strong>{shop?.name}</strong>
                            </Body1>
                            <HorizontalFlexLayout
                                spacing={1}
                                alignItems={"center"}>
                                <GridItem sx={{flexGrow: 1}}>
                                    <Body1 color={colors.copy}>
                                        {offers[0].discount}
                                    </Body1>
                                </GridItem>
                                <StarIcon
                                    fill
                                    size={16}
                                    color={colors.premium}/>
                                <Body1 color={colors.copy}>
                                    <strong>5.0</strong>
                                </Body1>
                            </HorizontalFlexLayout>
                        </Box>
                    </Paper>
                </GridItem>
            </HorizontalFlexLayout>
        </VerticalFlexLayout>
    )
}

export default forwardRef(UpsertMediaControl)
