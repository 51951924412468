import Reply from "models/Reply";
import Strings from "models/Strings";
import IChatMessage from "@unipal/types/chat/IChatMessage";
import images from "@unipal/res/images";
import LatestReply from "models/LatestReply";
import IChatConversation from "@unipal/types/chat/IChatConversation";

export const toConversation = (
    r: LatestReply
): IChatConversation => ({
    id: r.ratingId,
    type: "USER",
    name: r.studentName,
    description: r.instituteName
})

export const toChatMessage = (
    reply: Reply,
    studentName: string,
    shopName: string,
    shopLogo: string,
    role: "SHOP" | "ADMIN",
    strings: (key: keyof Strings, ...values: any[]) => string
): IChatMessage => {
    let image, name = ''
    const me = role === reply.senderType

    if (reply.senderType === "STUDENT") {
        name = studentName
    } else if (reply.senderType === "SHOP") {
        name = me ? strings("You") : shopName
        image = shopLogo
    } else if (reply.senderType === "ADMIN") {
        name = strings("UnipalTeam")
        image = images.squareLogo
    }

    return {
        id: reply.id,
        content: reply.content,
        contentType: "MESSAGE",
        created: reply.createdAt,
        sender: {
            id: reply.senderId,
            name: name,
            image: image,
            me: me
        }
    }
}
