import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import React, {FC, useEffect, useState} from 'react';
import Api from "util/api/Api";
import DashboardVM from "viewModels/merchant/DashboardVM";
import {
    Avatar,
    Box, Button,
    Paper, Popover,
    ToggleButton, ToggleButtonGroup,
    Typography
} from "@mui/material";
import DataTable from "components/common/DataTable";
import {Doughnut} from "react-chartjs-2";
import colors from "res/colors";
import Card from "components/common/Card";
import {useMerchantAppData} from "contexts/MerchantAppDataProvider";
import {ExpandMore, TrendingDown, TrendingFlat, TrendingUp} from "@mui/icons-material";
import {LoadingPage} from "components/common/Loading";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import {useResources} from "contexts/ResourcesProvider";
import appSettings from "appSettings";
import {getCurrency} from "util/Utilities";
import InputField from "@unipal/common/InputField";

type FiltrationType = "ThisMonth" | "AllTime" | "Custom"

function RenderData(row: { key: string, value: number }, index: number, data: {
    key: string,
    value: number
}[]): (string | JSX.Element)[] {
    const date = new Date(row.key).toDateString().split(' ')
    // const prevVal = data[index - 1]?.value ?? 0
    // const rate = ((row.value - (index === 0 ? 0 : prevVal)) / Math.abs(index === 0 || prevVal === 0 ? 1 : prevVal)) * 100.0
    // TODO: This should somehow be managed based on the current sorting.
    const prevVal = data[index + 1]?.value ?? 0
    const rate = ((row.value - (index === data.length ? 0 : prevVal)) / Math.abs(index === data.length || prevVal === 0 ? 1 : prevVal)) * 100.0

    let color = colors.copy
    let sign = ''
    let icon = <TrendingFlat sx={{color: color}}/>

    if (rate > 0) {
        color = colors.success
        sign = '+'
        icon = <TrendingUp sx={{color: color}}/>
    } else if (rate < 0) {
        color = colors.error
        icon = <TrendingDown sx={{color: color}}/>
    }

    return [(
        <HorizontalFlexLayout alignItems={"center"} spacing={1}>
            <Avatar sx={{bgcolor: color + "20", width: 30, height: 30}} variant="rounded">
                {icon}
            </Avatar>
            <Box>
                <Typography variant={"body2"} color={colors.copy}
                            align={"center"}><strong>{date[1]}</strong></Typography>
                <Typography variant={"body2"} align={"center"}>{date[3]}</Typography>
            </Box>
        </HorizontalFlexLayout>

    ), (
        <HorizontalFlexLayout spacing={1} alignItems={"center"}>
            <Typography variant={"body1"} color={colors.copy}><strong>{row.value}</strong></Typography>
            <Typography variant={"caption"} color={color}>({sign}{rate.toFixed(2)}%)</Typography>
        </HorizontalFlexLayout>
    )]
}

const Dashboard: FC = () => {
    const {shop} = useMerchantAppData()
    const {strings} = useResources()

    const [isLoading, setIsLoading] = useState(true)
    const [vm, setVm] = useState<DashboardVM>()
    const [filterType, setFilterType] = useState<FiltrationType>("AllTime")
    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

    useEffect(() => {
        void async function fetchData() {
            const data = await Api.getMerchantDashboardAsync()
            if (data) {
                setVm(data as DashboardVM)
            }

            setIsLoading(false)
        }()
    }, [])

    const handleChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
        if (value) {
            setFilterType(value as FiltrationType)
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleApply = async () => {
        let f = from
        let t = to

        if (filterType === "ThisMonth") {
            const now = new Date()
            f = new Date(now.getFullYear(), now.getMonth(), 1).toISOString()
            t = ''
        } else if (filterType === "AllTime") {
            f = t = ''
        }

        const data = await Api.getMerchantDashboardAsync(f, t)
        if (data) {
            setVm(data as DashboardVM)
        }
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    if (isLoading) {
        return (<LoadingPage/>)
    } else if (!vm) {
        return (<>Unable to load data!</>)
    } else {
        const totalGenderUsers = vm.genderRate?.reduce((sum, gender) => sum + gender.value, 0)
        const isCustom = filterType === "Custom"

        return (<>
            <HtmlMetaDecorator title={strings("Dashboard")}/>

            <Box p={4}>
                <VerticalFlexLayout spacing={4}>
                    <HorizontalFlexLayout spacing={4} alignItems={"center"} justify={"flex-end"}>
                        <GridItem style={{flexGrow: 1}}>
                            <HorizontalFlexLayout spacing={2} alignItems={"center"} wrap={"nowrap"}>
                                <Avatar src={shop.logo} sx={{width: 48, height: 48}}/>
                                <Typography variant={"h5"}>
                                    <strong>
                                        {strings("ShopDashboard_ShopName", shop.name)}
                                    </strong>
                                </Typography>
                            </HorizontalFlexLayout>
                        </GridItem>

                        <Button variant={"contained"} color={"inherit"}
                                sx={{backgroundColor: "white", whiteSpace: 'break-spaces'}}
                                onClick={handleClick} endIcon={<ExpandMore/>}>
                            <Typography variant={"caption"}>{`${strings("DateRange")}: `}</Typography>
                            <strong>{strings(filterType)}</strong>
                        </Button>
                    </HorizontalFlexLayout>

                    <HorizontalFlexLayout spacing={4}>
                        <GridItem style={{flexGrow: 1}}>
                            <Card title={strings("TotalPurchases")}
                                  value={vm.totalPurchases.toString()}/>
                        </GridItem>
                        {appSettings.id === "unipal" &&
                            <GridItem style={{flexGrow: 1}}>
                                <Card title={strings("EstGrossRevenue")}
                                      value={`${vm.grossRevenue.toFixed(1).toString()} ${getCurrency(shop.countryCode, strings)}`}/>
                            </GridItem>}
                        <GridItem style={{flexGrow: 1}}>
                            <Card title={strings("RetentionRate")}
                                  value={`${vm.retentionRate.toFixed(1)}%`}/>
                        </GridItem>
                    </HorizontalFlexLayout>
                    <HorizontalFlexLayout spacing={4} alignItems={"stretch"}>
                        <GridItem md={6} style={{flexGrow: 1}}>
                            <Paper variant={"outlined"}>
                                <Box p={3}>
                                    <VerticalFlexLayout spacing={1}>
                                        <Typography variant={"body1"}>
                                            <strong>{strings("FirstTimerStudents")}</strong>
                                        </Typography>
                                        <Typography variant={"body2"} color={colors.copy}>
                                            {strings("FirstTimerStudentsDescription_ShopName", shop.name)}
                                        </Typography>
                                        <Box pt={2}>
                                            {vm.firstTimers.length ?
                                                <DataTable
                                                    size={"small"}
                                                    columns={[
                                                        {id: "key", label: strings("Month")},
                                                        {id: "value", label: strings("Count")}
                                                    ]}
                                                    sortBy={"key"}
                                                    sortDirection={"desc"}
                                                    data={vm.firstTimers}
                                                    renderRow={RenderData}/>
                                                :
                                                <Typography
                                                    sx={{pt: 4}} align={"center"} color={"gray"}
                                                    variant={"body2"}>
                                                    {strings("NoDataAvailable")}
                                                </Typography>
                                            }
                                        </Box>
                                    </VerticalFlexLayout>
                                </Box>
                            </Paper>
                        </GridItem>
                        <GridItem md={6} style={{flexGrow: 1}}>
                            <Paper variant={"outlined"}>
                                <Box p={3}>
                                    <VerticalFlexLayout spacing={1}>
                                        <Typography variant={"body1"}>
                                            <strong>{strings("ReturningStudents")}</strong>
                                        </Typography>
                                        <Typography variant={"body2"} color={colors.copy}>
                                            {strings("ReturningStudentsDescription_ShopName", shop.name)}
                                        </Typography>
                                        <Box pt={2}>
                                            {vm.userRetention.length ?
                                                <DataTable
                                                    size={"small"}
                                                    columns={[
                                                        {id: "key", label: strings("Month")},
                                                        {id: "value", label: strings("Count")}
                                                    ]}
                                                    sortBy={"key"}
                                                    sortDirection={"desc"}
                                                    data={vm.userRetention}
                                                    renderRow={RenderData}/>
                                                :
                                                <Typography sx={{pt: 4}} align={"center"} color={"gray"}
                                                            variant={"body2"}>
                                                    {strings("NoDataAvailable")}
                                                </Typography>
                                            }
                                        </Box>
                                    </VerticalFlexLayout>
                                </Box>
                            </Paper>
                        </GridItem>
                        <GridItem md={6} style={{flexGrow: 1}}>
                            <Paper variant={"outlined"} style={{backgroundColor: colors.disabledFill}}>
                                <Box p={3}>
                                    <VerticalFlexLayout spacing={2}>
                                        <Typography variant={"body1"} color={colors.gray}>
                                            <strong>{strings("LoyalStudents")}</strong>
                                        </Typography>
                                        <Typography variant={"body1"} color={colors.gray}>
                                            <strong>{strings("DataWillBeAvailableSoon")}</strong>
                                        </Typography>
                                        <Box pt={2}>
                                            <Typography variant={"body2"} color={colors.gray}>
                                                {strings("LoyalStudentsDescription")}
                                            </Typography>
                                        </Box>
                                    </VerticalFlexLayout>
                                </Box>
                            </Paper>
                        </GridItem>
                        {appSettings.id === "unipal" &&
                            <GridItem md={6} alignItems={"center"} style={{flexGrow: 1}}>
                                <Paper>
                                    <Box p={3}>
                                        <Typography variant={"body1"}>
                                            <strong>{strings("MaleVsFemale")}</strong>
                                        </Typography>
                                        {Boolean(vm.genderRate?.length) ?
                                            <Box maxWidth={280} maxHeight={280} margin={"auto"}>
                                                <Doughnut data={{
                                                    labels: vm.genderRate.map(g => g.key),
                                                    datasets: [{
                                                        data: vm.genderRate.map(g => ((g.value / totalGenderUsers) * 100.0).toFixed(1)),
                                                        backgroundColor: [colors.primary, colors.secondary],
                                                        borderWidth: 4,
                                                    }]
                                                }} options={{
                                                    plugins: {
                                                        tooltip: {
                                                            callbacks: {
                                                                label(tooltipItem): string | string[] {
                                                                    return ' ' + tooltipItem.raw + '%'
                                                                }
                                                            }
                                                        }
                                                    }
                                                }}/>
                                            </Box>
                                            :
                                            <Typography sx={{pt: 4}} align={"center"} color={"gray"} variant={"body2"}>
                                                {strings("NoDataAvailable")}
                                            </Typography>
                                        }
                                    </Box>
                                </Paper>
                            </GridItem>}
                    </HorizontalFlexLayout>
                </VerticalFlexLayout>

                <Popover
                    open={open}
                    color={colors.copy}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}>
                    <Box p={2}>
                        <VerticalFlexLayout spacing={2}>
                            <Typography variant={"body2"} color={colors.copy}>
                                <strong>{strings("DateRange")}:</strong>
                            </Typography>
                            <ToggleButtonGroup
                                color="primary"
                                value={filterType}
                                exclusive
                                fullWidth
                                size={"small"}
                                onChange={handleChange}
                                style={{whiteSpace: "nowrap"}}>
                                <ToggleButton value="ThisMonth">{strings("ThisMonth")}</ToggleButton>
                                <ToggleButton value="AllTime">{strings("AllTime")}</ToggleButton>
                                <ToggleButton value="Custom">{strings("Custom")}</ToggleButton>
                            </ToggleButtonGroup>
                            <HorizontalFlexLayout
                                wrap={"nowrap"}
                                spacing={1}>
                                <InputField
                                    label={strings("FromLabel")}
                                    size={"small"}
                                    type={"date"}
                                    value={from}
                                    onChange={e => setFrom(e.target.value)}
                                    disabled={!isCustom}/>
                                <InputField
                                    label={strings("ToLabel")}
                                    size={"small"}
                                    type={"date"}
                                    value={to}
                                    onChange={e => setTo(e.target.value)}
                                    disabled={!isCustom}/>
                            </HorizontalFlexLayout>
                            <HorizontalFlexLayout spacing={2} justify={"flex-end"}>
                                <Button onClick={handleClose}>
                                    {strings("Cancel")}
                                </Button>
                                <Button variant={"outlined"} onClick={handleApply}>
                                    {strings("Apply")}
                                </Button>
                            </HorizontalFlexLayout>
                        </VerticalFlexLayout>
                    </Box>
                </Popover>
            </Box>
        </>)
    }
}

export default Dashboard
