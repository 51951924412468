import React, {FC} from 'react';
import {
    Box, Button, Checkbox, CircularProgress,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Paper, Tooltip, Typography
} from "@mui/material";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import colors from "res/colors";
import {Circle} from "@mui/icons-material";
import Api from "util/api/Api";
import {useMerchantAppData} from "contexts/MerchantAppDataProvider";
import {Field, Form, Formik} from "formik";
import InputFieldComponent from "components/helpers/InputFieldComponent";
import Campaign from "models/Campaign";
import {campaigns} from "res/campaigns";
import {useResources} from "contexts/ResourcesProvider";
import appSettings from "appSettings";
import {LoadingPage} from "components/common/Loading";
import {useNavigate} from "react-router-dom";

const Campaigns: FC = () => {
    const {strings} = useResources()
    const data = useMerchantAppData()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [requestSent, setRequestSent] = React.useState(false)
    const [confirmOpen, setConfirmOpen] = React.useState(false)
    const [termsChecked, setTermsChecked] = React.useState(false)
    const [termsMessage, setTermsMessage] = React.useState<string>()
    const [result, setResult] = React.useState<string>()
    const [campaign, setCampaign] = React.useState<Campaign>()

    if (!appSettings.allowedFeatures?.includes("campaigns")) {
        navigate("/")
        return (<LoadingPage/>)
    }

    function isParticipant(id?: String) {
        return Boolean(data.shop.campaignParticipation?.find(c => c === id))
    }

    function handleTermsCheckbox() {
        setTermsMessage(undefined)
        setTermsChecked(ps => !ps)
    }

    async function sendRequest(fields: any) {
        if (!campaign) {
            return
        }

        if (!termsChecked) {
            setTermsMessage(strings("AgreeToCampaignTermsErrorMessage"))
            return
        }

        setIsLoading(true)

        let message = `${data.shop.name} would like to ${isParticipant(campaign?.id) ?
            "cancel their participation" : "participate"} in the ${campaign?.id} campaign.`

        if (campaign.form && fields) {
            Object.keys(fields).forEach(k => message += `\n\n${k}: ${fields[k]}`)
        }

        await Api.sendCommunication({
            message: message
        }).then(() => {
            setRequestSent(true)
            setResult(strings("CampaignRequestSuccessMessage"))
        }).catch(() => {
            setRequestSent(true)
            setResult(strings("CampaignRequestFailureMessage"))
        })

        setIsLoading(false)
        setTermsChecked(false)
    }

    return (<>
        <HtmlMetaDecorator title={strings("Campaigns")}/>

        <Box p={4}>
            <VerticalFlexLayout spacing={4}>
                <Typography variant={"h5"}>
                    <strong>{strings("Campaigns")}</strong>
                </Typography>
                {campaigns
                    .sort((c1, c2) => Number(c2.isActive) - Number(c1.isActive))
                    .map(c => {
                        const isParticipant = Boolean(data.shop.campaignParticipation?.find(c1 => c1 === c.id))
                        const tipColor = isParticipant ? colors.success : colors.error
                        const textColor = c.isActive ? colors.text : colors.gray
                        const bgColor = c.isActive ? "#ffffff" : colors.disabledFill
                        const tip = isParticipant ?
                            strings("YouAreParticipatingInCampaign") : strings("YouAreNotParticipatingInCampaign")
                        const currentParticipants = data.campaignParticipants ? data.campaignParticipants[c.id] ?? 0 : 0

                        return (
                            <Paper key={c.id} variant={"outlined"} style={{backgroundColor: bgColor}}>
                                <Box p={3}>
                                    <VerticalFlexLayout spacing={2}>
                                        <HorizontalFlexLayout alignItems={"center"} spacing={1}>
                                            {c.isActive &&
                                            <Tooltip title={tip} arrow>
                                                <Circle fontSize={"small"}
                                                        sx={{marginBottom: "-4px", color: tipColor}}/>
                                            </Tooltip>}
                                            <GridItem style={{flexGrow: 1}}>
                                                <Typography variant={"body1"} color={textColor}>
                                                    <strong>{c.name}</strong>
                                                </Typography>
                                            </GridItem>
                                            {c.isActive &&
                                            <Typography variant={"caption"} color={textColor}>
                                                <strong>
                                                    {`[${currentParticipants}/${c.maxParticipants} ${strings("Participants")}]`}
                                                </strong>
                                            </Typography>}
                                        </HorizontalFlexLayout>
                                        <div dangerouslySetInnerHTML={{__html: c.description}}
                                             style={{
                                                 color: c.isActive ? colors.copy : colors.gray,
                                                 fontSize: 14, padding: "8px 0"
                                             }}/>
                                        {c.isActive && c.terms &&
                                        <Button
                                            size={"large"} fullWidth
                                            sx={{textTransform: "none"}} variant={"outlined"}
                                            onClick={() => {
                                                setCampaign(c)
                                                setOpen(ps => !ps)
                                            }}>
                                            <strong>{strings("TermsConditions")}</strong>
                                        </Button>}
                                        {c.isActive &&
                                        <Button
                                            variant={"contained"} fullWidth size={"large"}
                                            onClick={() => {
                                                setCampaign(c)
                                                setConfirmOpen(ps => !ps)
                                            }}
                                            style={{textTransform: "none"}}>
                                            <strong>
                                                {isParticipant ? strings("Leave") : strings("Participate")}
                                            </strong>
                                        </Button>}
                                    </VerticalFlexLayout>
                                </Box>
                            </Paper>
                        )
                    })}
            </VerticalFlexLayout>
        </Box>

        <Dialog open={open} maxWidth={"md"} onClose={() => setOpen(ps => !ps)}>
            <DialogTitle>
                <Typography variant={"h6"}>
                    <strong>{campaign?.name}</strong>
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div style={{fontSize: 14}}
                     dangerouslySetInnerHTML={{
                         __html: campaign?.terms ?? ""
                     }}/>
            </DialogContent>
            <DialogActions sx={{padding: 2}}>
                <Button size={"large"} variant={"contained"}
                        fullWidth style={{textTransform: "none"}}
                        onClick={() => setOpen(ps => !ps)}>
                    <strong>{strings("Close")}</strong>
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog open={confirmOpen} maxWidth={"sm"} onClose={() => {
            setConfirmOpen(ps => !ps)
            setRequestSent(false)
        }}>
            <DialogTitle>
                <Typography variant={"h6"}>
                    <strong>{campaign?.name}</strong>
                </Typography>
            </DialogTitle>
            <DialogContent>
                {requestSent &&
                <Typography variant={"body2"}>{result}</Typography>}

                {!requestSent &&
                <Formik
                    enableReinitialize={true}
                    initialValues={{}}
                    onSubmit={async fields => {
                        await sendRequest(fields)
                    }}>
                    <Form>
                        <VerticalFlexLayout spacing={2}>
                            <Typography variant={"body2"}>
                                {isParticipant(campaign?.id) ?
                                    strings("RequestLeaveCampaign") : strings("RequestParticipateInCampaign")}
                            </Typography>

                            {!isParticipant(campaign?.id) && campaign?.form?.map((c, i) =>
                                <Field
                                    name={c.id}
                                    label={c.name}
                                    type={c.type}
                                    required={c.isRequired}
                                    multiline={c.type === "multiline"}
                                    inputProps={{style: {minHeight: c.type === "multiline" ? "100px" : ""}}}
                                    autoFocus={i === 0}
                                    component={InputFieldComponent}/>
                            )}

                            <GridItem style={{paddingLeft: 8}}>
                                <HorizontalFlexLayout alignItems={"center"} wrap={"nowrap"}>
                                    <Checkbox
                                        color={"primary"} checked={termsChecked}
                                        onClick={handleTermsCheckbox}/>
                                    <div>
                                        <Typography variant={"body2"}>
                                            {strings("AgreeToCampaignTerms")}
                                        </Typography>
                                        {termsMessage &&
                                        <Typography variant={"caption"} color={colors.error}>
                                            {termsMessage}
                                        </Typography>}
                                    </div>
                                </HorizontalFlexLayout>
                            </GridItem>

                            <HorizontalFlexLayout spacing={2} justify={"flex-end"}>
                                <Button
                                    size={"large"} variant={"outlined"}
                                    fullWidth style={{textTransform: "none"}}
                                    onClick={() => {
                                        setConfirmOpen(ps => !ps)
                                        setRequestSent(false)
                                    }}>
                                    <strong>Cancel</strong>
                                </Button>
                                <Button size={"large"} variant={"contained"}
                                        fullWidth type={"submit"}
                                        style={{textTransform: "none"}}>
                                    {isLoading ?
                                        <CircularProgress
                                            style={{width: 24, height: 24}}
                                            sx={{color: "white"}}/>
                                        :
                                        <strong>{strings("SubmitRequest")}</strong>
                                    }
                                </Button>
                            </HorizontalFlexLayout>
                        </VerticalFlexLayout>
                    </Form>
                </Formik>
                }
            </DialogContent>
            {requestSent &&
            <DialogActions sx={{padding: 2, paddingTop: 0}}>
                <Button
                    size={"large"} variant={"contained"}
                    fullWidth style={{textTransform: "none"}}
                    onClick={() => {
                        setConfirmOpen(ps => !ps)
                        setRequestSent(false)
                    }}>
                    <strong>{strings("GotIt")}</strong>
                </Button>
            </DialogActions>}
        </Dialog>
    </>)
}

export default Campaigns
