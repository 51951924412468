import React, {FC, useEffect, useState} from "react";
import Api from "util/api/Api";
import StudentVerificationResult from "viewModels/StudentVerificationResult";
import {HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import Loading from "components/common/Loading";
import {useMerchantAppData} from "contexts/MerchantAppDataProvider";
import colors from "res/colors";
import {
    Box,
    Button,
    Dialog,
    IconButton,
    Typography
} from "@mui/material";
import {CheckCircleOutlineRounded, Close, HighlightOff} from "@mui/icons-material";
import {useResources} from "contexts/ResourcesProvider";
import OffersList from "components/offer/OffersList";
import GtsOffersList from "components/offer/GtsOffersList";
import Offer from "models/Offer";

interface Props {
    cardNumber: string
    onComplete: () => void
}

const GtsAliveVerificationControl: FC<Props> = (props) => {
    const {offers} = useMerchantAppData()
    const {strings} = useResources()

    const [student, setStudent] = useState<StudentVerificationResult>()
    const [selectedOffers, setSelectedOffers] = useState<string[]>([])
    const [eligibleOffers, setEligibleOffers] = useState<Offer[]>([])
    const [notEligibleOffers, setNotEligibleOffers] = useState<Offer[]>([])
    const [completed, setCompleted] = useState(false)
    const notEligibleMessage = strings("NotEligible")

    useEffect(() => {
        void async function fetchData() {
            await Api.student
                .verifyStudentAsync(props.cardNumber)
                .then(data => {
                    if (data) {
                        setStudent(data)
                        if (data.offerEligibilities) {
                            const _offers = offers
                                .map(offer => {
                                    const eligibility = data.offerEligibilities?.find(oe => oe.id === offer.externalId)
                                    return {
                                        ...offer,
                                        isEligible: eligibility?.isEligible ?? false,
                                        message: eligibility?.message ?? notEligibleMessage
                                    }
                                })

                            setEligibleOffers(_offers.filter(o => o.isEligible))
                            setNotEligibleOffers(_offers.filter(o => !o.isEligible))

                            if (_offers.length === 1 && _offers[0].isEligible) {
                                setSelectedOffers([_offers[0].id])
                            }
                        }
                    } else {
                        props.onComplete()
                    }
                })
                .catch(() => props.onComplete())
        }()
    }, [props, offers, notEligibleMessage])

    const handleSelection = async (id: string, selected: boolean) => {
        if (selected && !selectedOffers.includes(id)) {
            setSelectedOffers(ps => [...ps, id])
        } else {
            setSelectedOffers(ps => ps.filter(i => i !== id))
        }
    }

    const confirmPurchase = async () => {
        const id = await Api.purchase
            .createGtsPurchaseAsync(selectedOffers, "", 0, false, props.cardNumber, student?.referenceId)

        if (id) {
            setCompleted(true)
        }
    }

    function renderOffers() {
        if (completed) {
            return [
                <Typography
                    align={"center"}
                    variant="body1">
                    <strong>
                        {strings("CreatePurchaseSuccessMessage_Amount", "")}
                    </strong>
                </Typography>,
                <Button
                    color={"primary"}
                    variant={"contained"}
                    size={"large"}
                    style={{
                        textTransform: "none",
                        fontWeight: 700,
                        minHeight: 64,
                        fontSize: 18
                    }}
                    fullWidth
                    onClick={props.onComplete}>
                    {strings("Close")}
                </Button>
            ]
        }

        if (student?.isMembershipValid) {
            if (eligibleOffers.length) {
                const view = [
                    <Typography
                        color={student.isEligible ? undefined : colors.error}
                        variant="body1">
                        <strong>
                            {strings("StudentEligibleForTheseOffers")}
                        </strong>
                    </Typography>,
                    <GtsOffersList
                        offers={eligibleOffers}
                        selected={offers.length === 1 && eligibleOffers.length === 1}
                        onSelect={handleSelection}/>
                ]

                if (notEligibleOffers.length) {
                    view.push(<Typography
                        pt={2}
                        color={student.isEligible ? undefined : colors.error}
                        variant="body1">
                        <strong>
                            {strings("StudentNotEligibleForAnyOffers")}
                        </strong>
                    </Typography>)
                    view.push(<OffersList offers={notEligibleOffers}/>)
                }

                view.push(<Button
                    fullWidth
                    variant={"contained"}
                    size={"large"}
                    disabled={!selectedOffers.length}
                    onClick={confirmPurchase}
                    sx={{textTransform: "none", fontWeight: 700}}>
                    {strings("ConfirmPurchase")}
                </Button>)

                return view
            } else {
                return [
                    <Typography
                        color={colors.error}
                        variant="body1">
                        <strong>
                            {strings("StudentNotEligibleForAnyOffers")}
                        </strong>
                    </Typography>,
                    <Button
                        color={"primary"}
                        variant={"contained"}
                        size={"large"}
                        style={{
                            textTransform: "none",
                            fontWeight: 700,
                            minHeight: 64,
                            fontSize: 18
                        }}
                        fullWidth
                        onClick={props.onComplete}>
                        {strings("Close")}
                    </Button>
                ]
            }
        } else {
            return [
                Boolean(student?.message?.trim()) &&
                <Typography
                    variant="body1"
                    align={"center"}
                    style={{color: colors.error}}>
                    <strong>
                        {student?.message}
                    </strong>
                </Typography>,
                <Button
                    color={"primary"}
                    variant={"contained"}
                    size={"large"}
                    style={{
                        textTransform: "none",
                        fontWeight: 700,
                        minHeight: 64,
                        fontSize: 18
                    }}
                    fullWidth
                    onClick={props.onComplete}>
                    {strings("Close")}
                </Button>
            ]
        }
    }

    return (
        <Dialog
            open={true}
            fullWidth
            maxWidth={"sm"}
            style={{
                backdropFilter: "blur(10px)",
                WebkitBackdropFilter: "blur(10px)"
            }}>
            <Box p={2}>
                {!student ?
                    <HorizontalFlexLayout justify={"center"}>
                        <Loading size={"small"}/>
                    </HorizontalFlexLayout>
                    :
                    <VerticalFlexLayout>
                        <HorizontalFlexLayout justify={"flex-end"}>
                            <IconButton size={"small"} onClick={props.onComplete}>
                                <Close color={"disabled"}/>
                            </IconButton>
                        </HorizontalFlexLayout>
                        <VerticalFlexLayout spacing={2}>
                            <HorizontalFlexLayout
                                justify={"center"}
                                wrap={"nowrap"}
                                alignItems={"center"}
                                spacing={1}>
                                {student.isMembershipValid ?
                                    [
                                        <CheckCircleOutlineRounded
                                            style={{color: colors.success}}/>,
                                        <Typography
                                            variant="body1"
                                            style={{color: colors.success}}>
                                            {strings("VerifiedStudent")}
                                        </Typography>
                                    ]
                                    :
                                    [
                                        <HighlightOff
                                            style={{color: colors.error}}/>,
                                        <Typography
                                            variant="body1"
                                            style={{color: colors.error}}>
                                            {strings("UnverifiedStudent")}
                                        </Typography>
                                    ]
                                }
                            </HorizontalFlexLayout>
                            <Box py={0}>
                                <Typography
                                    variant="h5"
                                    color={"primary"}
                                    align={"center"}
                                    style={{textTransform: "uppercase"}}>
                                    <strong>
                                        {props.cardNumber}
                                    </strong>
                                </Typography>
                            </Box>
                            {renderOffers()}
                        </VerticalFlexLayout>
                    </VerticalFlexLayout>
                }
            </Box>
        </Dialog>
    )
}

export default GtsAliveVerificationControl
