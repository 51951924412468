import {AxiosRequestConfig, AxiosResponse} from "axios";
import HandlerInput, {HandlerPriority} from "./models/HandlerInput";
import HandlerOutput from "./models/HandlerOutput";

class HandlersManager {
    private _requestHandlers: HandlerInput<AxiosRequestConfig>[] = []
    private _responseHandlers: HandlerInput<AxiosResponse>[] = []

    get requestHandlers() {
        return this._requestHandlers
    }

    get responseHandlers() {
        return this._responseHandlers
    }

    addRequestHandler(
        callback: ((request: AxiosRequestConfig) => Promise<HandlerOutput<AxiosRequestConfig>>),
        priority = HandlerPriority.NORMAL
    ) {
        this._requestHandlers.push({callback: callback, priority: priority});
        this._requestHandlers.sort((h1, h2) => (h1.priority < h2.priority) ? 1 : -1)
    }

    addResponseHandler(
        callback: ((response: AxiosResponse) => Promise<HandlerOutput<AxiosResponse>>),
        priority = HandlerPriority.NORMAL
    ) {
        this._responseHandlers.push({callback: callback, priority: priority});
        this._responseHandlers.sort((h1, h2) => (h1.priority < h2.priority) ? 1 : -1)
    }

    // TODO: Only allow the owner of this instance to clear all handlers,
    //  and provide different methods to only remove the handler of the target/caller

    clearRequestHandlers() {
        while (this._requestHandlers.length) {
            this._requestHandlers.pop();
        }
    }

    clearResponseHandlers() {
        while (this._responseHandlers.length) {
            this._responseHandlers.pop();
        }
    }
}

export default HandlersManager
