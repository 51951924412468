import appSettings from "appSettings";
import baseColors from "@unipal/res/colors"
import Colors from "@unipal/types/Colors";

const colors: Colors = {
    ...baseColors,
    ...appSettings.ui.colors
}

export default colors
