import BaseApi from "util/api/BaseApi";
import {DataPaginationInput, DataPaginationOutput} from "util/api/models/DataPagination";
import Purchase from "models/Purchase";
import appSettings from "appSettings";

class PurchaseApi extends BaseApi {
    async getPurchasesAsync(
        pagination: DataPaginationInput<Purchase>
    ): Promise<DataPaginationOutput<Purchase> | undefined> {
        return this.get(`business/transactions?${this.getPaginationQuery(pagination)}`);
    }

    async createPurchaseAsync(
        offerId: string,
        studentId: string,
        savings: number,
        isPremium: boolean,
        externalId?: string,
        referenceId?: string
    ): Promise<string | undefined> {
        const body = {
            offerId,
            studentId,
            savings,
            isPremium,
            externalId,
            referenceId
        }
        return this.post(appSettings.urls.postPurchase, body)
    }

    async createGtsPurchaseAsync(
        offerIds: string[],
        studentId: string,
        savings: number,
        isPremium: boolean,
        externalId?: string,
        referenceId?: string
    ): Promise<string | undefined> {
        const body = {
            offerIds,
            studentId,
            savings,
            isPremium,
            externalId,
            referenceId
        }
        return this.post(appSettings.urls.postPurchase, body)
    }
}

export default PurchaseApi
