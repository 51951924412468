const languages = [
    {
        code: "en",
        name: "English"
    },
    {
        code: "ar",
        name: "العربية"
    },
    {
        code: "cs",
        name: "Czech"
    },
]

export default languages
