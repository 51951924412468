import AppSettings, {version} from "models/AppSettings";

const appSettings: AppSettings = {
    id: "alive-verify",
    version: version,
    maxReplyMessageLength: 300,
    cardNumber: {
        type: "text",
        length: 14
    },
    urls: {
        base: "https://alive-verify.test.unipal.me",
        verifyStudent: "student/gts-verification",
        postPurchase: "gts-purchase"
    },
    ui: {
        showOfferTitle: false,
        colors: {
            primary: "#006666",
            secondary: "#D0EEEE"
        }
    },
    allowedLanguages: ["en", "ar", "cs"]
}

export default appSettings
