import React, {FC, PropsWithChildren, useEffect} from 'react';
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import {useAuth} from "contexts/AuthProvider";
import AccountRouter from "routers/AccountRouter";
import NotFound from "pages/NotFound";
import Login from "pages/account/login";
import SuccessIntegration from "pages/merchant/settings/SuccessIntegration";
import MerchantRouter from "routers/MerchantRouter";
import Unauthorized from "pages/account/Unauthorized";
import Status from "pages/Status";

const ScrollToTop: FC<PropsWithChildren<{}>> = (props) => {
    const {pathname} = useLocation()
    useEffect(() => window.scrollTo(0, 0), [pathname])
    return (<>{props.children}</>)
}

const Router: FC = () => {
    const {identity} = useAuth()
    // NOTE: ShopAdmin will always be a Merchant.
    const isMerchant = identity.roles?.some(r => r === "Merchant")

    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ScrollToTop>
                <Routes>
                    <Route path="/account/*" element={<AccountRouter/>}/>
                    <Route path="/integration-success" element={<SuccessIntegration/>}/>
                    <Route path="/status" element={<Status/>}/>
                    <Route path="/404" element={<NotFound/>}/>
                    {identity.isAuthenticated && !isMerchant &&
                        <Route path="*" element={<Unauthorized/>}/>}
                    {!identity.isAuthenticated && <Route path="*" element={<Login/>}/>}
                    {isMerchant && <Route path="*" element={<MerchantRouter/>}/>}
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </ScrollToTop>
        </BrowserRouter>
    )
}

export default Router
