import snackbarStore, {enqueue} from "redux/snackbarStore";

interface Props {
    message: string;
    errors: { key: string, value: string }[];
    type: 'default' | 'error' | 'success' | 'warning' | 'info';
}

export const enqueueSnackbar = (props: Partial<Props>) => {
    let message = "";

    if (props.message) {
        message = props.message;
    } else if (props.errors) {
        props.errors.forEach(e => {
            if (message) {
                message += "\n";
            }
            // TODO: Use HTML here to list the multiple error.
            message += e.key ? `${e.key}: ` : "";
            message += e.value;
        });
    }

    snackbarStore.dispatch(enqueue({
        message: message,
        options: {
            key: new Date().getTime() + Math.random(),
            variant: props.type ?? 'default'
        }
    }));
}