import React, {FC} from 'react';
import {Box, Paper} from "@mui/material";
import {VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import colors from "res/colors";
import {Body2, Heading4} from "@unipal/ui/typography/Typography";

interface Props {
    title: string
    value: string | number
    isLoading?: boolean
}

const Card: FC<Props> = (props) => {
    return (
        <Paper>
            <Box p={3}>
                <VerticalFlexLayout spacing={1}>
                    <Body2 color={colors.copy}>
                        {props.title}
                    </Body2>
                    {props.isLoading ?
                        <div className="shimmering body" style={{marginTop: 16}}/>
                        :
                        <Heading4>
                            <strong>{props.value}</strong>
                        </Heading4>
                    }
                </VerticalFlexLayout>
            </Box>
        </Paper>
    )
}

export default Card
