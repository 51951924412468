import React, {FC, MouseEvent} from "react";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import HaibaIcon from "components/svgs/HaibaIcon";
import {ExpandMore} from "@mui/icons-material";
import {Collapse, Typography, Box, Button} from "@mui/material";
import colors from "res/colors";
import {useResources} from "contexts/ResourcesProvider";
import Loading from "components/common/Loading";
import appSettings from "appSettings";
import Offer from "models/Offer";
import CircleOfferIcon from "@unipal/icons/CircleOfferIcon";

interface Props {
    offer: Offer
    onSelect?: (id: string) => Promise<void>
}

const CollapsibleOfferCard: FC<Props> = (props) => {
    const {strings} = useResources()

    const offer = props.offer
    const isPremium = offer.isPremium
    const color = isPremium ? colors.premium : "#000"

    const [loading, setLoading] = React.useState(false)
    const [collapsed, setCollapsed] = React.useState(true)
    const toggleCollapse = () => setCollapsed(!collapsed)

    const onClick = async (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        setLoading(true)
        await props.onSelect!(offer.id)
        setLoading(false)
    }

    const title = isPremium ? strings("PremiumOffer")
        : strings("StandardOffer")

    function isHTML(str: string) {
        const doc = new DOMParser().parseFromString(str, "text/html")
        return Array.from(doc.body.childNodes).some(node => node.nodeType === 1)
    }

    return (
        <Box
            onClick={toggleCollapse}
            p={2} sx={{
            border: `2px solid ${color}`,
            borderRadius: "8px",
            backgroundColor: "white",
        }}>
            <VerticalFlexLayout spacing={2}>
                <HorizontalFlexLayout wrap={"nowrap"} spacing={1} alignItems={"center"}>
                    <GridItem style={{display: "flex"}}>
                        {isPremium ?
                            <HaibaIcon/> :
                            <CircleOfferIcon color={colors.text}/>}
                    </GridItem>
                    <GridItem style={{flexGrow: 1}}>
                        <Typography variant="body1">
                            <strong style={{color: color}}>
                                {appSettings.ui.showOfferTitle ? title : offer.discount}
                            </strong>
                        </Typography>
                        {Boolean(!offer.isEligible && offer.message) &&
                            <Typography
                                color={colors.error}
                                variant="body2">
                                {offer.message}
                            </Typography>}
                    </GridItem>
                    <GridItem style={{display: "flex"}}>
                        <ExpandMore sx={{
                            transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)',
                            marginLeft: 'auto',
                            color: color,
                            transition: "0.4s"
                        }}/>
                    </GridItem>
                </HorizontalFlexLayout>

                {appSettings.ui.showOfferTitle &&
                    <Typography variant="body1">
                        {offer.discount}
                    </Typography>}

                <GridItem style={{
                    marginBottom: collapsed ? -16 : undefined,
                    transition: ".1s"
                }}>
                    <Collapse in={!collapsed} timeout="auto">
                        <VerticalFlexLayout spacing={1}>
                            <Typography variant="body1">
                                <strong>
                                    {strings("Conditions")}
                                </strong>
                            </Typography>
                            {isHTML(offer.terms) ?
                                <div dangerouslySetInnerHTML={{__html: offer.terms}}/>
                                :
                                <Typography
                                    variant="body1"
                                    style={{whiteSpace: "pre-wrap"}}>
                                    {offer.terms || "N/A"}
                                </Typography>
                            }
                            {Boolean(offer.isEligible && props.onSelect) &&
                                <Box pt={2}>
                                    {loading ?
                                        <HorizontalFlexLayout
                                            justify={"center"}>
                                            <Loading size={"small"}/>
                                        </HorizontalFlexLayout>
                                        :
                                        <Button
                                            fullWidth
                                            variant={"contained"}
                                            size={"large"}
                                            onClick={onClick}
                                            style={{
                                                textTransform: "none",
                                                fontWeight: 700,
                                            }}>
                                            {strings("ConfirmPurchase")}
                                        </Button>
                                    }
                                </Box>}
                        </VerticalFlexLayout>
                    </Collapse>
                </GridItem>
            </VerticalFlexLayout>
        </Box>
    )
}

export default CollapsibleOfferCard
