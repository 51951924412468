import User from "models/User";
import UserRole from "models/types/UserRole";

class UserIdentity {
    isAuthenticated: boolean;
    roles: UserRole[];
    user?: User;

    constructor() {
        this.isAuthenticated = false;
        this.roles = [];
    }
}

export default UserIdentity
