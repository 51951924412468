import {SnackbarKey} from "notistack";
import {combineReducers, createStore} from "redux";

enum Action {
    ENQUEUE,
    REMOVE,
    CLOSE,
    CLOSE_ALL
}

// TODO: Make all properties type-safe.
export const enqueue = (notification: any) => {
    const key = notification.options && notification.options.key;

    return {
        type: Action.ENQUEUE,
        notification: {
            ...notification,
            key: key || new Date().getTime() + Math.random(),
        },
    };
};

export const remove = (key: SnackbarKey) => ({type: Action.REMOVE, key,});
export const close = (key: SnackbarKey) => ({type: Action.CLOSE, key});
export const closeAll = () => ({type: Action.CLOSE_ALL});

const notifications: [] = [];

const snackbarReducer = (state = notifications, action: any) => {
    switch (action.type) {
        case Action.ENQUEUE:
            return [...state, {key: action.key, ...action.notification}];

        case Action.REMOVE:
            return state.filter((notification: any) =>
                notification.key !== action.key);

        case Action.CLOSE:
            return state.map((notification: any) =>
                notification.key === action.key ?
                    {...notification, dismissed: true} : {...notification}
            );

        case Action.CLOSE_ALL:
            return state.map((notification: any) =>
                ({...notification, dismissed: true}));

        default:
            return state;
    }
};

const snackbarStore = createStore(combineReducers({
    snackbar: snackbarReducer
}));

export default snackbarStore;