import {FC} from "react";
import IconProps from "@unipal/icons/IconProps";
import {useTheme} from "@unipal/contexts/ThemeProvider";

const CheckIcon: FC<IconProps> = (props) => {
    const {colors} = useTheme()

    return (
        <svg width={props.size ?? "24"} height={props.size ?? "24"} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M20 6L9 17L4 12" stroke={props.color ?? colors.primary} strokeWidth="3" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}

export default CheckIcon
