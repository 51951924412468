import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {TableFooter, TablePagination, TableSortLabel, Typography} from "@mui/material";
import {useResources} from "contexts/ResourcesProvider";
import PaginationActions from '@unipal/common/PaginationActions';

type SortDirection = 'asc' | 'desc'

interface Column<T> {
    id: keyof T
    label: string
}

interface Props<T> {
    size?: "small" | "medium"
    data: T[]
    columns: Column<T>[]
    // TODO: Maybe pass the list of data as parameter as well. In case there is some
    //  filtration applied, then the order of the data here will be different than the parent
    renderRow: (rowData: T, index: number, data: T[]) => (string | JSX.Element)[]
    rowsPerPage?: number
    sortBy?: keyof T
    sortDirection?: SortDirection
}

const DataTable = <T extends any>(props: Props<T> & { children?: ReactNode }) => {
    const {strings} = useResources()

    // TODO: Show loading as soon as the page is rendered; currently
    //  "no data" message is shown until the useEffect is completed.
    const [data, setData] = useState<T[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(props.rowsPerPage ?? 5);
    const [sortDirection, setSortDirection] = useState<SortDirection>(props.sortDirection ?? 'asc');
    const [sortBy, setSortBy] = useState<keyof T | undefined>(props.sortBy);

    // TODO: Support overriding this function, sometimes the caller might
    //  want to handle the sorting differently. Currently this only supports
    //  sorting by string, so sorting nested objects won't work.
    const getSortedData = useCallback((by?: keyof T, dir?: SortDirection) => {
        return by ? props.data.sort((a, b) => {
            // @ts-ignore
            const compare = (b[by] ?? "").toString().localeCompare((a[by] ?? "").toString())
            return dir === 'desc' ? compare : -compare
        }) : props.data
    }, [props.data])

    useEffect(() => {
        setData(getSortedData(props.sortBy, props.sortDirection))
    }, [props, getSortedData])

    // NOTE: Used to add empty space when reaching the last page and the available row are less than the rowsPerPage.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage)
    }

    const handlerSorting = (newSortBy: keyof T) => () => {
        const dir = sortBy === newSortBy && sortDirection === "asc" ? "desc" : "asc"
        setData(getSortedData(newSortBy, dir))
        setSortDirection(dir)
        setSortBy(newSortBy)
        setPage(0)
    }

    return (
        <TableContainer>
            <Table size={props.size}>
                <TableHead>
                    <TableRow>
                        {props.columns.map(c => (
                            <TableCell>
                                <TableSortLabel
                                    active={sortBy === c.id}
                                    direction={sortDirection}
                                    onClick={handlerSorting(c.id)}>
                                    <strong>{c.label}</strong>
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data)
                        .map((r, i) =>
                            // TODO: Add the 'key' and allow the parent to set the 'key' to be able to manage the row.
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                {props.renderRow(r, i + (page * rowsPerPage), data).map(i =>
                                    <TableCell style={{whiteSpace: "pre-line"}} align={"left"}>{i}</TableCell>
                                )}
                            </TableRow>
                        )}

                    {
                        !data.length &&
                        <TableRow style={{height: 53}}>
                            <TableCell colSpan={props.columns.length}>
                                <Typography component={"p"} variant={"caption"} align={"center"}>
                                    {strings("NoDataAvailable")}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    }

                    {emptyRows > 0 && (
                        <TableRow style={{height: 53 * emptyRows}}>
                            <TableCell colSpan={props.columns.length}/>
                        </TableRow>
                    )}
                </TableBody>
                {
                    data.length > rowsPerPage &&
                    <TableFooter>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                rowsPerPage={rowsPerPage}
                                count={data.length}
                                page={page}
                                onPageChange={handleChangePage}
                                ActionsComponent={PaginationActions}/>
                        </TableRow>
                    </TableFooter>
                }
            </Table>
        </TableContainer>
    )
}

export default DataTable
