import React, {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useState} from "react";
import {LoadingPage} from "components/common/Loading";
import UserIdentity from "util/api/models/UserIdentity";
import AuthHelper from "util/AuthHelper";
import Api from "util/api/Api";
import {enqueueSnackbar} from "util/SnackbarManager";

const authTokenKey = "AuthToken";

interface AuthContext {
    identity: UserIdentity;
    setAuthAsync: (token: string, rememberMe: boolean) => Promise<void>;
    clearAuth: () => void;
}

const initialIdentity = new UserIdentity();

export const authContext = createContext<AuthContext>({
    identity: initialIdentity,
    setAuthAsync: async () => {
    },
    clearAuth: () => {
    }
});

export const useAuth = () => useContext(authContext);

// TODO: Store the data in Cookies.
// TODO: Invalidate the token and identity.
const AuthProvider: FC<PropsWithChildren> = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [identity, setIdentity] = useState<UserIdentity>(initialIdentity);

    const clearAuth = useCallback(() => {
        localStorage.removeItem(authTokenKey)
        setIdentity(new UserIdentity())
    }, [])

    const setAuthAsync = useCallback(async (token: string) => {
        // NOTE: Token has to be set before anything, since it's needed in the next API call.
        AuthHelper.setAccessToken(token)
        if (token) {
            localStorage.setItem(authTokenKey, token)
        }

        // TODO: Store the Identity in Redux or in Storage so it can be invalidated properly.
        await Api.getUserIdentityAsync()
            .then(data => {
                const userIdentity = data as UserIdentity
                if (userIdentity) {
                    setIdentity(userIdentity)
                }
            })
            .catch(() => {
                clearAuth();
                enqueueSnackbar({
                    message: "Unable to fetch user!",
                    type: "error"
                })
            })
    }, [clearAuth])

    useEffect(() => {
        void async function fetchData() {
            const token = localStorage.getItem(authTokenKey)
            token ? await setAuthAsync(token) : clearAuth()
            setIsLoading(false)
        }()
    }, [setAuthAsync, clearAuth])

    if (isLoading) {
        return (<LoadingPage/>)
    } else {
        return (
            <authContext.Provider
                value={{identity, setAuthAsync, clearAuth}}>
                {props.children}
            </authContext.Provider>
        )
    }
}

export default AuthProvider
