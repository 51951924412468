import BaseApi from "./BaseApi";
import Offer from "models/Offer";

class OffersApi extends BaseApi {
    async getOfferAsync(id: string): Promise<Offer | undefined> {
        return this.get(`business/offer/${id}`)
    }

    async updateOfferAsync(
        id: string,
        type: number,
        value: string,
        name: string,
        terms: string,
        altName: string,
        altTerms: string
    ): Promise<Offer | undefined> {
        const body = {type, value, name, terms, altName, altTerms}
        return this.patch(`business/offer/${id}`, body)
    }
}

export default OffersApi
