import React, {ChangeEvent, FC, useEffect, useState} from "react";
import Api from "util/api/Api";
import StudentVerificationResult from "viewModels/StudentVerificationResult";
import {HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import HaibaIcon from "components/svgs/HaibaIcon";
import Loading from "components/common/Loading";
import {useMerchantAppData} from "contexts/MerchantAppDataProvider";
import colors from "res/colors";
import {enqueueSnackbar} from "util/SnackbarManager";
import {Avatar, Box, Button, Dialog, Grid, IconButton, TextField, Typography} from "@mui/material";
import {ArrowBackIos, CheckCircleOutlineRounded, Close, HighlightOff} from "@mui/icons-material";
import {useResources} from "contexts/ResourcesProvider";
import CollapsibleOfferCard from "components/offer/CollapsibleOfferCard";
import {getCurrency} from "util/Utilities";

interface Props {
    cardNumber: string;
    onComplete: () => void;
}

const VerifyStudentControl: FC<Props> = (props) => {
    const {strings} = useResources()
    const appData = useMerchantAppData()
    const [student, setStudent] = useState<StudentVerificationResult>()
    const [stage, setStage] = useState<"Verify" | "Confirm">("Verify")
    const [isPremiumSelected, setIsPremiumSelected] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [saving, setSaving] = useState(0)
    const [savingError, setSavingError] = useState("")

    // TODO: Assuming the shop has a single offer.
    const premiumOffer = appData.offers.find(o => o.isPremium)
    const standardOffer = appData.offers.find(o => !o.isPremium)

    useEffect(() => {
        void async function fetchData() {
            await Api.student
                .verifyStudentAsync(props.cardNumber)
                .then(data => {
                    if (data) {
                        setStudent(data)
                        setSaving(data.initialSaving)
                    } else {
                        props.onComplete()
                    }
                })
                .catch(() => props.onComplete())
        }()
    }, [props])

    const handleOfferSelection = (isPremium: boolean) => {
        setStage("Confirm")
        setIsPremiumSelected(isPremium)
        if ((isPremium ? premiumOffer : standardOffer)!.type === 2) {
            setSaving(Number((isPremium ? premiumOffer : standardOffer)!.value) || 0)
        } else {
            setSaving(student!.initialSaving)
        }
    }

    const handleSavingChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setSaving(Number(event.target.value))
    }

    const confirmPurchase = async () => {
        if (saving <= 0) {
            setSavingError(strings("EnterValidAmount"))
            return
        } else {
            setIsLoading(true)

            const purchaseId = await Api.purchase
                .createPurchaseAsync((isPremiumSelected ? premiumOffer : standardOffer)!.id, student!.id, saving, isPremiumSelected)

            if (purchaseId) {
                setIsLoading(false)
                setSavingError("")
                props.onComplete()

                enqueueSnackbar({
                    // TODO: Set the currency.
                    message: strings(
                        "CreatePurchaseSuccessMessage_Amount",
                        `${saving.toFixed(3)} ${getCurrency(appData.shop.countryCode, strings)}`
                    ),
                    type: "success"
                })
            }

            setIsLoading(false)
        }
    }

    if (stage === "Verify") {
        return (
            <Dialog fullWidth maxWidth={"xs"} open={true}
                    style={{backdropFilter: "blur(10px)", WebkitBackdropFilter: "blur(10px)"}}>
                <Box p={2}>
                    {!student ?
                        <HorizontalFlexLayout justify={"center"}>
                            <Loading size={"small"}/>
                        </HorizontalFlexLayout>
                        :
                        <VerticalFlexLayout>
                            <HorizontalFlexLayout justify={"flex-end"}>
                                <IconButton size={"small"} onClick={props.onComplete}>
                                    <Close color={"disabled"}/>
                                </IconButton>
                            </HorizontalFlexLayout>

                            <VerticalFlexLayout spacing={2}>
                                <VerticalFlexLayout alignItems={"center"} spacing={2}>
                                    {student.isMembershipValid ?
                                        <HorizontalFlexLayout wrap={"nowrap"} alignItems={"center"} spacing={1}>
                                            <CheckCircleOutlineRounded style={{color: colors.success}}/>
                                            <Typography variant="body1" style={{color: colors.success}}>
                                                {strings("VerifiedStudent")}
                                            </Typography>
                                        </HorizontalFlexLayout>
                                        :
                                        <HorizontalFlexLayout wrap={"nowrap"} alignItems={"center"} spacing={1}>
                                            <HighlightOff style={{color: colors.error}}/>
                                            <Typography variant="body1" style={{color: colors.error}}>
                                                {strings("UnverifiedStudent")}
                                            </Typography>
                                        </HorizontalFlexLayout>
                                    }

                                    <Avatar alt={student.name} src={student?.imageUrl}
                                            style={{width: 160, height: 160}}/>

                                    <Typography variant="h5" color={"primary"} align={"center"}>
                                        <strong>{student.cardNumber}</strong>
                                    </Typography>

                                    <Typography variant="h5" align={"center"}>
                                        <strong>{student.name}</strong>
                                    </Typography>

                                    <Box pb={4}>
                                        <Typography variant="body1" align={"center"}>
                                            {student.universityName}
                                        </Typography>
                                    </Box>
                                </VerticalFlexLayout>

                                {student.isEligible ?
                                    <VerticalFlexLayout spacing={2}>
                                        <Typography variant="body1">
                                            <strong>{strings("StudentEligibleForTheseOffers")}</strong>
                                        </Typography>

                                        {premiumOffer && student.isPremium &&
                                            <Button
                                                color={"primary"}
                                                variant={"contained"}
                                                size={"large"}
                                                fullWidth
                                                onClick={() => handleOfferSelection(true)}
                                                startIcon={<HaibaIcon color={"#fff"}/>}
                                                style={{
                                                    textTransform: "none",
                                                    fontWeight: 700,
                                                    minHeight: 64,
                                                    fontSize: 18
                                                }}>
                                                {strings("PremiumOffer")}
                                            </Button>}

                                        <Button
                                            color={"primary"}
                                            variant={premiumOffer && student.isPremium ? "outlined" : "contained"}
                                            size={"large"}
                                            fullWidth
                                            onClick={() => handleOfferSelection(false)}
                                            style={{
                                                textTransform: "none",
                                                fontWeight: 700,
                                                minHeight: 64,
                                                fontSize: 18
                                            }}>
                                            {strings("StandardOffer")}
                                        </Button>
                                    </VerticalFlexLayout>
                                    :
                                    <VerticalFlexLayout spacing={2}>
                                        {
                                            student.message?.trim() &&
                                            <Typography variant="body1" style={{color: colors.error}}>
                                                <strong>{student.message}</strong>
                                            </Typography>
                                        }
                                        <Button
                                            color={"primary"}
                                            variant={"contained"}
                                            size={"large"}
                                            style={{
                                                textTransform: "none",
                                                fontWeight: 700,
                                                minHeight: 64,
                                                fontSize: 18
                                            }}
                                            fullWidth
                                            onClick={props.onComplete}>
                                            {strings("Close")}
                                        </Button>
                                    </VerticalFlexLayout>
                                }
                            </VerticalFlexLayout>
                        </VerticalFlexLayout>
                    }
                </Box>
            </Dialog>
        );
    } else {
        return (
            <Dialog fullWidth maxWidth={"xs"} open={true}
                    style={{backdropFilter: "blur(10px)", WebkitBackdropFilter: "blur(10px)"}}>
                <Box p={2}>
                    <VerticalFlexLayout spacing={3}>
                        <HorizontalFlexLayout>
                            <Grid item style={{flexGrow: 1}}>
                                <IconButton
                                    size={"small"}
                                    onClick={() => setStage("Verify")}>
                                    <ArrowBackIos color={"disabled"}/>
                                </IconButton>
                            </Grid>
                            <IconButton size={"small"} onClick={props.onComplete}>
                                <Close color={"disabled"}/>
                            </IconButton>
                        </HorizontalFlexLayout>

                        <VerticalFlexLayout spacing={1}>
                            <Typography variant="body1">
                                <strong>{strings("Offer")}:</strong>
                            </Typography>
                            <CollapsibleOfferCard
                                offer={isPremiumSelected ? premiumOffer! : standardOffer!}/>
                        </VerticalFlexLayout>

                        <VerticalFlexLayout spacing={1}>
                            <Typography variant="body1">
                                <strong>{strings("SavingAmount")}:</strong>
                            </Typography>

                            <TextField
                                variant={"outlined"}
                                placeholder={"0.000"}
                                inputMode={"decimal"}
                                fullWidth
                                type={"number"}
                                disabled={(isPremiumSelected ? premiumOffer : standardOffer)!.type === 2}
                                value={saving ? saving : undefined}
                                onChange={handleSavingChange}
                                error={Boolean(savingError)}
                                helperText={Boolean(savingError) ? savingError : ''}
                                InputProps={{
                                    endAdornment: (
                                        <Typography variant="body1" color={"textSecondary"}>
                                            <strong>{getCurrency(appData.shop.countryCode, strings)}</strong>
                                        </Typography>
                                    )
                                }}/>

                        </VerticalFlexLayout>

                        {isLoading ?
                            <HorizontalFlexLayout justify={"center"}>
                                <Loading size={"small"}/>
                            </HorizontalFlexLayout>
                            :
                            <Button
                                color={"primary"}
                                variant={"contained"}
                                size={"large"}
                                fullWidth
                                onClick={confirmPurchase}
                                style={{
                                    textTransform: "none",
                                    fontWeight: 700,
                                    minHeight: 64,
                                    fontSize: 18
                                }}>
                                {strings("ConfirmPurchase")}
                            </Button>
                        }
                    </VerticalFlexLayout>
                </Box>
            </Dialog>
        )
    }
}

export default VerifyStudentControl
