import React, {FC} from "react"
import Rating from "models/Rating";
import Reply from "models/Reply";
import {useResources} from "contexts/ResourcesProvider";
import {useTheme} from "@unipal/contexts/ThemeProvider";
import images from "@unipal/res/images";
import {GridItem, HorizontalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {Body1, Body2} from "@unipal/ui/typography/Typography";
import {toAge} from "util/Utilities";
import Avatar from "@unipal/ui/avatar/Avatar";

interface Props {
    rating: Rating
    reply: Reply
}

const ReplyItem: FC<Props> = (props) => {
    const {strings} = useResources()
    const {colors} = useTheme()

    const reply = props.reply
    const isStudent = reply.senderType === "STUDENT"
    const name = props.rating.studentName
    let subject, image

    if (isStudent) {
        subject = props.rating.studentName
    } else if (reply.senderType === "SHOP") {
        subject = strings("You")
        image = props.rating.shopLogo
    } else if (reply.senderType === "ADMIN") {
        subject = strings("UnipalTeam")
        image = images.squareLogo
    }

    return (
        <HorizontalFlexLayout
            spacing={2}
            wrap={"nowrap"}>
            <Avatar
                name={name}
                image={isStudent ? undefined : image || images.squarePlaceholder}/>
            <GridItem sx={{flexGrow: 1}}>
                <Body1>
                    <strong>{subject}</strong>
                </Body1>
                <Body1
                    paddingTop={1}
                    color={colors.copy}
                    whiteSpace={"pre-wrap"}>
                    {reply.content}
                </Body1>
            </GridItem>
            <GridItem alignSelf={"flex-end"}>
                <Body2 color={colors.gray}>
                    {toAge(reply.createdAt)}
                </Body2>
            </GridItem>
        </HorizontalFlexLayout>
    )
}

export default ReplyItem
