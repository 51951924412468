import React, {FC, PropsWithChildren, ReactNode, useState} from 'react';
import MuiAppBar from "@mui/material/AppBar"
import {
    Box,
    Drawer,
    Hidden,
    IconButton,
    SwipeableDrawer,
    Theme,
    Toolbar
} from "@mui/material";
import {ArrowBack, Menu} from "@mui/icons-material";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {createStyles, makeStyles} from "@mui/styles";
import {useResources} from "contexts/ResourcesProvider";
import {Link} from "react-router-dom"
import {useMerchantAppData} from "contexts/MerchantAppDataProvider";
import ShopStatus from "models/types/ShopStatus";
import Button from "@unipal/ui/button/Button";
import paths from "@unipal/utils/paths";
import Alert from "@unipal/ui/alert/Alert";

interface Props {
    drawerList?: ReactNode
    toolbarMenu?: ReactNode
}

const drawerWidth = 250

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            height: "100%"
        },
        logo: {
            cursor: "pointer",
            width: 130,
            height: 36
        },
        appBar: {
            boxShadow: "-1px 1px 4px 0px rgb(0 0 0 / 5%)",
            backgroundColor: "white",
            zIndex: theme.zIndex.drawer + 1,
            borderRadius: 0
        },
        menuIcon: {
            marginRight: theme.spacing(2),
        },
        nav: {
            [theme.breakpoints.up('md')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        drawer: {
            width: drawerWidth,
        },
        searchView: {
            padding: "0 16px"
        },
        toolbar: theme.mixins.toolbar,
        banner: {
            zIndex: 1100,
            padding: "32px 32px 0 32px",
            position: "sticky",
            // backgroundColor: "#E5F6FD",
            top: 56,
            [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
                top: 48
            },
            [theme.breakpoints.up('sm')]: {
                top: 64
            }
        }
    })
)

const AppBar: FC<PropsWithChildren<Props>> = (props) => {
    const {shop} = useMerchantAppData()
    const {strings} = useResources()
    const classes = useStyles()

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const toggleDrawer = (open: boolean) => () => {
        setIsDrawerOpen(open)
    }

    const logo = (
        <img
            className={classes.logo}
            alt={strings("AppName")}
            src={strings("AppLogo")}/>
    )

    const drawerView = (
        <div
            className={classes.drawer}
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}>
            <div className={classes.toolbar}>
                <Box px={3} pt={1}>
                    <HorizontalFlexLayout alignItems={"center"}>
                        <IconButton
                            edge="start"
                            className={classes.menuIcon}
                            color="inherit"
                            onClick={toggleDrawer(true)}>
                            <ArrowBack/>
                        </IconButton>
                        <Link to={"/"}>
                            <Box flexGrow={1}>
                                {logo}
                            </Box>
                        </Link>
                    </HorizontalFlexLayout>
                </Box>
            </div>
            {props.drawerList}
        </div>
    )

    return (
        <div className={classes.root}>
            <MuiAppBar
                className={classes.appBar}
                position="fixed">
                <Toolbar>
                    {props.drawerList &&
                        <Hidden mdUp>
                            <IconButton
                                edge="start"
                                className={classes.menuIcon}
                                onClick={toggleDrawer(true)}>
                                <Menu/>
                            </IconButton>
                        </Hidden>}
                    <Link to={"/"}>
                        <Box flexGrow={1}>
                            {logo}
                        </Box>
                    </Link>
                    {Boolean(props.toolbarMenu) &&
                        <Hidden smDown>
                            {props.toolbarMenu}
                        </Hidden>}
                </Toolbar>
            </MuiAppBar>
            {props.drawerList &&
                <nav className={classes.nav}>
                    <Hidden mdUp>
                        <SwipeableDrawer
                            anchor={'left'}
                            variant={"temporary"}
                            open={isDrawerOpen}
                            onClose={toggleDrawer(false)}
                            onOpen={toggleDrawer(true)}
                            ModalProps={{keepMounted: true}}>
                            {drawerView}
                        </SwipeableDrawer>
                    </Hidden>
                    <Hidden mdDown>
                        <Drawer
                            open
                            variant="permanent"
                            sx={{borderRadius: 0}}>
                            {drawerView}
                        </Drawer>
                    </Hidden>
                </nav>}
            <main style={{flexGrow: 1}}>
                <VerticalFlexLayout sx={{height: "100%"}} wrap={"nowrap"}>
                    <GridItem sx={{flexGrow: 1}}>
                        {/* NOTE: Necessary for content to be below app bar */}
                        <div className={classes.toolbar}/>
                        {shop.status === ShopStatus.NEW &&
                            <Box className={classes.banner}>
                                <Alert
                                    severity="warning"
                                    title={strings("AccountNotLiveTitle")}
                                    message={strings("AccountNotLiveMessage")}
                                    action={
                                        <Button
                                            to={paths.profileSetup()}
                                            variant={"contained"}>
                                            {strings("CompleteNow")}
                                        </Button>
                                    }/>
                            </Box>}
                        {shop.status === ShopStatus.PENDING &&
                            <Box className={classes.banner}>
                                <Alert
                                    severity="info"
                                    message={strings("ProfileDetailsBeingReviewedMessage")}/>
                            </Box>}
                        {shop.status === ShopStatus.REJECTED &&
                            <Box className={classes.banner}>
                                <Alert
                                    severity="error"
                                    title={strings("ProfileDetailsRejectedTitle")}
                                    message={shop.rejectionMessage}
                                    action={
                                        <Button
                                            to={paths.profileSetup()}
                                            variant={"contained"}>
                                            {strings("Resubmit")}
                                        </Button>
                                    }/>
                            </Box>}
                        {props.children}
                    </GridItem>
                </VerticalFlexLayout>
            </main>
        </div>
    )
}

export default AppBar
