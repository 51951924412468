import React, {FC, useLayoutEffect} from "react";
import ThemeProvider from "@unipal/contexts/ThemeProvider";
import AuthProvider from "contexts/AuthProvider";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import "App.scss";
import SnackbarNotifier from "components/helpers/SnackbarNotifier";
import {Provider} from "react-redux";
import {SnackbarProvider} from "notistack";
import snackbarStore from "redux/snackbarStore";
import colors from "res/colors";
import 'extension.d';
import {createTheme, Theme, ThemeProvider as MuiThemeProvider} from "@mui/material";
import {ArcElement, Chart, Legend, Tooltip} from "chart.js";
import 'res/strings';
import ResourcesProvider from "contexts/ResourcesProvider";
import {useTranslation} from "react-i18next";
import rtlPlugin from 'stylis-plugin-rtl';
import {CacheProvider} from '@emotion/react';
import createCache from '@emotion/cache';
import {prefixer} from 'stylis';
import appSettings from "appSettings";
import Router from "routers/Router";

Chart.register(ArcElement, Tooltip, Legend)

const theme: Theme = createTheme({
    direction: "ltr",
    components: {
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    color: colors.error
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    boxShadow: "none",
                    fontWeight: 700,
                    fontSize: 16
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
                    border: 'none',
                    borderRadius: 8
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                inputMultiline: {
                    minHeight: 150
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        border: `1px solid ${colors.primary} !important`
                    }
                }
            }
        }
    },
    palette: {
        primary: {
            main: colors.primary,
        },
        secondary: {
            main: colors.secondary,
        }
    },
    typography: {
        fontFamily: 'Open Sans, sans-serif',
        allVariants: {
            color: colors.text
        },
        h1: {
            fontWeight: "bold",
            fontStyle: "normal",
            textTransform: "capitalize",
            fontSize: 32
        }
    }
})

const rtlTheme: Theme = createTheme({
    ...theme,
    direction: "rtl",
    typography: {
        fontFamily: 'Tajawal, sans-serif',
        h1: {
            fontWeight: "bold",
            fontStyle: "normal",
            textTransform: "capitalize",
            color: colors.text,
            fontSize: 32
        }
    }
})

const cacheRtl = createCache({
    key: 'muirtl',
    // @ts-ignore
    stylisPlugins: [prefixer, rtlPlugin],
    prepend: true,
})

const cacheLtr = createCache({
    key: 'muiltr',
    stylisPlugins: [],
    prepend: true,
})

const googleAnalyticsScript = "https://www.googletagmanager.com/gtag/js?id=" +
    appSettings.googleAnalyticsCode

const setGoogleAnalytics = () => {
    if (!appSettings.googleAnalyticsCode) {
        return
    }

    // @ts-ignore
    window.dataLayer = window.dataLayer || []

    function gtag(key: string, value: any) {
        // @ts-ignore
        dataLayer.push(arguments)
    }

    gtag('js', new Date())
    gtag('config', appSettings.googleAnalyticsCode)
}

const App: FC = () => {
    const {i18n} = useTranslation()
    const dir = i18n.dir()

    useLayoutEffect(() => {
        document.dir = dir
        document.body.setAttribute("dir", dir)
    }, [dir])

    setGoogleAnalytics()

    return (<>
        <HtmlMetaDecorator>
            <meta name="theme-color" content={colors.primary}/>
            <link rel="mask-icon" color={colors.primary} href="/favicons/safari-pinned-tab.svg"/>

            {Boolean(appSettings.googleAnalyticsCode) && <script async src={googleAnalyticsScript}/>}
        </HtmlMetaDecorator>

        <ThemeProvider colors={colors}>
            <ResourcesProvider>
                <CacheProvider value={dir === "rtl" ? cacheRtl : cacheLtr}>
                    <MuiThemeProvider theme={dir === "rtl" ? {...rtlTheme} : {...theme}}>
                        <Provider store={snackbarStore}>
                            <SnackbarProvider maxSnack={5}>
                                <AuthProvider>
                                    <Router/>
                                    <SnackbarNotifier/>
                                </AuthProvider>
                            </SnackbarProvider>
                        </Provider>
                    </MuiThemeProvider>
                </CacheProvider>
            </ResourcesProvider>
        </ThemeProvider>
    </>)
}

export default App
