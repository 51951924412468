import React, {FC, useState} from "react";
import DragDropControl from "@unipal/common/DragDropControl";
import ImageCropperControl, {Props as ImageCropperProps} from "@unipal/common/ImageCropperControl";
import {useResources} from "contexts/ResourcesProvider";

type Props = Omit<ImageCropperProps, "image" | "onClose">

const DragDropImageCropper: FC<Props> = (props) => {
    const {strings} = useResources()
    const [image, setImage] = useState<string>()

    const onFileChange = async (files: FileList) => {
        if (files && files.length > 0) {
            setImage(await readFile(files[0]) as string | undefined)
        }
    }

    const readFile = (file: File) => {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    const onCrop = async (image: Blob) => {
        await props.onCrop(image)
        onClose()
    }

    const onClose = () => setImage(undefined)

    return (<>
        <DragDropControl
            accept="image/*"
            action={strings("UploadImage")}
            message={strings("DragDropImageTip")}
            onChange={onFileChange}/>

        {image &&
        <ImageCropperControl
            {...props}
            image={image}
            onCrop={onCrop}
            onClose={onClose}/>}
    </>)
}

export default DragDropImageCropper
