import React, {FC} from "react";

interface Props {
    size?: number
}

const CloseIcon: FC<Props> = (props) => {
    const size = props.size || 24

    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#F5F5F5"/>
            <path d="M16.5 7.5L7.5 16.5" stroke="#121212" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M7.5 7.5L16.5 16.5" stroke="#121212" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}

export default CloseIcon
