import BaseApi from "./BaseApi";
import ApiClient from "util/api/client/ApiClient";
import config from "util/api/config";
import AuthenticationResponse from "util/api/models/AuthenticationResponse";
import {AxiosResponse} from "axios";
import UserIdentity from "util/api/models/UserIdentity";
import MerchantAppData from "viewModels/MerchantAppData";
import StudentApi from "util/api/StudentApi";
import PurchaseApi from "util/api/PurchaseApi";
import DashboardVM from "viewModels/merchant/DashboardVM";
import ActivityFeedApi from "util/api/ActivityFeedApi";
import Communication from "models/Communication";
import VoucherApi from "util/api/VoucherApi";
import ExternalSystem from "models/ExternalSystem";
import RatingsApi from "util/api/RatingsApi";
import ShopsApi from "util/api/ShopsApi";
import OffersApi from "util/api/OffersApi";
import MediaApi from "util/api/MediaApi";
import BranchesApi from "util/api/BranchesApi";

class Api extends BaseApi {

    private static _instance: Api
    private _studentApi?: StudentApi
    private _purchaseApi?: PurchaseApi
    private _ratingsApi?: RatingsApi
    private _activityFeedApi?: ActivityFeedApi
    private _voucherApi?: VoucherApi
    private _shopsApi?: ShopsApi
    private _offersApi?: OffersApi
    private _mediaApi?: MediaApi
    private _branchesApi?: BranchesApi

    private constructor() {
        super()
    }

    public static get instance() {
        return Api._instance || (Api._instance = new this());
    }

    public authenticate(username: string, password: string):
        Promise<AxiosResponse<AuthenticationResponse>> {
        const data = new URLSearchParams({
            'grant_type': 'password',
            'username': username,
            'password': password
        })

        return ApiClient.post<AuthenticationResponse>("", data, {
            method: 'post',
            baseURL: config.authenticationUrl,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    }

    public async getUserIdentityAsync():
        Promise<UserIdentity | undefined> {
        return await this.get<UserIdentity>("user")
    }

    async getMerchantAppDataAsync():
        Promise<MerchantAppData | undefined> {
        const path = `business/data`
        return this.get(path)
    }

    async getExternalSystemsAsync():
        Promise<ExternalSystem[] | undefined> {
        const path = `business/integrations`
        return this.get(path)
    }

    async getMerchantDashboardAsync(from?: string, to?: string):
        Promise<DashboardVM | undefined> {
        const path = `business/dashboard?from=${from}&to=${to}`
        return this.get(path)
    }

    async sendCommunication(input: Communication):
        Promise<string | undefined> {
        return this.post("CorporateCommunication", input)
    }

    get student() {
        if (!this._studentApi) {
            this._studentApi = new StudentApi();
        }
        return this._studentApi;
    }

    get purchase() {
        if (!this._purchaseApi) {
            this._purchaseApi = new PurchaseApi();
        }
        return this._purchaseApi;
    }

    get ratings() {
        if (!this._ratingsApi) {
            this._ratingsApi = new RatingsApi()
        }
        return this._ratingsApi
    }

    get activityFeed() {
        if (!this._activityFeedApi) {
            this._activityFeedApi = new ActivityFeedApi();
        }
        return this._activityFeedApi;
    }

    get voucher() {
        if (!this._voucherApi) {
            this._voucherApi = new VoucherApi();
        }
        return this._voucherApi;
    }

    get shops() {
        if (!this._shopsApi) {
            this._shopsApi = new ShopsApi()
        }
        return this._shopsApi
    }

    get offers() {
        if (!this._offersApi) {
            this._offersApi = new OffersApi()
        }
        return this._offersApi
    }

    get media() {
        if (!this._mediaApi) {
            this._mediaApi = new MediaApi()
        }
        return this._mediaApi
    }

    get branches() {
        if (!this._branchesApi) {
            this._branchesApi = new BranchesApi()
        }
        return this._branchesApi
    }
}

const instance = Api.instance
export default instance
