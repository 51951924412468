import BaseApi from "./BaseApi";
import VoucherVerificationResult from "viewModels/VoucherVerificationResult";

class VoucherApi extends BaseApi {
    async verifyVoucherAsync(code: string):
        Promise<VoucherVerificationResult | undefined> {
        return this.get(`voucher/verify-voucher?code=${code}`)
    }

    async redeemVoucherAsync(id: string, amount: number):
        Promise<string | undefined> {
        const body = {voucherId: id, savings: amount}
        return this.post(`purchase/voucher`, body)
    }
}

export default VoucherApi
