import React, {FC, PropsWithChildren} from 'react';
import {Navigate, Route, Routes, Outlet} from "react-router-dom";
import Dashboard from "pages/merchant/dashboard";
import Campaigns from "pages/merchant/campaigns";
import ContactUs from "pages/merchant/contact-us";
import Feeds from "pages/merchant/feeds";
import RatingsIndex from "pages/ratings/Index";
import Purchases from "pages/merchant/purchases";
import Integrations from "pages/merchant/settings/Integrations";
import AppBar from "merchant/AppBar";
import MerchantAppDataProvider from "contexts/MerchantAppDataProvider";
import Index from "pages/merchant";
import {useAuth} from "contexts/AuthProvider";
import ProfileSetup from "pages/account/ProfileSetup";
import LatestReplies from "pages/ratings/LatestReplies";

const Wrapper: FC<PropsWithChildren> = (props) => {
    return (
        <AppBar>
            {props.children}
        </AppBar>
    )
}

const MerchantRouter: FC = () => {
    const {identity} = useAuth()
    const isAdmin = identity.roles?.some(r => r === "ShopAdmin")

    return (
        <MerchantAppDataProvider>
            <Routes>
                <Route path="/" element={<Index/>}/>
                <Route key={"setup"} path="/setup" element={<ProfileSetup/>}/>

                {isAdmin &&
                    <Route element={<Wrapper><Outlet/></Wrapper>}>
                        <Route key={"dashboard"} path="/dashboard" element={<Dashboard/>}/>
                        <Route key={"campaigns"} path="/campaigns" element={<Campaigns/>}/>
                        <Route key={"contact-us"} path="/contact-us" element={<ContactUs/>}/>
                        <Route key={"feeds"} path="/feeds" element={<Feeds/>}/>
                        <Route key={"ratings"} path="/ratings" element={<RatingsIndex/>}/>
                        <Route key={"chat"} path="/chat" element={<LatestReplies/>}/>
                        <Route key={"purchases"} path="/purchases" element={<Purchases/>}/>
                        <Route key={"settings"} path="/settings/integrations" element={<Integrations/>}/>
                    </Route>}
                <Route path="*" element={<Navigate to="/" replace/>}/>
            </Routes>
        </MerchantAppDataProvider>
    )
}

export default MerchantRouter
