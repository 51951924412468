import BaseApi from "./BaseApi";
import UploadInstruction from "@unipal/models/UploadInstruction";
import ApiClient from "@unipal/utils/api/ApiClient";
import AuthHelper from "util/AuthHelper";

class MediaApi extends BaseApi {
    async uploadAsync(
        purpose: string,
        file: File | Blob
    ): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            const url = `business/upload/${purpose}?type=${file.type}&size=${file.size}`
            const instruction = await this.get<UploadInstruction>(url)
            if (instruction) {
                instruction.headers["unipal-access-token"] = AuthHelper.accessToken

                const response = await ApiClient
                    .put(instruction.uploadUrl, file, {headers: instruction.headers})
                if (response.status === 200) {
                    resolve(instruction.downloadUrl)
                    return
                }
            }

            reject(undefined)
        })
    }
}

export default MediaApi
