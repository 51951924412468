import React, {useState} from "react";
import {makeStyles} from "@mui/styles";
import {HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {Field, Form, Formik} from "formik";
import InputFieldComponent from "components/helpers/InputFieldComponent";
import Api from "util/api/Api";
import AuthenticationResponse from "util/api/models/AuthenticationResponse";
import {useAuth} from "contexts/AuthProvider";
import Loading from "components/common/Loading";
import {Theme, Typography, Button, Box, Grid} from "@mui/material";
import {useResources} from "contexts/ResourcesProvider";
import Copyright from "components/common/Copyright";
import appSettings from "appSettings";

interface FormFields {
    username: string
    password: string
}

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        padding: theme.spacing(2, 3)
    },
    logo: {
        width: 220,
    }
}))

const LoginControl: React.FC = () => {
    const {setAuthAsync} = useAuth()
    const {strings} = useResources()

    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const initialValues: FormFields = {
        username: "",
        password: ""
    }

    // TODO: Change the alerts
    async function login(fields: FormFields) {
        setIsLoading(true);
        Api.authenticate(fields.username, fields.password)
            .then(async (response) => {
                if (response && response.status === 200) {
                    await setAuthAsync(response.data.access_token, true)
                } else if (response?.data.error) {
                    alert(response?.data.error_description);
                } else {
                    alert(strings("InternalErrorMessage"))
                }

                setIsLoading(false)
            })
            .catch((result) => {
                try {
                    if (result.request.response) {
                        const response: AuthenticationResponse = JSON.parse(result.request.response);
                        if (response?.error) {
                            alert(response.error_description);
                        } else {
                            alert(strings("InternalErrorMessage"))
                        }
                    }
                } catch (error) {
                    alert(strings("InternalErrorMessage"))
                }

                setIsLoading(false);
            })
    }

    return (
        <VerticalFlexLayout
            className={classes.main}
            spacing={3}>

            <Grid item style={{alignSelf: "center"}}>
                <img
                    src={strings("AppLogo")}
                    alt={strings("AppName")}
                    className={classes.logo}/>
            </Grid>

            <Typography
                component="h1"
                variant="h5"
                align={"center"}>
                {strings("Login")}
            </Typography>

            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={async fields => {
                    await login(fields);
                }}>
                <Form>
                    <VerticalFlexLayout spacing={3}>
                        <Field
                            name="username"
                            label={strings("Username")}
                            required
                            autoFocus
                            className={"force-ltr"}
                            component={InputFieldComponent}/>

                        <Field
                            name="password"
                            label={strings("Password")}
                            type="password"
                            required
                            className={"force-ltr"}
                            component={InputFieldComponent}/>

                        {isLoading ?
                            <HorizontalFlexLayout justify={"center"}>
                                <Loading size={"small"}/>
                            </HorizontalFlexLayout>
                            :
                            <Button
                                type="submit"
                                fullWidth
                                size={"large"}
                                variant="contained"
                                color="primary">
                                {strings("Login")}
                            </Button>
                        }
                    </VerticalFlexLayout>
                </Form>
            </Formik>
            {appSettings.id === "unipal" &&
                <Box mt={3}>
                    <Copyright/>
                </Box>}
        </VerticalFlexLayout>
    )
}

export default LoginControl
