import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {Box, Paper, Rating as MuiRating, ToggleButton, ToggleButtonGroup} from "@mui/material";
import Api from "util/api/Api";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import Rating from "models/Rating";
import Card from "components/common/Card";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import DynamicDataTable from "@unipal/common/DynamicDataTable";
import {useResources} from "contexts/ResourcesProvider";
import appSettings from "appSettings";
import {useNavigate} from "react-router-dom";
import {GetRatingsOutput} from "util/api/RatingsApi";
import {Body1, Body2, Heading4, Heading5} from "@unipal/ui/typography/Typography";
import Button from "@unipal/ui/button/Button";
import colors from "res/colors";
import RatingRow from "@unipal/components/rating/RatingRow";

const Index: FC = () => {
    const {strings} = useResources()
    const navigate = useNavigate()

    const [data, setData] = useState<GetRatingsOutput>()
    const [loading, setLoading] = useState(true)
    const [type, setType] = useState<"PURCHASE" | "FEEDBACK">("PURCHASE")

    // TODO: Move this to the DDT.
    // TODO: Support both next and previous page load.
    const token = useRef<string>()

    useEffect(() => {
        if (!appSettings.allowedFeatures?.includes("ratings")) {
            navigate("/")
            return
        }
    }, [navigate])

    const load = useCallback(async () => {
        await Api.ratings
            .getRatings(type, token.current)
            .then(data => {
                setLoading(false)
                const d = data as GetRatingsOutput
                setData(ps => {
                    if (!ps) {
                        return d
                    } else {
                        return {...ps, items: [...(ps.items ?? []), ...(d.items ?? [])]}
                    }
                })

                token.current = d.pagingToken
            })
    }, [type])

    return (<>
        <HtmlMetaDecorator
            title={strings("StudentReviews")}/>

        <Box p={4}>
            <VerticalFlexLayout spacing={4}>
                <GridItem>
                    <Heading5>
                        <strong>{strings("StudentReviews")}</strong>
                    </Heading5>
                    <Body2
                        paddingTop={2}
                        color={colors.copy}>
                        {strings("StudentRatingsTabDescription")}
                    </Body2>
                </GridItem>
                <ToggleButtonGroup
                    color="primary"
                    value={type}
                    exclusive
                    fullWidth
                    size={"large"}
                    sx={{backgroundColor: "white"}}
                    onChange={(e, v) => {
                        if (v !== type) {
                            token.current = undefined
                            setData(undefined)
                            setType(v)
                        }
                    }}>
                    <ToggleButton value="PURCHASE">
                        <Body1>
                            <strong>{strings("StudentRatingsTabTitle")}</strong>
                        </Body1>
                    </ToggleButton>
                    <ToggleButton value="FEEDBACK">
                        <Body1>
                            <strong>{strings("StudentFeedbacksTabTitle")}</strong>
                        </Body1>
                    </ToggleButton>
                </ToggleButtonGroup>
                <HorizontalFlexLayout spacing={4}>
                    <GridItem style={{flexGrow: 1}}>
                        <Card title={strings("TotalRatings")}
                              value={data?.count ?? '...'}
                              isLoading={loading}/>
                    </GridItem>
                    <GridItem style={{flexGrow: 1}}>
                        <Paper>
                            <Box p={3}>
                                <VerticalFlexLayout spacing={1}>
                                    <Body2 color={colors.copy}>
                                        {strings("AverageRating")}
                                    </Body2>
                                    {loading ?
                                        <div className="shimmering body" style={{marginTop: 16}}/>
                                        :
                                        <HorizontalFlexLayout
                                            spacing={2}
                                            alignItems={"center"}>
                                            <Heading4>
                                                <strong>
                                                    {data?.average?.toFixed(1) ?? '...'}
                                                </strong>
                                            </Heading4>
                                            {Boolean(data?.average) &&
                                                <MuiRating
                                                    readOnly
                                                    size={"large"}
                                                    value={data?.average ?? 0}/>}
                                        </HorizontalFlexLayout>}
                                </VerticalFlexLayout>
                            </Box>
                        </Paper>
                    </GridItem>
                    <GridItem style={{flexGrow: 1}}>
                        <Card title={strings("TotalReviews")}
                              value={data?.comments ?? '...'}
                              isLoading={loading}/>
                    </GridItem>
                </HorizontalFlexLayout>
            </VerticalFlexLayout>
        </Box>

        <Box px={2} pb={4}>
            <DynamicDataTable<Rating>
                showPaging={false}
                showDivider={false}
                columns={[]}
                load={load}
                total={data?.count ?? 0}
                data={data?.items ?? []}
                renderRow={rating => [
                    <RatingRow rating={rating}/>
                ]}/>
            {token.current &&
                <Button
                    fullWidth
                    onClick={load}
                    size={"large"}>
                    <Body1>
                        <strong>{strings("ViewMore")}</strong>
                    </Body1>
                </Button>}
        </Box>
    </>)
}

export default Index
