import React, {FC, PropsWithChildren} from "react"
import {Box} from "@mui/material"
import IconProps from "@unipal/icons/IconProps";
import {Body2} from "@unipal/ui/typography/Typography";

interface Props {
    color: string
    icon: FC<IconProps>
    onClick: () => void | Promise<void>
    iconColor?: string
}

const IconTextButton: FC<PropsWithChildren<Props>> = (props) => {
    return (
        <Box
            onClick={props.onClick}
            sx={{
                borderRadius: 1,
                padding: "4px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
                '&:hover': {
                    backgroundColor: props.color + '0a',
                }
            }}>
            <Box sx={{
                width: 32,
                height: 32,
                backgroundColor: props.color,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%"
            }}>
                <props.icon
                    size={16}
                    color={props.iconColor || "#fff"}/>
            </Box>
            <Body2
                fontWeight={"bold"}
                color={props.color}>
                {props.children}
            </Body2>
        </Box>
    )
}

export default IconTextButton
