import CurrencyCode from "@unipal/types/CurrencyCode"

export const getUuid = (): string => {
    return require("uuid").v4()
}

export const moneyString = (a: number, c: CurrencyCode): string => {
    return `${a.toFixed(3) ?? ""} ${CurrencyCode[c]}`
}

export const toShortDate = (date: string): string => {
    const timestamp = Date.parse(date)
    return isNaN(timestamp) ? "-" : new Date(timestamp).toLocaleDateString()
}

export function toAge(date: string) {
    const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000)

    let interval = seconds / 31536000
    if (interval > 1) {
        return Math.floor(interval) + "y"
    }
    interval = seconds / 604800
    if (interval > 1) {
        return Math.floor(interval) + "w"
    }
    interval = seconds / 86400
    if (interval > 1) {
        return Math.floor(interval) + "d"
    }
    interval = seconds / 3600
    if (interval > 1) {
        return Math.floor(interval) + "h"
    }
    interval = seconds / 60
    if (interval > 1) {
        return Math.floor(interval) + "m"
    }

    return "now"
}

export const buildUrl = (
    url: string,
    params?: Record<string, string | undefined>
): string => {
    const parts = url.trim().split('?')
    const lastPart = (parts[parts.length - 1] || '')
    const sp = new URLSearchParams()

    lastPart
        .split('&')
        .forEach(p => {
            // NOTE: Zero and False are valid values.
            const kvp = p.split('=').filter(s => (s?.trim() || '') !== '')
            if (kvp.length > 1) {
                sp.append(kvp[0].trim(), kvp[1].trim())
            }
        })

    params && Object.entries(params)
        .filter(([k, v]) => (k?.trim() || '') !== '' && (v?.trim() || '') !== '')
        .forEach(([k, v]) => sp.append(k.trim(), v!.trim()))

    return [parts[0], sp.toString()].join('?')
}
