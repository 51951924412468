import React, {FC, useCallback} from "react";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {Box, Dialog, Divider, IconButton, List, ListItem, Typography} from "@mui/material";
import colors from "res/colors";
import CloseIcon from "components/svgs/CloseIcon";
import i18n from "i18next";
import languages from "res/languages";
import appSettings from "appSettings";
import {useResources} from "contexts/ResourcesProvider";
import {getLanguageByCountry} from "util/Utilities";
import {useMerchantAppData} from "contexts/MerchantAppDataProvider";

interface Props {
    open: boolean
    onChange: () => void
}

const ChangeLanguageDialog: FC<Props> = (props) => {
    const {strings} = useResources()
    const {shop} = useMerchantAppData()

    const getLanguages = useCallback(() => {
        // NOTE: Show all supported language when not logged in, and only
        //  show English and shop's secondary language when logged in.
        const allowedLanguages = shop ?
            ["en", getLanguageByCountry(shop.countryCode)] :
            appSettings.allowedLanguages

        return languages
            .filter((v, i, a) => a.indexOf(v) === i)
            .filter(l => allowedLanguages.includes(l.code))
    }, [shop])

    const changeLanguage = async (locale: string) => {
        await i18n.changeLanguage(locale)
        props.onChange()
    }

    return (
        <Dialog
            fullWidth
            open={props.open}
            onClose={props.onChange}
            maxWidth={"xs"}>
            <VerticalFlexLayout>
                <HorizontalFlexLayout
                    spacing={1}
                    alignItems={"center"}>
                    <GridItem style={{flexGrow: 1}}>
                        <Box p={2} position={"relative"}>
                            <Typography
                                fontWeight={600}
                                color={colors.text}
                                variant={"body2"}>
                                {strings("LanguageSelection")}
                            </Typography>
                        </Box>
                    </GridItem>
                    <IconButton onClick={props.onChange}>
                        <CloseIcon/>
                    </IconButton>
                </HorizontalFlexLayout>
                <Divider/>
                <List sx={{p: 2}}>
                    {getLanguages().map(l =>
                        <ListItem
                            key={l.code}
                            button
                            onClick={() => changeLanguage(l.code)}>
                            <Typography
                                color={colors.text}
                                variant={"body1"}>
                                <strong>{l.name}</strong>
                            </Typography>
                        </ListItem>
                    )}
                </List>
            </VerticalFlexLayout>
        </Dialog>
    )
}

export default ChangeLanguageDialog
