import { AxiosRequestConfig, AxiosResponse } from "axios";
import ResultStatus from "../enums/ResultStatus";

class ApiClientHandlerResult<T extends AxiosRequestConfig | AxiosResponse> {

    status: ResultStatus = ResultStatus.NONE;
    message: string = "";
    data?: T;

    constructor(params: Partial<ApiClientHandlerResult<T>> = {}) {
        Object.assign(this, params);
    }

}

export default ApiClientHandlerResult;