import Strings from "models/Strings";
import {strings_en as default_en, strings_ar as default_ar} from "apps/default/strings";

const strings_en: Strings = {
    ...default_en,
    "AgreeToCampaignTermsErrorMessage": "Please agree to the T&C to proceed.",
    "AllTime": "All Time",
    "AppDescription": "Alive Verify provides to its partners QR based instant verification tool for ISIC/ITIC/AliveID cards. Just scan digital QR code in Alive or ISIC App and you will know if customer is elegible for your special offer or not.",
    "Apply": "Apply",
    "AppName": "Alive Verify",
    "Branch": "Branch",
    "Cancel": "Cancel",
    "Close": "Close",
    "Company": "Company",
    "Conditions": "Discount details",
    "ConfirmPurchase": "Confirm Purchase",
    "ContactUs": "Contact us",
    "CreatePurchaseSuccessMessage_Amount": "Purchase Confirmed",
    "Currency_AE": "AED",
    "Custom": "Custom",
    "NotEligible": "Not Eligible",
    "Dashboard": "Dashboard",
    "DataWillBeAvailableSoon": "Data will be available soon.",
    "Date": "Date",
    "DateRange": "Date Range",
    "Details": "Details",
    "Discount": "Discount",
    "DropUsMessage": "Drop us a message",
    "EmailAddress": "Email Address",
    "EnterValidAmount": "Please enter a valid amount.",
    "EnterVerificationValuePlaceholder": "Enter card number or Voucher",
    "FirstTimerStudents": "New First-Time Customers",
    "FirstTimerStudentsDescription_ShopName": "Students that have visited {0} for the first-time ever.",
    "ForgotPassword": "Forgot your password? Contact us.",
    "GotIt": "Got it",
    "Home": "Home",
    "InternalErrorMessage": "Internal Error! Please contact the Admin.",
    "LandingPageMessage": "Join over 200+ brands and connect with the student population.",
    "Leave": "Leave",
    "Login": "Login",
    "Logout": "Logout",
    "LogoutConfirmationMessage": "Are you sure you want to logout?",
    "LoyalStudents": "Loyal Customers",
    "LoyalStudentsDescription": "Number of students that have made more than 8 purchases and are considered loyal customers.",
    "MaleVsFemale": "Male vs Female",
    "Message": "Message",
    "Name": "Name",
    "NoDataAvailable": "No data are available!",
    "NoPermissionToAccessApp": "Sorry! It seems like you don't have enough permission to access the app!",
    "Notifications": "Notifications",
    "Now": "now",
    "Offer": "Offer",
    "PageNotFoundMessage": "We're sorry, the page you requested could not be found.",
    "PageNotFoundTitle": "Page Not Found",
    "Password": "Password",
    "PhoneNumber": "Phone Number",
    "PhoneOrEmail": "Phone/Email",
    "Position": "Position",
    "RetentionRate": "Retention Rate",
    "ReturningStudents": "Returning Customers",
    "ReturningStudentsDescription_ShopName": "Retained students that have visited {0} before.",
    "Saving": "Saving",
    "SavingAmount": "Saving amount",
    "ScanQrButtonText": "Scan AliveID",
    "ShopDashboard_ShopName": "{0}'s Dashboard",
    "StudentEligibleForTheseOffers": "This cardholder is eligible for these offers:",
    "StudentNotEligibleForAnyOffers": "This cardholder is NOT eligible for any offer.",
    "StudentVerification": "Cardholder verification",
    "Submit": "Submit",
    "SubmitRequest": "Submit Request",
    "TermsConditions": "Terms & Conditions",
    "ThisMonth": "This Month",
    "TotalPurchases": "Total Purchases",
    "TransactionsHistory": "Transactions History",
    "Type": "Type",
    "UnverifiedStudent": "Unverified cardholder",
    "Username": "Username",
    "Verified": "Verified",
    "VerifiedPurchase": "Verified Purchase",
    "VerifiedStudent": "Verified cardholder",
    "VerifyByValueButtonText": "Verify by card number",
    "Voucher": "Voucher",
    "WannaBecomePartnerMessage": "Want to become a partner? Contact us.",
    "WeGotYourMessage": "We got your message!",
    "WeWillContactYouMessage": "The GTS Alive  team will contact you within 24 hours.",
    "AgreeToCampaignTerms": "By checking this box, I agree to the Terms & Conditions.",
    "AverageRating": "Average Rating",
    "Campaigns": "Campaigns",
    "CardNumber": "Card Number",
    "Comment": "Comment",
    "Connect": "Connect",
    "Connected": "Connected",
    "Count": "Count",
    "Currency_BH": "BHD",
    "Currency_CZ": "CZK",
    "Currency_SA": "SAR",
    "EstGrossRevenue": "Est. Gross Revenue",
    "IntegrationsPageTip": "Integrate Unipal with your other systems to supercharge your workflow.",
    "IntegrationsPageTitle": "Integrations",
    "IntegrationSuccessPageMessage": "Our team will now complete the configuration and reach out to you to roll out the integration.",
    "IntegrationSuccessPageTitle": "Request Sent!",
    "Language": "Language",
    "LanguageSelection": "Language Selection",
    "Month": "Month",
    "Participants": "Participants",
    "Participate": "Participate",
    "PremiumOffer": "Haiba Offer",
    "Rating": "Rating",
    "RedeemVoucherSuccessMessage_Amount": "Confirmed! Voucher has been redeemed for {0}.",
    "RequestLeaveCampaign": "I would like to stop my participation in this campaign.",
    "RequestParticipateInCampaign": "I would like to participate in this campaign.",
    "ReturnToDashboard": "Return to Dashboard",
    "Review": "Review",
    "Settings": "Settings",
    "StandardOffer": "Standard Offer",
    "StudentReviews": "Student Reviews",
    "StudentRatingsTabTitle": "Student Reviews",
    "TotalRatings": "Total Ratings",
    "TotalReviews": "Total Reviews",
    "TryAgain": "Try again!",
    "University": "University",
    "YouAreNotParticipatingInCampaign": "You are not participating in this campaign",
    "YouAreParticipatingInCampaign": "You are participating in this campaign",
    "ReplyMessagePlaceholder": "Your reply...",
    "ViewMore": "View More",
    "You": "You",
    "UnipalTeam": "ِAlive Verify Team",
    "NoActiveOffersMessage": "We don't see any active offers under your account. To change that please contact us",
}

const strings_ar: Strings = {
    ...default_ar,
    "AgreeToCampaignTermsErrorMessage": "يُرجى الموافقة على الشروط والأحكام للمتابعة.",
    "AllTime": "في جميع الأوقات",
    "AppDescription": "توفر Alive Verify لشركائها أداة تحقق فورية تعتمد على QR لبطاقات ISIC / ITIC / AliveID. ما عليك سوى مسح رمز الاستجابة السريعة الرقمي ضوئيًا في Alive أو تطبيق ISIC وستعرف ما إذا كان العميل مناسبًا لعرضك الخاص أم لا.",
    "Apply": "قدم",
    "AppName": "معرف الايف",
    "Branch": "الفرع",
    "NotEligible": "غير مؤهل",
    "Cancel": "الغاء",
    "Close": "اغلاق",
    "Company": "الشركة",
    "Conditions": "الشروط",
    "ConfirmPurchase": "تأكيد الشراء",
    "ContactUs": "اتصل بنا",
    "CreatePurchaseSuccessMessage_Amount": "تم تأكيد الشراء",
    "Currency_AE": "د.إ",
    "Custom": "مخصص",
    "Dashboard": "لوحة التحكم الرئيسية",
    "DataWillBeAvailableSoon": "المعلومات سوف تتوفر قريبا",
    "Date": "اليوم",
    "DateRange": "النطاق الزمني",
    "Details": "التفاصيل",
    "Discount": "الخصم",
    "DropUsMessage": "ارسل لنا رسالة",
    "EmailAddress": "البريد الالكتروني",
    "EnterValidAmount": "الرجاء ادخال رقم صحيح",
    "EnterVerificationValuePlaceholder": "ادخل رقم البطاقة",
    "FirstTimerStudents": "طالب جديد/ اول مرة",
    "FirstTimerStudentsDescription_ShopName": "الطلاب الذين زاروا {0} للمرة الأولى على الإطلاق.",
    "ForgotPassword": "هل نسيت رقمك السري؟ اتصل بنا",
    "GotIt": "حصلت عليه",
    "Home": "الصفحة الرئيسية",
    "InternalErrorMessage": "خطأ داخلي! يرجى الاتصال بالمسؤول.",
    "LandingPageMessage": "انضم إلى أكثر من 200 علامة تجارية وتواصل مع الطلاب.",
    "Leave": "خروج",
    "Login": "تسجيل الدخول",
    "Logout": "تسجيل الخروج",
    "LogoutConfirmationMessage": "هل أنت متأكد أنك تريد تسجيل الخروج؟",
    "LoyalStudents": "الطلاب المخلصين",
    "LoyalStudentsDescription": "عدد الطلاب الذين أجروا أكثر من 8 عمليات شراء ويعتبرون عملاء مخلصين.",
    "MaleVsFemale": "الذكور والاناث",
    "Message": "الرسالة",
    "Name": "الاسم",
    "NoDataAvailable": "لا توجد بيانات متاحة!",
    "NoPermissionToAccessApp": "آسف! يبدو أنه ليس لديك الإذن الكافي للوصول إلى التطبيق!",
    "Notifications": "الإشعارات",
    "Now": "الان",
    "Offer": "عرض",
    "PageNotFoundMessage": "معذرةً ، الصفحة التي طلبتها غير موجودة.",
    "PageNotFoundTitle": "لم يتم العثور على الصفحة",
    "Password": "الرقم السري",
    "PhoneNumber": "رقم الهاتف",
    "PhoneOrEmail": "رقم الهاتف/ البريد الالكتروني",
    "Position": "الموقع",
    "RetentionRate": "معدل البقاء",
    "ReturningStudents": "عودة الطلاب",
    "ReturningStudentsDescription_ShopName": "الطلاب الذين احتفظوا بزيارتهم لـ {0} من قبل.",
    "Saving": "التوفير",
    "SavingAmount": "مبلغ التوفير",
    "ScanQrButtonText": "مسح بطاقة تعريف الايف",
    "ShopDashboard_ShopName": "لوحة تحكم {0}",
    "StudentEligibleForTheseOffers": "هذا الطالب مؤهل للحصول على العروض التالية:",
    "StudentNotEligibleForAnyOffers": "حامل البطاقة غير مؤهل لأي عرض.",
    "StudentVerification": "معرف الطالب",
    "Submit": "إرسال",
    "SubmitRequest": "إرسال الطلب",
    "TermsConditions": "الشروط والاحكام",
    "ThisMonth": "هذا الشهر",
    "TotalPurchases": "مجموع المشتريات",
    "TransactionsHistory": "تاريخ المعاملات",
    "Type": "النوع",
    "UnverifiedStudent": "حامل بطاقة غير مؤكد",
    "Username": "اسم المستخدم",
    "Verified": "معرف",
    "VerifiedPurchase": "شراء تم التحقق منه",
    "VerifiedStudent": "حامل البطاقة المعتمد",
    "VerifyByValueButtonText": "إدخال رقم البطاقة",
    "Voucher": "قسيمة",
    "WannaBecomePartnerMessage": "هل تريد ان تكون شريكا/ اتصل بنا",
    "WeGotYourMessage": "وصلتنا رسالتك",
    "WeWillContactYouMessage": "سيتصل بك فريق جي تي اس الايف في غضون 24 ساعة.",
    "NoActiveOffersMessage": "لا توجد أي عروض نشطة ضمن حسابك. لتغيير ذلك يرجى الاتصال بنا.",
}

const strings_cs: Strings = {
    ...strings_en,
    "AgreeToCampaignTermsErrorMessage": "Prosím, potvrďte podmínky aplikace, abyste mohli pokračovat.",
    "AllTime": "Od začátku",
    "AppDescription": "Alive Verify přináší svým partnerům okamžitý verifikační nástroj pro průkazy ISIC/ITIC/AliveID založený na QR kódech. Stačí jen naskenovat QR kód v aplikacíci Alive App nebo ISIC app a budete hned vědět, jestli má zákazník na slevu nárok",
    "Apply": "Potvrdit",
    "AppName": "Alive Verify",
    "Branch": "Pobočka",
    "CampaignRequestFailureMessage": "Omlouváme se. Vypadá to, že se něco nepovedlo, a nebyli jsme schopni poslat váš požadavek.",
    "CampaignRequestSuccessMessage": "Váš požadavek byl úspěšně poslán na GTS Alive. Tým vás bude kontaktovat během 24 hodin.",
    "Cancel": "Zrušit",
    "Close": "Zavřít",
    "Company": "Společnost",
    "Conditions": "Detail nabídky",
    "ConfirmPurchase": "Potvrdit nákup",
    "ContactUs": "Kontaktujte nás",
    "CreatePurchaseSuccessMessage_Amount": "Transakce potvrzena",
    "Currency_AE": "AED",
    "Custom": "Vlastní",
    "NotEligible": "Bez nároku na slevu",
    "Dashboard": "Dashboard",
    "DataWillBeAvailableSoon": "Data budou dostupná brzo",
    "Date": "Datum",
    "DateRange": "Datový rozsah",
    "Details": "Detaily",
    "Discount": "Sleva",
    "DropUsMessage": "Pošlete nám zprávu",
    "EmailAddress": "Emailová adresa",
    "EnterValidAmount": "Prosím zadejte správnou částku",
    "EnterVerificationValuePlaceholder": "Napište číslo průkazu",
    "FirstTimerStudents": "Nově nákupující zákaznící",
    "FirstTimerStudentsDescription_ShopName": "Zákazníci, kteří k vám přišli poprvé",
    "ForgotPassword": "Zapomněli jste heslo? Kontaktujte nás.",
    "GotIt": "Rozumím",
    "Home": "Domů",
    "InternalErrorMessage": "Chyba. Kontaktujte admina",
    "LandingPageMessage": "Přidejte se k tisícům partnerů a navažte spojení se studentskou komunitou.",
    "Leave": "Odejít",
    "Login": "Přihlášení",
    "Logout": "Odhlásit se",
    "LogoutConfirmationMessage": "Určitě se chcete odhlásit?",
    "LoyalStudents": "Věrní zákazníci",
    "LoyalStudentsDescription": "Počet zákazníků, kteří uskutečnili více než 8 nákupů jsou považováni za věrné zákazníky",
    "MaleVsFemale": "Muž vs Žena",
    "Message": "Zpráva",
    "Name": "Jméno",
    "NoDataAvailable": "Data nejsou dostupná",
    "NoPermissionToAccessApp": "Omlouváme se. Vypadá to, že nemáte dostatečné oprávnění pro přistup do aplikace",
    "Notifications": "Notifikace",
    "Now": "Teď",
    "Offer": "Nabídka",
    "PageNotFoundMessage": "Omlouváme se, ale stránka nebyla nalezena.",
    "PageNotFoundTitle": "Stránka nenalezena",
    "Password": "Heslo",
    "PhoneNumber": "Telefonní číslo",
    "PhoneOrEmail": "Telefon/Email",
    "Position": "Pozice",
    "RetentionRate": "Retenční míra",
    "ReturningStudents": "Vracející se zákazník",
    "ReturningStudentsDescription_ShopName": "Zákazník, který se k vám vrátil",
    "Saving": "Ušetřeno",
    "SavingAmount": "Ušetřená částka",
    "ScanQrButtonText": "Naskenovat AliveID",
    "ShopDashboard_ShopName": "{0} Dashboard",
    "StudentEligibleForTheseOffers": "Student má nárok na tyto slevy",
    "StudentNotEligibleForAnyOffers": "Tento zákazník nemá nárok na žádnou z nabídek.",
    "StudentVerification": "Tento měsíc",
    "Submit": "Odeslat",
    "SubmitRequest": "Poslat požadavek",
    "TermsConditions": "Podmínky použití",
    "ThisMonth": "Tento měsíc",
    "TotalPurchases": "Všechny prodeje",
    "TransactionsHistory": "Transakční historie",
    "Type": "Typ",
    "UnverifiedStudent": "Neověřený držitel průkazu",
    "Username": "Uživatelské jméno",
    "Verified": "Ověřen",
    "VerifiedPurchase": "Ověřený nákup",
    "VerifiedStudent": "Ověřený držitel průkazu",
    "VerifyByValueButtonText": "Ověřit pomocí čísla průkazu",
    "Voucher": "Kupón",
    "WannaBecomePartnerMessage": "Chcete se stát partnerem? Kontaktujte nás.",
    "WeGotYourMessage": "Dostali jsem vaši zprávu",
    "WeWillContactYouMessage": "GTS Alive tým vás bude kontaktovat během 24 hodin",
    "AgreeToCampaignTerms": "Zaškrtnutím tohoto políčka souhlasím s Všeobecnými podmínkami.",
    "AverageRating": "Průměrné hodnocení",
    "Campaigns": "Kampaně",
    "CardNumber": "Číslo průkazu",
    "Comment": "Komentář",
    "Connect": "Připojit",
    "Connected": "Připojeno",
    "Count": "Počet",
    "Currency_BH": "BHD",
    "Currency_CZ": "CZK",
    "Currency_SA": "SAR",
    "EstGrossRevenue": "Předpokládaný hrubý výnos",
    "IntegrationsPageTip": "Integrujte Alive Verify se svými dalšími systémy pro zrychlení vašich procesů.",
    "IntegrationsPageTitle": "Integrace",
    "IntegrationSuccessPageMessage": "Náš tým nyní dokončí konfiguraci a kontaktuje vás ohledně nasazení integrace.",
    "IntegrationSuccessPageTitle": "Žádost odeslána!",
    "Language": "Jazyk",
    "LanguageSelection": "Výběr jazyka",
    "Month": "Měsíc",
    "Participants": "Účastníci",
    "Participate": "Účastnit se",
    "PremiumOffer": " Nabídka",
    "Rating": "Hodnocení",
    "RedeemVoucherSuccessMessage_Amount": "Potvrzeno! Poukaz byl uplatněn pro {0}.",
    "RequestLeaveCampaign": "Chci ukončit svou účast v této kampani.",
    "RequestParticipateInCampaign": "Chci se účastnit této kampaně.",
    "ReturnToDashboard": "Návrat na přehled",
    "Review": "Recenze",
    "Settings": "Nastavení",
    "StandardOffer": "Standardní nabídka",
    "StudentReviews": "Recenze studentů",
    "StudentRatingsTabTitle": "Recenze studentů",
    "TotalRatings": "Celkové hodnocení",
    "TotalReviews": "Celkové recenze",
    "TryAgain": "Zkusit znovu!",
    "University": "Univerzita",
    "YouAreNotParticipatingInCampaign": "Neúčastníte se této kampaně",
    "YouAreParticipatingInCampaign": "Účastníte se této kampaně",
    "ReplyMessagePlaceholder": "Vaše odpověď...",
    "ViewMore": "Zobrazit více",
    "You": "Vy",
    "UnipalTeam": "Alive Verify tým",
    "StudentFeedbacksTabTitle": "Zpětná vazba od studentů",
    "StudentRatingsTabDescription": "Toto jsou hodnocení a recenze od studentů, kteří si zakoupili produkt. Tato hodnocení jsou veřejně dostupná v aplikaci Alive Verify.",
    "AccountNotLiveTitle": "Váš účet není aktivní!",
    "AccountNotLiveMessage": "Dokončete nastavení svého profilu, abychom vás mohli uvést do provozu 😀.",
    "UpdateAboutShopPageTitle": "O vašem obchodě",
    "UpdateAboutShopPageMessage": "Povězte nám něco o svém obchodě. To je to, co naši studenti vidí, když se dostanou na jakoukoli stránku spojenou s vaším obchodem.",
    "WebsiteFieldLabel": "Webová stránka (volitelné)",
    "InstagramFieldLabel": "Instagram (volitelné)",
    "Back": "Zpět",
    "Continue": "Pokračovat",
    "UpdateShopSocialPageTitle": "Odkazy na sociální média",
    "UpdateShopSocialPageMessage": "Studenti milují sociální média! Ujistěte se, že přidáte své účty, aby s vámi mohli komunikovat!",
    "UpdateOfferDetailsPageTitle": "Podrobnosti nabídky",
    "UpdateOfferDetailsPageMessage": "Řekněte nám, jakou výhodu dostávají držitelé karet ve vašem obchodě. To je to, co naši studenti vidí, když se dostanou na jakoukoli stránku spojenou s vaším obchodem.",
    "OfferTypePercentage": "Procento",
    "OfferTypeFixed": "Pevná částka",
    "OfferTypeFieldLabel": "Typ nabídky",
    "OfferValueFieldLabel": "Hodnota nabídky",
    "ZoomFieldLabel": "Přiblížení",
    "CompleteProfilePageTitle": "Profil je kompletní!",
    "CompleteProfilePageMessage": "Váš profil je připraven ke kontrole! Náš tým váš profil posoudí a ozve se vám do 3-5 dnů!",
    "SubmitForReview": "Odeslat ke konrole",
    "UploadImage": "Nahrát obrázek",
    "DragDropImageTip": "nebo přetáhněte obrázek k nahrání",
    "InvalidShopImagesMessage": "Nahrajte všechny požadované obrázky, prosím.",
    "ManageShopMediaPageTitle": "Správa médií",
    "ManageShopMediaPageMessage": "Zde se prezentujte, protože na to máte plné právo. Pamatujte, obrázek řekne tisíc slov, takže se ujistěte, že přidáte ty nejlepší!",
    "LivePreviewLabel": "Živý náhled",
    "UploadLogoLabel": "Logo",
    "UploadCoverLabel": "Obal",
    "UploadThumbnailLabel": "Náhled",
    "UploadLogoTip": "Nahrajte obrázek s poměrem stran 1:1.",
    "UploadCoverTip": "Nahrajte obrázek s poměrem stran 1:1.",
    "UploadThumbnailTip": "Nahrajte obrázek s poměrem stran 16:9.",
    "LivePreviewTip": "Takto se bude zobrazovat ve studentské aplikaci.",
    "ViewRatings": "Zobrazit hodnocení",
    "TotalRatings_Count": "{0} hodnocení",
    "ProfileSetupTitle": "Nastavení profilu",
    "Upload": "Nahrát",
    "CompleteNow": "Dokončit nyní",
    "ManageBranchesPageTitle": "Podrobnosti o pobočce",
    "ManageBranchesPageMessage": "Přidejte všechny své pobočky, aby se objevily na mapě Unipalu!\n\nPoznámka: Mapa Unipalu je nejoblíbenější funkcí studentů v aplikaci 😉.",
    "FromLabel": "Od:",
    "ToLabel": "Do:",
    "UnauthorizedPageTitle": "Nepovolený přístup",
    "NoActiveOffersMessage": "Nevidíme žádné aktivní nabídky připojené k vašemu účtu. Pokud toto chcete změnit, prosím kontaktujte nás",
}

export {
    strings_en,
    strings_ar,
    strings_cs
}
