import React, {FC} from 'react'
import {Routes, Route} from "react-router-dom"
import Login from "pages/account/login";
import NotFound from "pages/NotFound";

const AccountRouter: FC = () => {
    return (
        <Routes>
            <Route path="login" element={<Login/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    )
}

export default AccountRouter
