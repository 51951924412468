import BaseApi from "./BaseApi";
import Rating from "models/Rating";
import Reply from "models/Reply";
import LatestReply from "models/LatestReply";

export interface GetRatingsOutput {
    items: Rating[]
    count: number
    comments: number
    average: number
    pagingToken: string
}

export interface GetRepliesOutput {
    items: Reply[]
    rating: Rating
    count: number
    pagingToken: string
}

export interface GetLatestRepliesOutput {
    items: LatestReply[]
    pagingToken: string
}

class RatingsApi extends BaseApi {
    async getRating(
        id: string,
        type: string
    ): Promise<Rating | undefined> {
        const url = `business/ratings/${id}?type=${type}`
        return this.get(url)
    }

    async getRatings(
        type: string,
        token?: string
    ): Promise<GetRatingsOutput | undefined> {
        const url = `business/ratings?type=${type}&token=${token || ''}`
        return this.get(url)
    }

    async getRatingReplies(
        ratingId: string,
        shopId: string,
        studentId: string,
        type: string,
        _private: boolean,
        token?: string
    ): Promise<GetRepliesOutput | undefined> {
        const url = `business/ratings/${ratingId}/replies?shopId=${shopId}&studentId=${studentId}&type=${type}&private=${_private}&token=${token || ''}`
        return this.get(url)
    }

    async getLatestReplies(
        token?: string
    ): Promise<GetLatestRepliesOutput | undefined> {
        const url = `business/ratings/latest-replies?token=${token || ''}`
        return this.get(url)
    }

    async createReply(
        isPublic: boolean,
        content: string,
        ratingId: string,
        shopId: string,
        studentId: string,
        type: string
    ): Promise<Reply | undefined> {
        const url = `business/ratings/${ratingId}/replies`
        const body = {content, ratingId, shopId, studentId, type, public: isPublic}
        return this.post(url, body)
    }
}

export default RatingsApi
