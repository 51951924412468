import React, {FC} from "react"
import {Button as MuiButton} from "@mui/material"
import {ButtonProps} from "@mui/material/Button/Button"
import {Link} from "react-router-dom";

interface Props extends ButtonProps {
    to?: string
}

const Button: FC<Props> = (props) => {
    if (props.to) {
        return (
            // @ts-ignore
            <MuiButton
                {...props}
                component={Link}
                to={props.to}>
                {props.children}
            </MuiButton>
        )
    } else {
        return (
            <MuiButton {...props}>
                {props.children}
            </MuiButton>
        )
    }
}

export default Button
