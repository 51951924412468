import {FC} from "react";
import IconProps from "@unipal/icons/IconProps";
import {useTheme} from "@unipal/contexts/ThemeProvider";

const ChatIcon: FC<IconProps> = (props) => {
    const {colors} = useTheme()

    return (
        <svg width={props.size ?? "24"} height={props.size ?? "24"} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.82011 21.7064L1.00001 23L2.29361 17.1799C1.4417 15.5864 0.997292 13.8069 1.00001 12C1.00001 5.9247 5.92471 1 12 1C18.0753 1 23 5.9247 23 12C23 18.0753 18.0753 23 12 23C10.1931 23.0027 8.41359 22.5583 6.82011 21.7064V21.7064ZM7.13911 19.3821L7.85741 19.7671C9.13181 20.4481 10.555 20.803 12 20.8C13.7405 20.8 15.4419 20.2839 16.889 19.3169C18.3362 18.35 19.4641 16.9756 20.1301 15.3676C20.7962 13.7596 20.9705 11.9902 20.6309 10.2832C20.2914 8.57617 19.4532 7.00816 18.2225 5.77746C16.9918 4.54676 15.4238 3.70864 13.7168 3.36909C12.0098 3.02954 10.2404 3.20381 8.63239 3.86986C7.02441 4.53591 5.65003 5.66383 4.68308 7.11098C3.71612 8.55813 3.20001 10.2595 3.20001 12C3.20001 13.4674 3.55751 14.8798 4.23401 16.1426L4.61791 16.8609L3.89741 20.1026L7.13911 19.3821V19.3821ZM6.50001 12H8.70001C8.70001 12.8752 9.04769 13.7146 9.66656 14.3334C10.2854 14.9523 11.1248 15.3 12 15.3C12.8752 15.3 13.7146 14.9523 14.3335 14.3334C14.9523 13.7146 15.3 12.8752 15.3 12H17.5C17.5 13.4587 16.9205 14.8576 15.8891 15.8891C14.8576 16.9205 13.4587 17.5 12 17.5C10.5413 17.5 9.14237 16.9205 8.11092 15.8891C7.07947 14.8576 6.50001 13.4587 6.50001 12V12Z"
                fill={props.color ?? colors.primary}/>
        </svg>
    )
}

export default ChatIcon
