import {AxiosRequestConfig, AxiosResponse} from "axios";
import HandlerOutput from "./HandlerOutput";

export enum HandlerPriority {
    LOW = 0,
    NORMAL = 1,
    HIGH = 2
}

interface HandlerInput<T extends AxiosRequestConfig | AxiosResponse> {
    callback: ((obj: T) => Promise<HandlerOutput<T>>),
    priority: HandlerPriority
}

export default HandlerInput
