import {FC} from "react";
import IconProps from "@unipal/icons/IconProps";
import {useTheme} from "@unipal/contexts/ThemeProvider";

const EditIcon: FC<IconProps> = (props) => {
    const {colors} = useTheme()

    return (
        <svg width={props.size ?? "24"} height={props.size ?? "24"} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                stroke={props.color ?? colors.primary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                stroke={props.color ?? colors.primary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default EditIcon
