import React, {FC} from "react";
import {useTheme} from "@unipal/contexts/ThemeProvider";
import {GridItem, HorizontalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {Body1, Body2} from "@unipal/ui/typography/Typography";
import {Box} from "@mui/material";
import Avatar from "@unipal/ui/avatar/Avatar";
import IChatConversation from "@unipal/types/chat/IChatConversation";
import {toAge} from "@unipal/utils";
import IChatMessage from "@unipal/types/chat/IChatMessage";

interface Props {
    conversation: IChatConversation
    message: IChatMessage
}

const ChatNavItem: FC<Props> = (props) => {
    const {colors} = useTheme()
    const conv = props.conversation
    const msg = props.message

    return (
        <Box p={2} width={"100%"}>
            <HorizontalFlexLayout
                spacing={2}
                wrap={"nowrap"}>
                <Avatar
                    name={conv.name}
                    image={conv.image}/>
                <GridItem sx={{flexGrow: 1}}>
                    <Body1 fontWeight={"bold"}>
                        {conv.name}
                    </Body1>
                    <Body2 color={colors.copy}>
                        {msg.content}
                    </Body2>
                </GridItem>
                <Body2 color={colors.gray}>
                    {toAge(msg.created)}
                </Body2>
            </HorizontalFlexLayout>
        </Box>
    )
}

export default ChatNavItem
