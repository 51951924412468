import React from "react";
import {Typography, Link} from "@mui/material";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {`© 2019-${new Date().getFullYear()} `}
            <Link href="https://unipal.me/">Unipal, Inc.</Link>
            {" All rights reserved."}
        </Typography>
    );
}

export default Copyright;