import BaseApi from "./BaseApi";
import Shop from "models/Shop";

class ShopsApi extends BaseApi {
    async getShopAsync(): Promise<Shop | undefined> {
        return this.get(`business/shop`)
    }

    async updateAboutAsync(
        about?: string,
        altAbout?: string
    ): Promise<Shop | undefined> {
        const body = {about, altAbout}
        return this.patch(`business/about`, body)
    }

    async updateSocialAsync(
        url?: string,
        instagram?: string
    ): Promise<Shop | undefined> {
        const body = {url, instagram}
        return this.patch(`business/social`, body)
    }

    async completeProfileAsync(): Promise<void> {
        return this.post(`business/complete-profile`)
    }
}

export default ShopsApi
