import React, {FC, useEffect, useState} from 'react';
import {Box, Button, Paper, Typography} from "@mui/material";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import {useResources} from "contexts/ResourcesProvider";
import Api from "util/api/Api";
import ExternalSystem from "models/ExternalSystem";
import {LoadingPage} from "components/common/Loading";
import colors from "res/colors";

const Integrations: FC = () => {
    const {strings} = useResources()

    const [loading, setLoading] = useState(true)
    const [systems, setSystems] = useState<ExternalSystem[]>([])

    useEffect(() => {
        void async function fetchData() {
            const data = await Api.getExternalSystemsAsync() as ExternalSystem[]
            if (data) {
                setSystems(data)
            }

            setLoading(false)
        }()
    }, [])

    if (loading) {
        return (<LoadingPage/>)
    }

    return (<>
        <HtmlMetaDecorator title={strings("IntegrationsPageTitle")}/>

        <Box p={4}>
            <VerticalFlexLayout spacing={4}>
                <GridItem>
                    <Typography variant={"h5"}>
                        <strong>
                            {strings("IntegrationsPageTitle")}
                        </strong>
                    </Typography>
                    <Typography
                        variant="body1"
                        paddingTop={1}
                        style={{whiteSpace: "pre-wrap"}}>
                        {strings("IntegrationsPageTip")}
                    </Typography>
                </GridItem>
                <HorizontalFlexLayout>
                    {systems.map(s => (
                        <GridItem key={s.id} xs={12} sm={6} lg={4}>
                            <Paper>
                                <Box px={3} py={4}>
                                    <VerticalFlexLayout
                                        spacing={4}>
                                        <GridItem sx={{alignSelf: "center"}}>
                                            <img title={s.name} alt={s.name} src={s.logo}
                                                 style={{width: "100%", maxWidth: 200}}/>
                                        </GridItem>
                                        <Typography
                                            variant="body1"
                                            align={"center"}
                                            style={{whiteSpace: "pre-wrap"}}>
                                            {s.description}
                                        </Typography>
                                        {s.isActive ?
                                            <Typography
                                                variant="body1"
                                                align={"center"}
                                                color={colors.success}
                                                style={{whiteSpace: "pre-wrap"}}>
                                                <strong> {strings("Connected")}</strong>
                                            </Typography>
                                            :
                                            <Button
                                                component={"a"}
                                                href={s.url}
                                                size={"large"}
                                                variant={"contained"}
                                                fullWidth
                                                style={{textTransform: "none"}}>
                                                <strong>{strings("Connect")}</strong>
                                            </Button>
                                        }
                                    </VerticalFlexLayout>
                                </Box>
                            </Paper>
                        </GridItem>
                    ))}
                </HorizontalFlexLayout>
            </VerticalFlexLayout>
        </Box>
    </>)
}

export default Integrations
