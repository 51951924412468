import React, {FC} from 'react';
import {Box, Container} from "@mui/material";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import {VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {Body1} from "@unipal/ui/typography/Typography";
import appSettings from "appSettings";

const Status: FC = () => {
    return (<>
        <HtmlMetaDecorator
            title={"Status"}/>

        <div className={"login-page-bg"}>
            <div className={"login-page-content"}>
                <Container maxWidth="xs">
                    <Box sx={{
                        px: 2, py: 4, my: 2,
                        backgroundColor: "white",
                        borderRadius: 2
                    }}>
                        <VerticalFlexLayout
                            spacing={2}>
                            <Body1>
                                <strong>ID:</strong> {appSettings.id}
                            </Body1>
                            <Body1>
                                <strong>Version:</strong> {appSettings.version}
                            </Body1>
                        </VerticalFlexLayout>
                    </Box>
                </Container>
            </div>
        </div>
    </>)
}

export default Status
