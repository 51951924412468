import React, {FC} from 'react';
import {Box, Paper, Tooltip, Typography} from "@mui/material";
import Api from "util/api/Api";
import {VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import {DataPaginationOutput} from "util/api/models/DataPagination";
import DynamicDataTable, {Column} from "components/common/pagination/DynamicDataTable";
import Purchase from "models/Purchase";
import {useResources} from "contexts/ResourcesProvider";
import Strings from "models/Strings";
import {getCurrency} from "util/Utilities";
import appSettings from "appSettings";

function RenderRow(purchase: Purchase, strings: (key: keyof Strings, ...values: any[]) => string): (string | JSX.Element)[] {
    const date = new Date(purchase.createdAt)
    // TODO: Handle this on the server.
    const name = purchase.studentName?.split(' ')[0] ?? "N/A"

    const createdAt = <Tooltip title={`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`} arrow>
        <span>{date.toLocaleDateString()}</span>
    </Tooltip>

    return appSettings.id === "unipal" ? [name, purchase.universityName, purchase.branchName,
        `${purchase.savings.toFixed(3)} ${getCurrency(purchase.countryCode, strings)}`,
        purchase.discount, createdAt] : [name, purchase.branchName, purchase.discount, createdAt]
}

const Purchases: FC = () => {
    const {strings} = useResources()

    async function onFetchData(limit: number, offset: number, sortBy?: keyof Purchase, sortDirection?: 'asc' | 'desc') {
        return await Api.purchase.getPurchasesAsync({
            limit: limit, offset: offset, sortBy: sortBy, sortDirection: sortDirection
        }).then(data => {
            return data as DataPaginationOutput<Purchase>
        })
    }

    const columns: Column<Purchase>[] = appSettings.id === "unipal" ? [
        {id: "studentName", label: strings("Name")},
        {id: "universityName", label: strings("University")},
        {id: "branchName", label: strings("Branch")},
        {id: "savings", label: strings("Saving")},
        {id: "discount", label: strings("Discount")},
        {id: "createdAt", label: strings("Date")}
    ] : [
        {id: "studentName", label: strings("CardNumber")},
        {id: "branchName", label: strings("Branch")},
        {id: "discount", label: strings("Discount")},
        {id: "createdAt", label: strings("Date")}
    ]

    return (<>
        <HtmlMetaDecorator title={strings("TransactionsHistory")}/>

        <Box p={4}>
            <VerticalFlexLayout spacing={4}>
                <Typography variant={"h5"}>
                    <strong>{strings("TransactionsHistory")}</strong>
                </Typography>
                <Paper>
                    <Box p={3}>
                        <DynamicDataTable<Purchase>
                            columns={columns}
                            sortBy={"createdAt"}
                            sortDirection={"desc"}
                            renderRow={p => RenderRow(p, strings)}
                            rowsPerPage={10}
                            fetchData={onFetchData}/>
                    </Box>
                </Paper>
            </VerticalFlexLayout>
        </Box>
    </>)
}

export default Purchases
