import React from "react";
import {Typography as MuiTypography} from "@mui/material";
import {TypographyProps} from "@mui/material/Typography/Typography";

interface Props extends TypographyProps {
    component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p' | 'label';
}

export const Typography: React.FC<Props> = (props) => (
    <MuiTypography   {...props} >
        {props.children}
    </MuiTypography>
)

export const Heading1: React.FC<Props> = (props) => (
    <MuiTypography variant={"h1"}  {...props}>
        {props.children}
    </MuiTypography>
)

export const Heading2: React.FC<Props> = (props) => (
    <MuiTypography variant={"h2"} {...props}>
        {props.children}
    </MuiTypography>
)

export const Heading3: React.FC<Props> = (props) => (
    <MuiTypography variant={"h3"} {...props}>
        {props.children}
    </MuiTypography>
)

export const Heading4: React.FC<Props> = (props) => (
    <MuiTypography variant={"h4"} {...props}>
        {props.children}
    </MuiTypography>
)

export const Heading5: React.FC<Props> = (props) => (
    <MuiTypography variant={"h5"} {...props}>
        {props.children}
    </MuiTypography>
)

export const Heading6: React.FC<Props> = (props) => (
    <MuiTypography variant={"h6"} {...props}>
        {props.children}
    </MuiTypography>
)

export const Body1: React.FC<Props> = (props) => (
    <MuiTypography variant={"body1"} {...props}>
        {props.children}
    </MuiTypography>
)

export const Body2: React.FC<Props> = (props) => (
    <MuiTypography variant={"body2"} {...props}>
        {props.children}
    </MuiTypography>
)
