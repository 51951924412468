import {ApiClientHandlers} from "util/api/client/ApiClient";
import {AxiosRequestConfig} from "axios";
import ApiClientHandlerResult from "util/api/client/models/ApiClientHandlerResult";

class AuthHelper {
    private _accessToken: string = "";

    get accessToken(): string {
        return this._accessToken;
    }

    setAccessToken(token: string) {
        this.clear();
        // NOTE: Subscribe the handlers before anything, because they are needed for the next request here.
        this.subscribeApiClientHandlers();
        this._accessToken = token;
    }

    clear() {
        this._accessToken = "";
        this.unsubscribeApiClientHandlers();
    }

    subscribeApiClientHandlers() {
        ApiClientHandlers.addRequestHandler(async (request: AxiosRequestConfig) => {
            if (this._accessToken) {
                request.headers = request.headers ? request.headers : {}
                request.headers['Authorization'] = `Bearer ${this.accessToken}`;
            }

            return new ApiClientHandlerResult({data: request});
        });
    }

    unsubscribeApiClientHandlers() {
        ApiClientHandlers.clearRequestHandlers();
    }
}

const instance = new AuthHelper();
export default instance;