import React, {FC} from "react";
import InfoIcon from "@unipal/icons/InfoIcon";
import WarningIcon from "@unipal/icons/WarningIcon";
import {Box, Paper} from "@mui/material";
import {GridItem, HorizontalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {Body2} from "@unipal/ui/typography/Typography";

interface Props {
    title?: string
    message?: string
    action?: React.ReactNode
    severity?: "error" | "info" | "warning"
}

const Alert: FC<Props> = (props) => {
    let color, bg, icon

    if (props.severity === "error") {
        color = "#ff0000"
        bg = "#ffefef"
        icon = <InfoIcon color={color}/>
    } else if (props.severity === "warning") {
        color = "#ff9800"
        bg = "#fff7eb"
        icon = <WarningIcon color={color}/>
    } else {
        color = "#03a9f4"
        bg = "#ecf8ff"
        icon = <InfoIcon color={color}/>
    }

    return (
        <Paper
            variant={"outlined"}
            sx={{
                border: `1px solid ${color}`,
                backgroundColor: bg
            }}>
            <Box p={2}>
                <HorizontalFlexLayout
                    spacing={2}
                    alignItems={"center"}>
                    {icon}
                    <GridItem sx={{flexGrow: 1}}>
                        {props.title &&
                            <Body2>
                                <strong>{props.title}</strong>
                            </Body2>}
                        {props.message &&
                            <Body2 paddingTop={props.title ? 1 : 0}>
                                {props.message}
                            </Body2>}
                    </GridItem>
                    {props.action && props.action}
                </HorizontalFlexLayout>
            </Box>
        </Paper>
    )
}

export default Alert
