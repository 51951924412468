import React, {FC, useState} from 'react';
import {
    Box, Button, Paper, Typography,
    Dialog, DialogActions, DialogContent, DialogTitle
} from "@mui/material";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import Api from "util/api/Api";
import {Field, Form, Formik, FormikState} from "formik";
import InputFieldComponent from "components/helpers/InputFieldComponent";
import Loading from "components/common/Loading";
import Communication from "models/Communication";
import {useResources} from "contexts/ResourcesProvider";

const ContactUs: FC = () => {
    const {strings} = useResources()

    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = React.useState(false)

    async function submit(fields: Communication, resetForm: (nextState?: Partial<FormikState<Communication>>) => void) {
        setIsLoading(true)

        // TODO: Handle the property errors.
        await Api.sendCommunication(fields).then(data => {
            if (data) {
                setOpen(true)
                resetForm({values: {name: "", phone: "", message: ""}})
            }
        })

        setIsLoading(false)
    }

    return (<>
        <HtmlMetaDecorator title={strings("ContactUs")}/>

        <Box p={4}>
            <VerticalFlexLayout spacing={4}>
                <Typography variant={"h5"}>
                    <strong>{strings("DropUsMessage")}</strong>
                </Typography>

                <Paper>
                    <Box p={2}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={{message: ""} as Communication}
                            onSubmit={async (fields, {resetForm}) => {
                                await submit(fields, resetForm)
                            }}>
                            <Form>
                                <HorizontalFlexLayout spacing={3}>
                                    <GridItem xs={12} md={6}>
                                        <Field
                                            name="name"
                                            label={strings("Name")}
                                            required
                                            autoFocus
                                            component={InputFieldComponent}/>
                                    </GridItem>
                                    <GridItem xs={12} md={6}>
                                        <Field
                                            name="phone"
                                            label={strings("PhoneOrEmail")}
                                            required
                                            component={InputFieldComponent}/>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <Field
                                            name="message"
                                            label={strings("Message")}
                                            required
                                            multiline
                                            component={InputFieldComponent}/>
                                    </GridItem>

                                    {isLoading ?
                                        <HorizontalFlexLayout justify={"center"}>
                                            <Loading size={"small"}/>
                                        </HorizontalFlexLayout>
                                        :
                                        <Button
                                            type="submit"
                                            color={"primary"}
                                            variant={"contained"}
                                            size={"large"}
                                            fullWidth
                                            style={{
                                                textTransform: "none",
                                                fontWeight: 700,
                                                fontSize: 18
                                            }}>
                                            {strings("Submit")}
                                        </Button>
                                    }
                                </HorizontalFlexLayout>
                            </Form>
                        </Formik>
                    </Box>
                </Paper>
            </VerticalFlexLayout>
        </Box>

        <Dialog
            open={open}
            maxWidth={"sm"}
            onClose={() => setOpen(ps => !ps)}>
            <DialogTitle>
                <Typography variant={"h6"}>
                    <strong>{strings("WeGotYourMessage")}</strong>
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant={"body2"}>
                    {strings("WeWillContactYouMessage")}
                </Typography>
            </DialogContent>
            <DialogActions sx={{padding: 2, paddingTop: 0}}>
                <Button size={"large"} variant={"contained"}
                        onClick={() => setOpen(ps => !ps)}
                        style={{textTransform: "none"}}>
                    <strong>{strings("GotIt")}</strong>
                </Button>
            </DialogActions>
        </Dialog>
    </>)
}

export default ContactUs
