import React, {FC, useCallback, useState} from "react";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {Body2} from "@unipal/ui/typography/Typography";
import {useResources} from "contexts/ResourcesProvider";
import {Dialog, DialogActions, DialogContent, Slider} from "@mui/material";
import Cropper, {Area} from "react-easy-crop";
import Button from "@unipal/ui/button/Button";
import RatioContainer from "@unipal/common/RatioContainer";

async function getCroppedImg(
    src: string,
    croppedArea: Area,
    quality?: number,
    maxSize?: number
): Promise<Blob | undefined> {
    const image = new Image()
    image.addEventListener('load', () => image)
    image.addEventListener('error', (error) => error)
    image.src = src

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (!ctx) {
        return undefined
    }

    canvas.width = image.width;
    canvas.height = image.height;

    ctx.translate(image.width / 2, image.height / 2)
    ctx.translate(-image.width / 2, -image.height / 2)
    ctx.drawImage(image, 0, 0)

    const data = ctx.getImageData(croppedArea.x, croppedArea.y, croppedArea.width, croppedArea.height)
    if (!data) {
        return undefined
    }

    canvas.width = croppedArea.width
    canvas.height = croppedArea.height
    ctx.putImageData(data, 0, 0)

    if (maxSize && (canvas.width > 800 || canvas.height > 800)) {
        const resizedCanvas = document.createElement('canvas')
        const resizedCtx = resizedCanvas.getContext('2d')
        if (!resizedCtx) {
            return undefined
        }

        const aspectRatio = canvas.width / canvas.height
        let resizedWidth = canvas.width
        let resizedHeight = canvas.height

        if (canvas.width > maxSize) {
            resizedWidth = maxSize
            resizedHeight = maxSize / aspectRatio
        }

        if (resizedHeight > maxSize) {
            resizedHeight = maxSize
            resizedWidth = maxSize * aspectRatio
        }

        resizedCanvas.width = resizedWidth
        resizedCanvas.height = resizedHeight
        resizedCtx.drawImage(canvas, 0, 0, resizedWidth, resizedHeight)

        canvas.width = resizedWidth
        canvas.height = resizedHeight
        ctx.drawImage(resizedCanvas, 0, 0, resizedWidth, resizedHeight)
    }

    return new Promise((resolve) => {
        canvas.toBlob((file) => {
            if (file instanceof Blob) {
                resolve(file)
            }
        }, 'image/jpeg', quality)
    })
}

export interface Props {
    image: string
    onCrop: (image: Blob) => void
    onClose: () => void
    aspect?: number
    maxSize?: number
    quality?: number
}

const ImageCropperControl: FC<Props> = (props) => {
    const {strings} = useResources()

    const [crop, setCrop] = useState({x: 0, y: 0})
    const [zoom, setZoom] = useState<number>(1)
    const [croppedArea, setCroppedArea] = useState<Area>()

    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedArea(croppedAreaPixels)
    }, [])

    const getCroppedImage = useCallback(async () => {
        if (croppedArea) {
            const cropped = await getCroppedImg(props.image, croppedArea, props.quality, props.maxSize)
            if (cropped) {
                props.onCrop(cropped)
            }
        }
    }, [props, croppedArea])

    const onClose = () => props.onClose()

    return (
        <Dialog
            open={true}
            maxWidth={"sm"}
            fullWidth>
            <DialogContent sx={{padding: 2}}>
                <RatioContainer ratio={props.aspect || 1}>
                    <Cropper
                        image={props.image}
                        crop={crop}
                        zoom={zoom}
                        aspect={props.aspect || 1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}/>
                </RatioContainer>
            </DialogContent>
            <DialogActions sx={{padding: 2, paddingTop: 0}}>
                <VerticalFlexLayout spacing={4}>
                    <GridItem>
                        <Body2 marginBottom={1}>
                            <strong>
                                {strings("ZoomFieldLabel")}
                            </strong>
                        </Body2>
                        <Slider
                            min={1}
                            max={3}
                            step={0.1}
                            value={zoom}
                            onChange={(e, zoom) => setZoom(zoom as number)}/>
                    </GridItem>
                    <HorizontalFlexLayout
                        spacing={2}>
                        <GridItem sx={{flexGrow: 1}}>
                            <Button
                                fullWidth
                                variant={"outlined"}
                                onClick={onClose}>
                                {strings("Cancel")}
                            </Button>
                        </GridItem>
                        <GridItem sx={{flexGrow: 1}}>
                            <Button
                                fullWidth
                                variant={"contained"}
                                onClick={getCroppedImage}>
                                {strings("Upload")}
                            </Button>
                        </GridItem>
                    </HorizontalFlexLayout>
                </VerticalFlexLayout>
            </DialogActions>
        </Dialog>
    )
}

export default ImageCropperControl
