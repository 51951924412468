import React, {FC, useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Box, Divider, Paper} from "@mui/material";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import {useResources} from "contexts/ResourcesProvider";
import colors from "res/colors";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {Body1, Body2, Heading6} from "@unipal/ui/typography/Typography";
import UpsertAboutControl from "components/shop/UpsertAboutControl";
import Button from "@unipal/ui/button/Button";
import LoadingButton from "@unipal/ui/button/LoadingButton";
import UpsertSocialControl from "components/shop/UpsertSocialControl";
import UpsertOfferControl from "components/offer/UpsertOfferControl";
import UpsertMediaControl, {Ref} from "components/shop/UpsertMediaControl";
import ShopDetailsConfirmationControl from "components/shop/ShopDetailsConfirmationControl";
import appSettings from "appSettings";
import {useNavigate} from "react-router-dom";
import {useMerchantAppData} from "contexts/MerchantAppDataProvider";
import ShopStatus from "models/types/ShopStatus";
import BranchesList from "components/branch/BranchesList";
import CheckCircleIcon from "@unipal/icons/CheckCircleIcon";
import paths from "@unipal/utils/paths";
import Alert from "@unipal/ui/alert/Alert";
import {ArrowBack} from "@mui/icons-material";

const ProfileSetup: FC = () => {
    const {shop, reload} = useMerchantAppData()
    const {strings} = useResources()
    const navigate = useNavigate()

    const [step, setStep] = useState(0)

    useEffect(() => {
        if (!appSettings.allowedFeatures?.includes("setup") ||
            ![ShopStatus.NEW, ShopStatus.REJECTED].includes(shop.status)) {
            navigate("/")
            return
        }

        void async function fetchData() {
            await reload()
        }()

        return () => {
            void async function fetchData() {
                await reload()
            }()
        }
    }, [navigate, shop.status, reload])

    useLayoutEffect(() => window.scrollTo(0, 0), [step])

    const currentStep = useRef<Ref>(null)

    const nextStep = useCallback(async () => {
        if (await currentStep?.current?.isValid() ?? true) {
            setStep(ps => ++ps)
        }
    }, [])

    const onComplete = useCallback(async () => await nextStep(), [nextStep])

    const prevStep = () => setStep(ps => --ps)

    const onLoad = () => {
    }

    const steps = [
        {
            formId: "SetupAboutShop",
            component: <UpsertAboutControl onLoad={onLoad} onComplete={onComplete}/>
        },
        {
            formId: "SetupOfferDetails",
            component: <UpsertOfferControl onLoad={onLoad} onComplete={onComplete}/>
        },
        {
            formId: "SetupShopSocial",
            component: <UpsertSocialControl onLoad={onLoad} onComplete={onComplete}/>
        },
        {
            component: <UpsertMediaControl ref={currentStep} onLoad={onLoad}/>
        },
        {
            component: <BranchesList/>
        },
        {
            component: <ShopDetailsConfirmationControl/>
        }
    ]

    const items = [
        {title: strings("UpdateAboutShopPageTitle"), duration: "3"},
        {title: strings("UpdateOfferDetailsPageTitle"), duration: "4"},
        {title: strings("UpdateShopSocialPageTitle"), duration: "2"},
        {title: strings("ManageShopMediaPageTitle"), duration: "2"},
        {title: strings("ManageBranchesPageTitle"), duration: "3-5"},
    ]

    return (<>
        <HtmlMetaDecorator
            title={strings("ProfileSetupTitle")}/>

        <Box p={4}>
            <VerticalFlexLayout spacing={4}>
                <Button
                    to={paths.dashboard()}
                    color={"inherit"}
                    startIcon={<ArrowBack/>}>
                    {strings("BackToDashboard")}
                </Button>
                {shop.status === ShopStatus.REJECTED &&
                    <Alert
                        severity="error"
                        title={strings("ProfileDetailsRejectedTitle")}
                        message={shop.rejectionMessage}/>}
                <HorizontalFlexLayout
                    spacing={4}
                    wrap={"nowrap"}>
                    <Paper>
                        <VerticalFlexLayout>
                            {items.map((s, i) => {
                                const current = step === i
                                const done = step > i
                                const weight = current ? "bold" : undefined
                                const color = current ? colors.primary :
                                    done ? colors.gray : undefined

                                return [
                                    <>{i > 0 && <Divider/>}</>,
                                    <Box p={3}>
                                        <HorizontalFlexLayout
                                            spacing={4}
                                            wrap={"nowrap"}
                                            alignItems={"center"}>
                                            <GridItem sx={{flexGrow: 1}}>
                                                <Body1
                                                    fontWeight={weight}
                                                    color={color}>
                                                    {s.title}
                                                </Body1>
                                            </GridItem>
                                            {done ?
                                                <CheckCircleIcon
                                                    size={20}
                                                    color={colors.success}/>
                                                :
                                                <Body2
                                                    textAlign={"center"}
                                                    fontWeight={weight}
                                                    color={color}>
                                                    {s.duration} min
                                                </Body2>}
                                        </HorizontalFlexLayout>
                                    </Box>
                                ]
                            })}
                        </VerticalFlexLayout>
                    </Paper>
                    <GridItem sx={{flexGrow: 1}}>
                        <VerticalFlexLayout spacing={4}>
                            <Paper>
                                <Box p={4}>
                                    {step < steps.length - 1 &&
                                        <HorizontalFlexLayout
                                            spacing={2}
                                            wrap={"nowrap"}
                                            alignItems={"center"}>
                                            <Box width={56}/>
                                            <Heading6 color={colors.gray}>
                                                {strings("ProfileSetupTitle")}
                                            </Heading6>
                                        </HorizontalFlexLayout>}
                                    {steps[step].component}
                                </Box>
                            </Paper>
                            <HorizontalFlexLayout
                                spacing={2}
                                justify={"space-between"}>
                                <GridItem>
                                    {step !== 0 &&
                                        <Button onClick={prevStep}>
                                            {strings("Back")}
                                        </Button>}
                                </GridItem>
                                {step < steps.length - 1 &&
                                    <LoadingButton
                                        type={steps[step].formId ? "submit" : undefined}
                                        form={steps[step].formId ? steps[step].formId : undefined}
                                        onClick={steps[step].formId ? undefined : nextStep}
                                        variant={"contained"}>
                                        {strings("Continue")}
                                    </LoadingButton>}
                            </HorizontalFlexLayout>
                        </VerticalFlexLayout>
                    </GridItem>
                </HorizontalFlexLayout>
            </VerticalFlexLayout>
        </Box>
    </>)
}

export default ProfileSetup
