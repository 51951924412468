import appSettings from "appSettings";

const baseUrl = appSettings.urls.base

const config = {
    authenticationUrl: `${baseUrl}/token`,
    apiUrl: `${baseUrl}/api`
}

export default config
