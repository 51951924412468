import BaseApi from "./BaseApi";
import ActivityFeed from "models/ActivityFeed";
import {DataPaginationInput, DataPaginationOutput} from "util/api/models/DataPagination";

class ActivityFeedApi extends BaseApi {
    async getFeedsAsync(pagination: DataPaginationInput<ActivityFeed>):
        Promise<DataPaginationOutput<ActivityFeed> | undefined> {
        return this.get(`business/feed?${this.getPaginationQuery(pagination)}`)
    }
}

export default ActivityFeedApi
