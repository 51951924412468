import React, {FC} from 'react';
import {Box, Button, Container} from "@mui/material";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import {VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {useResources} from "contexts/ResourcesProvider";
import {Body1} from '@unipal/ui/typography/Typography';
import {useAuth} from "contexts/AuthProvider";

const Unauthorized: FC = () => {
    const {clearAuth} = useAuth()
    const {strings} = useResources()

    return (<>
        <HtmlMetaDecorator
            title={strings("UnauthorizedPageTitle")}/>

        <div className={"login-page-bg"}>
            <div className={"login-page-content"}>
                <Container maxWidth="xs">
                    <Box px={2} py={4} my={2}
                         style={{backgroundColor: "white", borderRadius: 12}}>
                        <VerticalFlexLayout
                            spacing={3}
                            alignItems={"center"}>
                            <Body1 align={"center"}>
                                <strong>
                                    {strings("NoPermissionToAccessApp")}
                                </strong>
                            </Body1>
                            <Button
                                variant={"outlined"}
                                onClick={() => clearAuth()}>
                                <strong>{strings("TryAgain")}</strong>
                            </Button>
                        </VerticalFlexLayout>
                    </Box>
                </Container>
            </div>
        </div>
    </>)
}

export default Unauthorized
