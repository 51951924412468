import React, {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useState} from "react";
import {LoadingPage} from "components/common/Loading";
import Api from "util/api/Api";
import MerchantAppData from "viewModels/MerchantAppData";
import i18n from "i18next";
import {getLanguageByCountry} from "util/Utilities";
import Unauthorized from "pages/account/Unauthorized";

interface State {
    isLoading: boolean
    data?: MerchantAppData
}

export const MerchantAppDataContext =
    createContext<MerchantAppData>({} as MerchantAppData)

export const useMerchantAppData = () =>
    useContext(MerchantAppDataContext)

const MerchantAppDataProvider: FC<PropsWithChildren> = (props) => {
    const [state, setState] = useState<State>({
        isLoading: true
    })

    const load = useCallback(async () => {
        await Api.getMerchantAppDataAsync()
            .then(async data => {
                setState({
                    data: data,
                    isLoading: false
                })

                if (data && !i18n.language?.startsWith("en")) {
                    const locale = getLanguageByCountry(data.shop.countryCode)
                    await i18n.changeLanguage(locale)
                }
            })
            .catch(() => {
                setState({isLoading: false})
            })
    }, [])

    useEffect(() => {
        void async function fetchData() {
            await load()
        }()
    }, [load])

    if (state.isLoading) {
        return (<LoadingPage/>)
    } else if (!state.data) {
        return (<Unauthorized/>)
    } else {
        return (
            <MerchantAppDataContext.Provider
                value={{...state.data, reload: load}}>
                {props.children}
            </MerchantAppDataContext.Provider>
        )
    }
}

export default MerchantAppDataProvider
