import {AxiosRequestConfig, AxiosResponse} from "axios";

export enum HandlerStatus {
    NONE,
    RESEND,
    ABORT
}

class HandlerOutput<T extends AxiosRequestConfig | AxiosResponse> {
    status: HandlerStatus = HandlerStatus.NONE
    message: string = ""
    data?: T

    constructor(params: Partial<HandlerOutput<T>> = {}) {
        Object.assign(this, params)
    }
}

export default HandlerOutput
