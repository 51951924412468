import Strings from "models/Strings";
import CountryCode from "models/types/CountryCode";

export const getUuid = (): string => {
    return require("uuid").v4()
}

export const getCurrency = (code: CountryCode, strings: (key: keyof Strings, ...values: any[]) => string) => {
    const key = CountryCode[code]
    // @ts-ignore
    return key ? strings(`Currency_${key}`) : ""
}

export function toAge(date: string) {
    const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000)

    let interval = seconds / 31536000
    if (interval > 1) {
        return Math.floor(interval) + "y"
    }
    interval = seconds / 604800
    if (interval > 1) {
        return Math.floor(interval) + "w"
    }
    interval = seconds / 86400
    if (interval > 1) {
        return Math.floor(interval) + "d"
    }
    interval = seconds / 3600
    if (interval > 1) {
        return Math.floor(interval) + "h"
    }
    interval = seconds / 60
    if (interval > 1) {
        return Math.floor(interval) + "m"
    }

    return "now"
}

export function toShortDate(value: string) {
    const date = new Date(value)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    return `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`
}

export const getLanguageByCountry = (code: CountryCode) => {
    if ([
        CountryCode.BH,
        CountryCode.SA,
        CountryCode.AE
    ].includes(code)) {
        return 'ar'
    } else if (CountryCode.CZ === code) {
        return 'cs'
    } else {
        return "en"
    }
}
