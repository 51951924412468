import React, {FC} from 'react';
import {Box, Button, Paper, Typography} from "@mui/material";
import Api from "util/api/Api";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import ActivityFeed from "models/ActivityFeed";
import RatioContainer from "components/common/RatioContainer";
import colors from "res/colors";
import {Link, useNavigate} from "react-router-dom"
import {DataPaginationOutput} from "util/api/models/DataPagination";
import DynamicDataTable from "components/common/pagination/DynamicDataTable";
import {useResources} from "contexts/ResourcesProvider";
import appSettings from "appSettings";
import {LoadingPage} from "components/common/Loading";
import {toAge} from 'util/Utilities';

function RenderRow(review: ActivityFeed): (string | JSX.Element)[] {
    return [(
        <HorizontalFlexLayout spacing={3} wrap={"nowrap"}>
            <RatioContainer ratio={1} width={48}>
                <img
                    width={"100%"}
                    height={"100%"}
                    src={review.thumbnailUrl}
                    alt={review.name}
                    style={{objectFit: "cover", borderRadius: 8}}/>
            </RatioContainer>
            <GridItem sx={{flexGrow: 1}}>
                <VerticalFlexLayout spacing={1}>
                    <Typography variant={"body2"}>
                        <strong>{review.name}</strong>
                    </Typography>
                    <Typography variant={"body2"}>{review.description}</Typography>
                    {
                        review.value && review.actionText &&
                        <Link to={review.value}>
                            <Button component={"a"} target="_blank" rel="noreferrer" variant={"outlined"}
                                    size={"small"}>{review.actionText}</Button>
                        </Link>
                    }
                </VerticalFlexLayout>
            </GridItem>
            <Typography variant={"body2"} color={colors.gray}>
                {toAge(review.createdAt)}
            </Typography>
        </HorizontalFlexLayout>
    )];
}

const Feeds: FC = () => {
    const navigate = useNavigate()
    const {strings} = useResources()

    if (!appSettings.allowedFeatures?.includes("notifications")) {
        navigate("/")
        return (<LoadingPage/>)
    }

    async function onFetchData(limit: number, offset: number, sortBy?: keyof ActivityFeed, sortDirection?: 'asc' | 'desc') {
        return await Api.activityFeed.getFeedsAsync({
            limit: limit, offset: offset, sortBy: sortBy, sortDirection: sortDirection
        }).then(data => {
            return data as DataPaginationOutput<ActivityFeed>
        })
    }

    return (<>
        <HtmlMetaDecorator title={strings("Notifications")}/>

        <Box p={4}>
            <VerticalFlexLayout spacing={4}>
                <Typography variant={"h5"}>
                    <strong>{strings("Notifications")}</strong>
                </Typography>

                <Paper>
                    <DynamicDataTable<ActivityFeed>
                        columns={[]}
                        sortBy={"createdAt"}
                        sortDirection={"desc"}
                        renderRow={RenderRow}
                        rowsPerPage={10}
                        fetchData={onFetchData}/>
                </Paper>
            </VerticalFlexLayout>
        </Box>
    </>)
}

export default Feeds
