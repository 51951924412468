import BaseApi from "./BaseApi";
import Branch from "models/Branch";

class BranchesApi extends BaseApi {
    async getBranchesAsync(): Promise<Branch[] | undefined> {
        return this.get(`business/branches`)
    }

    async createBranchesAsync(branches: Branch[]): Promise<Branch[] | undefined> {
        return this.post(`business/branches/import`, branches)
    }

    async createBranchAsync(branch: Branch): Promise<Branch | undefined> {
        return this.post(`business/branches`, branch)
    }

    async updateBranchAsync(branch: Branch): Promise<Branch | undefined> {
        return this.put(`business/branches/${branch.id}`, branch)
    }

    async deleteBranchAsync(id: string): Promise<void> {
        return this.delete(`business/branches/${id}`)
    }
}

export default BranchesApi
