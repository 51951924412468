import React, {FC, useState} from "react";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {useResources} from "contexts/ResourcesProvider";
import colors from "res/colors";
import {Box, Checkbox, Collapse, IconButton, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import appSettings from "appSettings";
import Offer from "models/Offer";
import CircleOfferIcon from "@unipal/icons/CircleOfferIcon";

interface Props {
    offers: Offer[]
    onSelect: (id: string, selected: boolean) => Promise<void>
    selected?: boolean
}

interface CardProps {
    offer: Offer
    onSelect: (id: string, selected: boolean) => Promise<void>
    selected?: boolean
}

const OfferCard: FC<CardProps> = (props) => {
    const {strings} = useResources()

    const offer = props.offer

    const [selected, setSelected] = useState(props.selected ?? false)
    const [collapsed, setCollapsed] = useState(true)
    const toggleCollapse = () => setCollapsed(!collapsed)

    const handleClick = async () => {
        let _selected = selected;
        setSelected(ps => {
            _selected = !ps
            return !ps
        })
        await props.onSelect!(offer.id, _selected)
    }

    function isHTML(str: string) {
        const doc = new DOMParser().parseFromString(str, "text/html")
        return Array.from(doc.body.childNodes).some(node => node.nodeType === 1)
    }

    return (
        <Box
            onClick={handleClick}
            py={2} px={1} sx={{
            border: `2px solid ${selected ? colors.primary : "#000"}`,
            borderRadius: "8px",
            backgroundColor: "white"
        }}>
            <VerticalFlexLayout spacing={2}>
                <HorizontalFlexLayout wrap={"nowrap"} spacing={1} alignItems={"center"}>
                    {props.offer.isEligible &&
                        <Checkbox checked={selected}/>}
                    <GridItem style={{display: "flex"}}>
                        <CircleOfferIcon color={colors.text}/>
                    </GridItem>
                    <GridItem style={{flexGrow: 1}}>
                        <Typography variant="body1">
                            <strong style={{color: "#000"}}>
                                {offer.discount}
                            </strong>
                        </Typography>
                        {Boolean(!offer.isEligible && offer.message) &&
                            <Typography
                                color={colors.error}
                                variant="body2">
                                {offer.message}
                            </Typography>}
                    </GridItem>
                    <GridItem style={{display: "flex"}}>
                        <IconButton
                            onClick={e => {
                                e.stopPropagation()
                                toggleCollapse()
                            }}>
                            <ExpandMore
                                sx={{
                                    transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)',
                                    marginLeft: 'auto',
                                    color: "#000",
                                    transition: "0.4s"
                                }}/>
                        </IconButton>
                    </GridItem>
                </HorizontalFlexLayout>

                {appSettings.ui.showOfferTitle &&
                    <Typography variant="body1">
                        {offer.discount}
                    </Typography>}

                <GridItem style={{
                    marginBottom: collapsed ? -16 : undefined,
                    transition: ".1s"
                }}>
                    <Collapse in={!collapsed} timeout="auto">
                        <VerticalFlexLayout spacing={1}>
                            <Typography variant="body1">
                                <strong>
                                    {strings("Conditions")}
                                </strong>
                            </Typography>
                            {isHTML(offer.terms) ?
                                <div dangerouslySetInnerHTML={{__html: offer.terms}}/>
                                :
                                <Typography
                                    variant="body1"
                                    style={{whiteSpace: "pre-wrap"}}>
                                    {offer.terms || "N/A"}
                                </Typography>
                            }
                        </VerticalFlexLayout>
                    </Collapse>
                </GridItem>
            </VerticalFlexLayout>
        </Box>
    )
}

const GtsOffersList: FC<Props> = (props) => {
    const [loading, setLoading] = useState(false)

    const onSelect = async (id: string, selected: boolean) => {
        // NOTE: This is to prevent clicking multiple items at the same time.
        if (!loading) {
            setLoading(true)
            await props.onSelect!(id, selected)
            setLoading(false)
        }
    }

    return (
        <VerticalFlexLayout spacing={2}>
            {props.offers.map(o =>
                <OfferCard
                    key={o.id}
                    offer={o}
                    selected={props.selected}
                    onSelect={onSelect}/>
            )}
        </VerticalFlexLayout>
    )
}

export default GtsOffersList
