import React, {FC, MouseEvent, useState} from "react"
import {Button as MuiButton} from "@mui/material"
import {ButtonProps} from "@mui/material/Button/Button"

interface Props extends ButtonProps {
    to?: string
}

const LoadingButton: FC<Props> = (props) => {
    const [loading, setLoading] = useState(false)

    const onClick = async (e: MouseEvent<HTMLButtonElement>) => {
        if (props.onClick) {
            setLoading(true)
            await props.onClick(e)
            setLoading(false)
        }
    }

    return (
        <MuiButton
            {...props}
            disabled={props.disabled || loading}
            onClick={onClick}>
            {props.children}
        </MuiButton>
    )
}

export default LoadingButton
