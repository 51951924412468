import React, {FC, useEffect, useMemo} from "react";
import {Controller, useForm} from "react-hook-form";
import Api from "util/api/Api";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {Body2, Heading1} from "@unipal/ui/typography/Typography";
import InputField from "@unipal/common/InputField";
import {useResources} from "contexts/ResourcesProvider";
import {Box} from "@mui/material";
import InstagramIcon from "@unipal/icons/InstagramIcon";
import WebIcon from "@unipal/icons/WebIcon";
import colors from "res/colors";

interface Props {
    onComplete: () => Promise<void>
    onLoad: (loading: boolean) => void
}

interface Fields {
    url: string
    instagram: string
}

const UpsertSocialControl: FC<Props> = (props) => {
    const {strings} = useResources()
    const form = useForm<Fields>({
        defaultValues: {instagram: '', url: ''}
    })
    // NOTE: Must call the isDirty before rendering in order to enable the state update.
    const isDirty = useMemo(() => form.formState.isDirty, [form.formState.isDirty])

    useEffect(() => {
        void async function fetchData() {
            const shop = await Api.shops.getShopAsync()
            form.reset({
                url: shop?.url,
                instagram: shop?.instagram
            })
        }()
    }, [form])

    const onSubmit = form.handleSubmit(async fields => {
        if (isDirty) {
            props.onLoad(true)
            form.clearErrors()
            await Api.shops
                .updateSocialAsync(fields.url?.trim(), fields.instagram?.trim())
        }

        await props.onComplete()
    })

    return (
        <form
            id="SetupShopSocial"
            onSubmit={onSubmit}>
            <VerticalFlexLayout spacing={4}>
                <HorizontalFlexLayout
                    spacing={2}
                    wrap={"nowrap"}
                    alignItems={"center"}>
                    <img src={`${process.env.PUBLIC_URL}/assets/social-media-icon.png`}
                         style={{width: 56}}
                         alt={"social media icon"}/>
                    <Heading1>
                        {strings("UpdateShopSocialPageTitle")}
                    </Heading1>
                </HorizontalFlexLayout>
                <Body2
                    whiteSpace={"pre-wrap"}
                    color={colors.copy}>
                    {strings("UpdateShopSocialPageMessage")}
                </Body2>
                <HorizontalFlexLayout
                    spacing={2}
                    wrap={"nowrap"}
                    alignItems={"flex-end"}>
                    <Box
                        height={"56px"} width={"56px"}
                        border={"1px solid #0000003b"}
                        boxSizing={"border-box"}
                        borderRadius={"4px"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}>
                        <WebIcon
                            size={32}
                            color={"#A5A5A5"}/>
                    </Box>
                    <GridItem sx={{flexGrow: 1}}>
                        <Controller
                            name="url"
                            control={form.control}
                            render={({field, fieldState: {error}}) => (
                                <InputField
                                    fullWidth
                                    className={"force-ltr"}
                                    label={strings("WebsiteFieldLabel")}
                                    placeholder={"www.your-website.com"}
                                    fieldError={error}
                                    {...field} />
                            )}/>
                    </GridItem>
                </HorizontalFlexLayout>
                <HorizontalFlexLayout
                    spacing={2}
                    wrap={"nowrap"}
                    alignItems={"flex-end"}>
                    <Box
                        height={"56px"} width={"56px"}
                        border={"1px solid #0000003b"}
                        boxSizing={"border-box"}
                        borderRadius={"4px"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}>
                        <InstagramIcon
                            size={32}
                            color={"#A5A5A5"}/>
                    </Box>
                    <GridItem sx={{flexGrow: 1}}>
                        <Controller
                            name="instagram"
                            control={form.control}
                            render={({field, fieldState: {error}}) => (
                                <InputField
                                    className={"force-ltr"}
                                    fullWidth
                                    label={strings("InstagramFieldLabel")}
                                    placeholder={"https://instagram.com/your_username"}
                                    fieldError={error}
                                    {...field} />
                            )}/>
                    </GridItem>
                </HorizontalFlexLayout>
            </VerticalFlexLayout>
        </form>
    )
}

export default UpsertSocialControl
