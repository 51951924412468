import React, {FC, useState} from "react";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {Body1, Body2} from "@unipal/ui/typography/Typography";
import colors from "res/colors";
import Button from "@unipal/ui/button/Button";
import {Box, Dialog, DialogContent, DialogTitle} from "@mui/material";
import DragDropControl from "@unipal/common/DragDropControl";
import {Controller, FormProvider, useFieldArray, useForm} from "react-hook-form";
import InputField from "@unipal/common/InputField";
import IconButton from "@unipal/ui/iconButton/IconButton";
import TrashIcon from "@unipal/icons/TrashIcon";
import Branch from "models/Branch";
import {kml} from "@tmcw/togeojson";
import JSZip from "jszip";
import images from "@unipal/res/images";
import CloseIcon from "@unipal/icons/CloseIcon";
import {getUuid} from "util/Utilities";
import {MapOutlined} from "@mui/icons-material";
import Api from "util/api/Api";
import {useResources} from "contexts/ResourcesProvider";
import {enqueueSnackbar} from "util/SnackbarManager";

interface KML {
    features: {
        type: "Feature"
        geometry: {
            type: "Point"
            coordinates: number[]
        }
        properties: {
            name: string
        }
    }[]
}

const convertToLocations = (file: File): Promise<KML> => {
    const getDom = (value: string) =>
        (new DOMParser()).parseFromString(value, "text/xml")

    return new Promise<KML>(async (resolve, reject) => {
        if (file.name.toLowerCase().endsWith(".kml")) {
            const reader = new FileReader()
            reader.onload = () => {
                if (reader.result) {
                    resolve(kml(getDom(reader.result as string)))
                } else {
                    reject("Invalid file!")
                }
            }
            reader.readAsText(file)
        } else if (file.name.toLowerCase().endsWith(".kmz")) {
            new JSZip()
                .loadAsync(file)
                .then(zip => {
                    let kmlDom: any
                    zip.forEach((path, file) => {
                        if (path.split(".").pop() === "kml" && !kmlDom) {
                            kmlDom = file.async("string").then(getDom)
                        }
                    })
                    return kmlDom || reject("No kml file found")
                })
                .then(kmlDom => {
                    resolve(kml(kmlDom))
                })
        } else {
            reject("Invalid file!")
        }
    })
}

interface BranchVM extends Branch {
    key: string
}

interface Fields {
    branches: BranchVM[]
}

interface Props {
    onComplete: (branches: Branch[]) => void
}

const ImportGoogleMapsLocationsControl: FC<Props> = (props) => {
    const {strings} = useResources()
    const form = useForm<Fields>({defaultValues: {branches: []}})
    const branches = useFieldArray({control: form.control, name: "branches"})

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const onSubmit = form.handleSubmit(async fields => {
        setLoading(true)

        await Api.branches
            .createBranchesAsync(fields.branches)
            .then(b => {
                if (b) {
                    props.onComplete(b)
                    onClose()
                }
            })
            .catch(() => {
            })

        setLoading(false)
    })

    const onClose = () => {
        setOpen(false)
        branches.remove()
    }

    const onFileChange = async (files: FileList) => {
        if (files && files.length > 0) {
            const file = files[0]
            const data = await convertToLocations(file)

            if (!data.features.length) {
                enqueueSnackbar({
                    message: strings("ImportGoogleMapsLocationsInvalidFileError"),
                    type: "error"
                })
                return
            }

            data.features.forEach(f => {
                const lat = Number(f.geometry?.coordinates[1].toFixed(7)) || undefined
                const long = Number(f.geometry?.coordinates[0].toFixed(7)) || undefined

                if (!branches.fields.find(f => f.latitude === lat && f.longitude === long)) {
                    branches.append({
                        id: "",
                        key: getUuid(),
                        name: f.properties?.name,
                        altName: "",
                        latitude: lat,
                        longitude: long
                    })
                }
            })
        }
    }

    return (<>
        <HorizontalFlexLayout
            alignItems={"center"}
            spacing={2}
            wrap={"nowrap"}>
            <img width={40} height={40}
                 alt={"Google Maps"}
                 src={images.googleMapsPinIcon}/>
            <GridItem sx={{flexGrow: 1}}>
                <Body1>
                    <strong>{strings("ImportGoogleMapsLocationsTitle")}</strong>
                </Body1>
                <Body2 color={colors.copy}>
                    {strings("ImportGoogleMapsLocationsMessage")}
                </Body2>
            </GridItem>
            <Button
                variant={"contained"}
                onClick={() => setOpen(true)}>
                {strings("Import")}
            </Button>
        </HorizontalFlexLayout>

        <Dialog
            open={open}
            fullWidth
            maxWidth={"md"}>
            <DialogTitle>
                <HorizontalFlexLayout
                    spacing={2}
                    wrap={"nowrap"}
                    alignItems={"center"}>
                    <img width={24} height={24}
                         alt={"Google Maps"}
                         src={images.googleMapsPinIcon}/>
                    <GridItem sx={{flexGrow: 1}}>
                        <Body1>
                            <strong>{strings("ImportGoogleMapsLocationsTitle")}</strong>
                        </Body1>
                    </GridItem>
                    <IconButton
                        size={"small"}
                        onClick={onClose}>
                        <CloseIcon color={"black"}/>
                    </IconButton>
                </HorizontalFlexLayout>
            </DialogTitle>
            <DialogContent dividers>
                {!branches.fields.length ?
                    <VerticalFlexLayout spacing={4}>
                        <Body1 dangerouslySetInnerHTML={{
                            __html: strings("ImportGoogleMapsLocationsSteps")
                        }}/>
                        <DragDropControl
                            action={strings("UploadFile")}
                            accept=".kml,.kmz"
                            message={strings("DragDropFileTip")}
                            onChange={onFileChange}/>
                    </VerticalFlexLayout>
                    :
                    <FormProvider {...form}>
                        <form onSubmit={onSubmit}>
                            <VerticalFlexLayout
                                spacing={2}
                                sx={{minWidth: 700}}>
                                <GridItem/>
                                {branches.fields.map((b, i) => (
                                    <GridItem key={b.key}>
                                        <HorizontalFlexLayout
                                            alignItems={"center"}
                                            wrap={"nowrap"}
                                            spacing={2}>
                                            <GridItem sx={{flexGrow: 1}}>
                                                <Controller
                                                    name={`branches.${i}.name`}
                                                    control={form.control}
                                                    render={({field}) => (
                                                        <InputField
                                                            required
                                                            placeholder={"Branch Name"}
                                                            className={"force-ltr"}
                                                            size={"small"}
                                                            {...field} />
                                                    )}/>
                                            </GridItem>
                                            <GridItem sx={{flexGrow: 1}}>
                                                <Controller
                                                    name={`branches.${i}.altName`}
                                                    control={form.control}
                                                    render={({field}) => (
                                                        <InputField
                                                            required
                                                            size={"small"}
                                                            placeholder={"اسم الفرع"}
                                                            className={"force-rtl"}
                                                            {...field} />
                                                    )}/>
                                            </GridItem>
                                            <a target="_blank"
                                               href={`https://maps.google.com/?q=${branches.fields[i].latitude},${branches.fields[i].longitude}`}
                                               rel="noreferrer">
                                                <Button
                                                    startIcon={<MapOutlined/>}
                                                    variant={"outlined"}>
                                                    {strings("ViewInMap")}
                                                </Button>
                                            </a>
                                            <IconButton
                                                onClick={() => branches.remove(i)}>
                                                <TrashIcon color={colors.error}/>
                                            </IconButton>
                                        </HorizontalFlexLayout>
                                    </GridItem>
                                ))}
                                <Box pt={2}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={loading}
                                        color="primary">
                                        {strings("Import") + ` (${branches.fields.length})`}
                                    </Button>
                                </Box>
                            </VerticalFlexLayout>
                        </form>
                    </FormProvider>
                }
            </DialogContent>
        </Dialog>
    </>)
}

export default ImportGoogleMapsLocationsControl
