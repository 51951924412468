import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector"
// import {strings_ar, strings_en} from "apps/default/strings";
import {strings_ar, strings_en, strings_cs} from "apps/gts-alive/strings";

const resources = {
    en: {translation: strings_en},
    ar: {translation: strings_ar},
    cs: {translation: strings_cs}
}

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    })

export default i18n
