import React, {FC} from "react"
import {Avatar as MuiAvatar} from "@mui/material"

interface Props {
    name: string
    image?: string
    color?: string
    size?: number
}

const Avatar: FC<Props> = (props) => {
    const size = props.size || 40
    let color = props.color

    if (!color) {
        let hash = 0
        for (let i = 0; i < props.name?.length; i++) {
            hash = props.name.charCodeAt(i) + ((hash << 5) - hash)
        }
        color = `hsl(${hash % 360}, 100%, 45%)`
    }

    return (
        <MuiAvatar
            variant={"rounded"}
            src={props.image}
            sx={{
                width: size,
                height: size,
                backgroundColor: color,
                fontSize: size / 2
            }}>
            {props.name?.slice(0, 2)}
        </MuiAvatar>
    )
}

export default Avatar
