import React, {FC} from "react";
import {useTheme} from "@unipal/contexts/ThemeProvider";
import {HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {Body2} from "@unipal/ui/typography/Typography";
import {Box} from "@mui/material";
import Avatar from "@unipal/ui/avatar/Avatar";
import IChatMessage from "@unipal/types/chat/IChatMessage";
import {toAge} from "@unipal/utils";

interface Props {
    message: IChatMessage
}

// TODO: Support other content types.
const ChatMessageItem: FC<Props> = (props) => {
    const {colors} = useTheme()

    const msg = props.message
    const sender = msg.sender
    const dir = sender.me ? "row-reverse" : "row"
    const align = sender.me ? "flex-end" : "flex-start"
    const bg = (sender.me ? "#002bff" : colors.gray) + '30'

    return (
        <HorizontalFlexLayout
            spacing={2}
            wrap={"nowrap"}
            sx={{flexDirection: dir}}>
            {!sender.me &&
                <Avatar
                    size={32}
                    name={sender.name}
                    image={sender.image}/>}
            <VerticalFlexLayout
                spacing={1}
                sx={{alignItems: align}}>
                <Body2
                    fontSize={12}
                    color={colors.gray}>
                    {!sender.me && <strong>{sender.name}, </strong>}
                    {toAge(msg.created)}
                </Body2>
                <Box sx={{
                    py: 1, px: 1.5,
                    borderRadius: 1,
                    maxWidth: "400px",
                    backgroundColor: bg
                }}>
                    <Body2>
                        {msg.content}
                    </Body2>
                </Box>
            </VerticalFlexLayout>
        </HorizontalFlexLayout>
    )
}

export default ChatMessageItem
