import React, {FC, useEffect, useMemo} from "react";
import {Controller, useForm} from "react-hook-form";
import Api from "util/api/Api";
import {HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {Body2, Heading1, Heading6} from "@unipal/ui/typography/Typography";
import InputField from "@unipal/common/InputField";
import {useResources} from "contexts/ResourcesProvider";
import colors from "res/colors";

interface Props {
    onComplete: () => Promise<void>
    onLoad: (loading: boolean) => void
}

interface Fields {
    about: string
    altAbout: string
}

const UpsertAboutControl: FC<Props> = (props) => {
    const {strings} = useResources()
    const form = useForm<Fields>({
        defaultValues: {about: '', altAbout: ''}
    })
    // NOTE: Must call the isDirty before rendering in order to enable the state update.
    const isDirty = useMemo(() => form.formState.isDirty, [form.formState.isDirty])

    useEffect(() => {
        void async function fetchData() {
            const shop = await Api.shops.getShopAsync()
            form.reset({
                about: shop?.about,
                altAbout: shop?.altAbout
            })
        }()
    }, [form])

    const onSubmit = form.handleSubmit(async fields => {
        if (isDirty) {
            props.onLoad(true)
            form.clearErrors()
            await Api.shops
                .updateAboutAsync(fields.about?.trim(), fields.altAbout?.trim())
        }

        await props.onComplete()
    })

    return (
        <form
            id="SetupAboutShop"
            onSubmit={onSubmit}>
            <VerticalFlexLayout spacing={4}>
                <HorizontalFlexLayout
                    spacing={2}
                    wrap={"nowrap"}
                    alignItems={"center"}>
                    <img src={`${process.env.PUBLIC_URL}/assets/store-icon.png`}
                         style={{width: 56}}
                         alt={"store icon"}/>
                    <Heading1>
                        {strings("UpdateAboutShopPageTitle")}
                    </Heading1>
                </HorizontalFlexLayout>
                <Body2
                    whiteSpace={"pre-wrap"}
                    color={colors.copy}>
                    {strings("UpdateAboutShopPageMessage")}
                </Body2>
                <VerticalFlexLayout
                    spacing={2}
                    className={"force-ltr"}>
                    <Heading6>
                        <strong>English</strong>
                    </Heading6>
                    <Controller
                        name="about"
                        control={form.control}
                        render={({field, fieldState: {error}}) => (
                            <InputField
                                fullWidth
                                multiline
                                required
                                label="ِAbout You"
                                fieldError={error}
                                {...field} />
                        )}/>
                </VerticalFlexLayout>
                <VerticalFlexLayout
                    spacing={2}
                    className={"force-rtl"}>
                    <Heading6>
                        <strong>العربية</strong>
                    </Heading6>
                    <Controller
                        name="altAbout"
                        control={form.control}
                        render={({field, fieldState: {error}}) => (
                            <InputField
                                fullWidth
                                multiline
                                required
                                label="عن محلك"
                                fieldError={error}
                                {...field} />
                        )}/>
                </VerticalFlexLayout>
            </VerticalFlexLayout>
        </form>
    )
}

export default UpsertAboutControl
