import React, {FC, useCallback, useRef, useState} from "react"
import Rating from "models/Rating";
import Reply from "models/Reply";
import {useResources} from "contexts/ResourcesProvider";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {Body1, Body2} from "@unipal/ui/typography/Typography";
import {toShortDate} from "util/Utilities";
import Api from "util/api/Api";
import {GetRepliesOutput} from "util/api/RatingsApi";
import colors from "res/colors";
import RoundVerifiedIcon from "@unipal/icons/RoundVerifiedIcon";
import InfoIcon from "@unipal/icons/InfoIcon";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    Paper,
    Rating as MuiRating,
    TextField
} from "@mui/material";
import ChatIcon from "@unipal/icons/ChatIcon";
import Button from "@unipal/ui/button/Button";
import ReplyItem from "@unipal/components/reply/ReplyItem";
import appSettings from "appSettings";
import IconTextButton from "@unipal/ui/button/IconTextButton";
import TrashIcon from "@unipal/icons/TrashIcon";
import EditIcon from "@unipal/icons/EditIcon";
import Avatar from "@unipal/ui/avatar/Avatar";
import RatingRepliesControl from "pages/ratings/RatingRepliesControl";
import IconButton from "@unipal/ui/iconButton/IconButton";
import CloseIcon from "@unipal/icons/CloseIcon";

interface Props {
    rating: Rating
    isAdmin?: boolean
}

// TODO: Show loading for all the API requests.
const RatingRow: FC<Props> = (props) => {
    const rating = props.rating
    const isAdmin = props.isAdmin
    const isVerified = rating.type === "PURCHASE"
    const maxMessageLength = appSettings.maxReplyMessageLength

    const {strings} = useResources()
    const [hasPublicReply, setHasPublicReply] = useState(Boolean(rating.publicMessages))
    const [showReplies, setShowReplies] = useState(false)
    const [showReply, setShowReply] = useState(false)
    const [message, setMessage] = useState<string>()
    const [open, setOpen] = useState(false)
    const [confirmation, setConfirmation] = useState(false)
    const [replies, setReplies] = useState<Reply[]>([])

    // TODO: Move this to the DDT.
    const token = useRef<string>()

    const load = useCallback(async () => {
        await Api.ratings
            .getRatingReplies(
                rating.id,
                rating.shopId,
                rating.studentId,
                rating.type,
                false,
                token.current
            ).then(data => {
                if (data) {
                    const d = data as GetRepliesOutput
                    token.current = d.pagingToken

                    setReplies(ps => [
                        ...(d.items?.reverse() ?? []),
                        ...(ps ?? [])
                    ])
                }
            })
    }, [rating])

    const submitReply = async () => {
        await Api.ratings
            .createReply(
                true,
                message?.trim() || '',
                rating.id,
                rating.shopId,
                rating.studentId,
                rating.type
            ).then(data => {
                if (data) {
                    const d = data as Reply
                    setReplies(ps => [...(ps ?? []), d])
                    setShowReply(false)
                    setShowReplies(true)
                    setHasPublicReply(true)
                }
            }).catch(() => {
                // TODO: Handle errors.
            })
    }

    const onSubmit = async () => {
        if (!isVerified || !message?.trim() || message?.length > maxMessageLength) {
            return
        }

        if (!isAdmin) {
            setConfirmation(true)
        } else {
            await submitReply()
        }
    }

    const renderStatus = () => {
        let text, color, icon
        if (isVerified) {
            text = strings("VerifiedPurchase")
            color = colors.success
            icon = <RoundVerifiedIcon size={16} color={color}/>
        } else {
            text = strings("UnverifiedPurchase")
            color = colors.primary
            icon = <InfoIcon size={16} color={color}/>
        }

        return (
            <HorizontalFlexLayout
                spacing={1}
                wrap={"nowrap"}
                alignItems={"center"}>
                {icon}
                <Body2 color={color}>
                    {text}
                </Body2>
            </HorizontalFlexLayout>
        )
    }

    const submitReplyButton = (
        <Button
            variant={"outlined"}
            size={"small"}
            onClick={onSubmit}
            sx={{fontSize: 14}}
            disabled={!Boolean(message?.trim())}>
            {strings("Reply")}
        </Button>
    )

    return (<>
        <Paper>
            <Box p={3}>
                <HorizontalFlexLayout
                    spacing={4}>
                    <GridItem xs={12} md={4} xl={3}>
                        <VerticalFlexLayout
                            spacing={2}>
                            <HorizontalFlexLayout
                                spacing={2}
                                wrap={"nowrap"}>
                                <Avatar
                                    name={rating.studentName}/>
                                <GridItem>
                                    <Body1>
                                        <strong>{rating.studentName}</strong>
                                    </Body1>
                                    <Body2 color={colors.copy}>
                                        {rating.instituteName}
                                    </Body2>
                                </GridItem>
                            </HorizontalFlexLayout>
                            <HorizontalFlexLayout
                                spacing={2}>
                                <IconTextButton
                                    icon={ChatIcon}
                                    color={colors.primary}
                                    onClick={() => setOpen(true)}>
                                    {strings("Chat")}
                                </IconTextButton>
                                {isAdmin &&
                                    <IconTextButton
                                        icon={EditIcon}
                                        color={colors.copy}
                                        onClick={() => alert("Coming soon!")}>
                                        Edit
                                    </IconTextButton>}
                                {isAdmin &&
                                    <IconTextButton
                                        icon={TrashIcon}
                                        color={colors.error}
                                        onClick={() => alert("Coming soon!")}>
                                        Delete
                                    </IconTextButton>}
                            </HorizontalFlexLayout>
                        </VerticalFlexLayout>
                    </GridItem>
                    <GridItem xs={12} md={8} xl={9}>
                        <VerticalFlexLayout
                            spacing={2}>
                            {renderStatus()}
                            <HorizontalFlexLayout
                                spacing={2}
                                alignItems={"center"}>
                                <MuiRating
                                    readOnly
                                    value={rating.score}/>
                                <Body2 color={colors.gray}>
                                    {toShortDate(rating.createdAt)}
                                </Body2>
                            </HorizontalFlexLayout>

                            {rating.comment &&
                                <Body1
                                    color={colors.copy}
                                    whiteSpace={"pre-wrap"}>
                                    {rating.comment}
                                </Body1>}

                            {hasPublicReply && !showReplies &&
                                <Button
                                    size={"small"}
                                    onClick={async () => {
                                        await load()
                                        setShowReplies(true)
                                    }}>
                                    {strings("ViewReplies_Count", rating.publicMessages)}
                                </Button>}

                            {isVerified && !showReply && (isAdmin || !hasPublicReply) &&
                                <Button
                                    variant={"outlined"}
                                    size={"small"}
                                    onClick={() => setShowReply(true)}
                                    sx={{fontSize: 14}}>
                                    {strings("ReplyPublicly")}
                                </Button>}

                            {showReplies &&
                                <Box px={3} py={2}>
                                    <VerticalFlexLayout
                                        spacing={4}>
                                        {replies.map(r =>
                                            <ReplyItem
                                                rating={rating}
                                                reply={r}/>
                                        )}
                                    </VerticalFlexLayout>
                                </Box>}

                            {hasPublicReply && showReplies &&
                                <Button
                                    size={"small"}
                                    onClick={() => {
                                        setReplies([])
                                        setShowReplies(false)
                                        token.current = undefined
                                    }}>
                                    {strings("HideReplies")}
                                </Button>}

                            {(isAdmin || !hasPublicReply) && showReply &&
                                <TextField
                                    fullWidth
                                    autoFocus
                                    value={message}
                                    variant="outlined"
                                    placeholder={strings("ReplyMessagePlaceholder")}
                                    onChange={e => setMessage(e.target.value)}
                                    InputProps={{endAdornment: submitReplyButton}}
                                    inputProps={{maxLength: maxMessageLength}}/>}
                        </VerticalFlexLayout>
                    </GridItem>
                </HorizontalFlexLayout>
            </Box>
        </Paper>

        <Dialog
            open={open}
            fullWidth
            scroll={"paper"}
            PaperProps={{sx: {height: "80%"}}}
            maxWidth={"sm"}>
            <IconButton
                onClick={() => setOpen(false)}
                sx={{
                    position: "absolute",
                    right: "12px",
                    top: "18px"
                }}>
                <CloseIcon color={"black"}/>
            </IconButton>
            <RatingRepliesControl {...rating}/>
        </Dialog>

        <Dialog
            maxWidth="xs"
            open={confirmation}>
            <DialogContent>
                <HorizontalFlexLayout
                    spacing={1}
                    wrap={"nowrap"}>
                    <InfoIcon size={24}/>
                    <Body2 fontWeight={"bold"}>
                        {strings("SubmitReplyConfirmationTitle")}
                    </Body2>
                </HorizontalFlexLayout>
                <Body2
                    paddingTop={3}
                    color={colors.copy}
                    whiteSpace={"pre-wrap"}
                    dangerouslySetInnerHTML={{
                        __html: strings("SubmitReplyConfirmationMessage")
                    }}/>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    onClick={() => setConfirmation(false)}>
                    {strings("Cancel")}
                </Button>
                <Button
                    variant={"contained"}
                    onClick={async () => {
                        setConfirmation(false)
                        await submitReply()
                    }}>
                    {strings("ReplyPublicly")}
                </Button>
            </DialogActions>
        </Dialog>
    </>)
}

export default RatingRow
