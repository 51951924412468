import {FC} from "react";
import IconProps from "@unipal/icons/IconProps";
import {useTheme} from "@unipal/contexts/ThemeProvider";

const InfoIcon: FC<IconProps> = (props) => {
    const {colors} = useTheme()

    return (
        <svg width={props.size ?? "24"} height={props.size ?? "24"} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2Z"
                  stroke={props.color ?? colors.primary} strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M12 16L12 11" stroke={props.color ?? colors.primary} strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M12.0054 8H11.9964" stroke={props.color ?? colors.primary} strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}

export default InfoIcon
