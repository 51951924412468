import React, {FC, useEffect, useMemo} from "react";
import {Controller, useForm} from "react-hook-form";
import Api from "util/api/Api";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {Body1, Body2, Heading1, Heading6} from "@unipal/ui/typography/Typography";
import InputField from "@unipal/common/InputField";
import {useResources} from "contexts/ResourcesProvider";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {useMerchantAppData} from "contexts/MerchantAppDataProvider";
import colors from "res/colors";

interface Props {
    onComplete: () => Promise<void>
    onLoad: (loading: boolean) => void
}

interface Fields {
    type: number
    value: string
    name: string
    terms: string
    altName: string
    altTerms: string
}

const UpsertOfferControl: FC<Props> = (props) => {
    const {offers} = useMerchantAppData()
    const {strings} = useResources()
    const form = useForm<Fields>({
        defaultValues: {
            type: 1,
            value: '',
            name: '',
            terms: '',
            altName: '',
            altTerms: ''
        }
    })
    // NOTE: Must call the isDirty before rendering in order to enable the state update.
    const isDirty = useMemo(() => form.formState.isDirty, [form.formState.isDirty])

    useEffect(() => {
        void async function fetchData() {
            const offer = await Api.offers
                .getOfferAsync(offers[0].id)

            form.reset({
                type: offer?.type || 1,
                value: offer?.value,
                name: offer?.name,
                terms: offer?.terms,
                altName: offer?.altName,
                altTerms: offer?.altTerms
            })
        }()
    }, [form, offers])

    const onSubmit = form.handleSubmit(async fields => {
        if (isDirty) {
            props.onLoad(true)
            form.clearErrors()
            await Api.offers
                .updateOfferAsync(
                    offers[0].id,
                    fields.type,
                    fields.value,
                    fields.name,
                    fields.terms,
                    fields.altName,
                    fields.altTerms
                )
        }

        await props.onComplete()
    })

    return (
        <form
            id="SetupOfferDetails"
            onSubmit={onSubmit}>
            <VerticalFlexLayout spacing={4}>
                <HorizontalFlexLayout
                    spacing={2}
                    wrap={"nowrap"}
                    alignItems={"center"}>
                    <img src={`${process.env.PUBLIC_URL}/assets/coupon-icon.png`}
                         style={{width: 56}}
                         alt={"store icon"}/>
                    <Heading1>
                        {strings("UpdateOfferDetailsPageTitle")}
                    </Heading1>
                </HorizontalFlexLayout>
                <Body2
                    whiteSpace={"pre-wrap"}
                    color={colors.copy}>
                    {strings("UpdateOfferDetailsPageMessage")}
                </Body2>
                <GridItem>
                    <Body2 marginBottom={1}>
                        <strong>{strings("OfferTypeFieldLabel")}</strong>
                    </Body2>
                    <Controller
                        name="type"
                        control={form.control}
                        render={({field, fieldState: {error}}) => (
                            <ToggleButtonGroup
                                color="primary"
                                exclusive
                                size={"large"}
                                sx={{backgroundColor: "white"}}
                                {...field}
                                onChange={(e, v) => form.setValue(field.name, Number(v))}
                                value={field.value.toString()}>
                                <ToggleButton value="1">
                                    <Body1>
                                        <strong>{strings("OfferTypePercentage")}</strong>
                                    </Body1>
                                </ToggleButton>
                                <ToggleButton value="2">
                                    <Body1>
                                        <strong>{strings("OfferTypeFixed")}</strong>
                                    </Body1>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        )}/>
                </GridItem>
                <Controller
                    name="value"
                    control={form.control}
                    render={({field, fieldState: {error}}) => (
                        <InputField
                            fullWidth
                            required
                            label={strings("OfferValueFieldLabel")}
                            type={"number"}
                            placeholder={"25.0"}
                            fieldError={error}
                            {...field} />
                    )}/>
                <VerticalFlexLayout
                    spacing={2}
                    className={"force-ltr"}>
                    <Heading6>
                        <strong>English</strong>
                    </Heading6>
                    <Controller
                        name="name"
                        control={form.control}
                        render={({field, fieldState: {error}}) => (
                            <InputField
                                fullWidth
                                required
                                label="Offer Title"
                                placeholder={"25% Off!"}
                                fieldError={error}
                                {...field} />
                        )}/>
                    <Controller
                        name="terms"
                        control={form.control}
                        render={({field, fieldState: {error}}) => (
                            <InputField
                                fullWidth
                                multiline
                                required
                                label="Terms & Conditions"
                                placeholder={"Offer cannot be availed with any other discounts."}
                                fieldError={error}
                                {...field} />
                        )}/>
                </VerticalFlexLayout>
                <VerticalFlexLayout
                    spacing={2}
                    className={"force-rtl"}>
                    <Heading6>
                        <strong>العربية</strong>
                    </Heading6>
                    <Controller
                        name="altName"
                        control={form.control}
                        render={({field, fieldState: {error}}) => (
                            <InputField
                                fullWidth
                                required
                                label="العرض"
                                placeholder={"خصم ٢٥٪"}
                                fieldError={error}
                                {...field} />
                        )}/>
                    <Controller
                        name="altTerms"
                        control={form.control}
                        render={({field, fieldState: {error}}) => (
                            <InputField
                                fullWidth
                                multiline
                                required
                                label="الشروط والأحكام"
                                placeholder={"لا يمكن الاستفادة من العرض مع أي خصومات أخرى."}
                                fieldError={error}
                                {...field} />
                        )}/>
                </VerticalFlexLayout>
            </VerticalFlexLayout>
        </form>
    )
}

export default UpsertOfferControl
