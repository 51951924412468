import React, {FC} from "react"
import {IconButton as MuiIconButton, IconButtonProps} from "@mui/material"
import {Link} from "react-router-dom";

interface Props extends IconButtonProps {
    to?: string
}

const IconButton: FC<Props> = (props) => {
    if (props.to) {
        return (
            // @ts-ignore
            <MuiIconButton
                {...props}
                component={Link}
                to={props.to}>
                {props.children}
            </MuiIconButton>
        )
    } else {
        return (
            <MuiIconButton {...props}>
                {props.children}
            </MuiIconButton>
        )
    }
}

export default IconButton
