import React, {FC, useCallback, useEffect, useRef, useState} from "react";
import appSettings from "appSettings";
import IChatConversation from "@unipal/types/chat/IChatConversation";
import {useResources} from "contexts/ResourcesProvider";
import Reply from "models/Reply";
import Api from "util/api/Api";
import {toChatMessage} from "@unipal/utils/mapping";
import ChatConversationView from "@unipal/components/chat/ChatConversationView";

// TODO: Too many props!
interface Props {
    id: string
    type: string
    shopId: string
    studentId: string
    studentName: string
    instituteName: string
    shopName: string
    shopLogo: string
}

const RatingRepliesControl: FC<Props> = (props) => {
    const maxMessageLength = appSettings.maxReplyMessageLength
    const conv: IChatConversation = {
        id: props.id,
        type: "USER",
        name: props.studentName,
        description: props.instituteName
    }

    const {strings} = useResources()
    const [replies, setReplies] = useState<Reply[]>([])
    const token = useRef<string>()

    const load = useCallback(async () => {
        await Api.ratings
            .getRatingReplies(
                props.id,
                props.shopId,
                props.studentId,
                props.type,
                true,
                token.current
            ).then(data => {
                token.current = data?.pagingToken
                setReplies(ps => [
                    ...(data?.items?.reverse() ?? []),
                    ...(ps ?? [])
                ])
            }).catch(() => {
            })
    }, [
        props.id,
        props.shopId,
        props.studentId,
        props.type
    ])

    useEffect(() => {
        setReplies([])
        token.current = undefined

        void async function fetchData() {
            await load()
        }()
    }, [load])

    // TODO: Update the last message in the ChatNav.
    const send = async (msg: string) => {
        if (!msg) {
            return
        }

        await Api.ratings
            .createReply(
                false,
                msg.trim(),
                props.id,
                props.shopId,
                props.studentId,
                props.type
            ).then(data => {
                if (data) {
                    const d = data as Reply
                    setReplies(ps => [...(ps ?? []), d])
                }
            }).catch(() => {
            })
    }

    const messages = replies
        .map(r => toChatMessage(
            r, props.studentName, props.shopName,
            props.shopLogo, "SHOP", strings
        ))

    return (
        <ChatConversationView
            conversation={conv}
            messages={messages}
            load={load}
            send={send}
            hasMore={Boolean(token.current)}
            maxMessageLength={maxMessageLength}/>
    )
}

export default RatingRepliesControl
