import {FC} from "react";
import IconProps from "@unipal/icons/IconProps";
import {useTheme} from "@unipal/contexts/ThemeProvider";

interface Props extends IconProps {
    fill?: boolean
}

const StarIcon: FC<Props> = (props) => {
    const {colors} = useTheme()

    return (
        <svg width={props.size ?? "24"} height={props.size ?? "24"} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 1.99951L15.09 8.25951L22 9.26951L17 14.1395L18.18 21.0195L12 17.7695L5.82 21.0195L7 14.1395L2 9.26951L8.91 8.25951L12 1.99951Z"
                stroke={props.color ?? colors.primary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                  fill={props.fill ? props.color ?? colors.primary : "transparent"}
                  stroke={props.color ?? colors.primary} strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}

export default StarIcon
