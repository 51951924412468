import {FC} from "react";

interface Props {
    color?: string;
}

const VoucherIcon: FC<Props> = (props) => {
    const color = props.color || "#001A9C";

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill={color}/>
            <path
                d="M7.33329 7.33301C7.02387 7.33301 6.72713 7.45592 6.50833 7.67472C6.28954 7.89351 6.16663 8.19026 6.16663 8.49967V10.833C6.81413 10.833 7.33329 11.358 7.33329 11.9997C7.33329 12.3091 7.21038 12.6058 6.99158 12.8246C6.77279 13.0434 6.47605 13.1663 6.16663 13.1663V15.4997C6.16663 15.8091 6.28954 16.1058 6.50833 16.3246C6.72713 16.5434 7.02387 16.6663 7.33329 16.6663H16.6666C16.976 16.6663 17.2728 16.5434 17.4916 16.3246C17.7104 16.1058 17.8333 15.8091 17.8333 15.4997V13.1663C17.5239 13.1663 17.2271 13.0434 17.0083 12.8246C16.7895 12.6058 16.6666 12.3091 16.6666 11.9997C16.6666 11.3522 17.1916 10.833 17.8333 10.833V8.49967C17.8333 7.85217 17.3083 7.33301 16.6666 7.33301H7.33329ZM14.0416 9.08301L14.9166 9.95801L9.95829 14.9163L9.08329 14.0413L14.0416 9.08301ZM10.1391 9.10634C10.7108 9.10634 11.1716 9.56717 11.1716 10.1388C11.1716 10.4127 11.0628 10.6753 10.8692 10.8689C10.6756 11.0626 10.413 11.1713 10.1391 11.1713C9.56746 11.1713 9.10663 10.7105 9.10663 10.1388C9.10663 9.86501 9.21541 9.60238 9.40904 9.40875C9.60267 9.21512 9.86529 9.10634 10.1391 9.10634ZM13.8608 12.828C14.4325 12.828 14.8933 13.2888 14.8933 13.8605C14.8933 14.1343 14.7845 14.397 14.5909 14.5906C14.3973 14.7842 14.1346 14.893 13.8608 14.893C13.2891 14.893 12.8283 14.4322 12.8283 13.8605C12.8283 13.5867 12.9371 13.3241 13.1307 13.1304C13.3243 12.9368 13.587 12.828 13.8608 12.828Z"
                fill="white"/>
        </svg>
    );
};

export default VoucherIcon;