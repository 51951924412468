import Colors from "@unipal/types/Colors";

type AppId = "unipal" | "alive-verify"
type Feature = "ratings" | "campaigns" | "notifications" | "settings" | "setup"

interface AppSettings {
    id: AppId
    version: string
    allowedFeatures?: Feature[]
    googleAnalyticsCode?: string
    maxReplyMessageLength: number
    cardNumber: {
        type: "number" | "text"
        length: number
    }
    urls: {
        base: string
        verifyStudent: string
        postPurchase: string
    }
    ui: {
        showOfferTitle: boolean
        colors?: Partial<Colors>
    }
    allowedLanguages: string[]
}

export const version = "1.5.0"
export default AppSettings
