import React, {FC, useState} from "react";
import CollapsibleOfferCard from "components/offer/CollapsibleOfferCard";
import {HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import Offer from "models/Offer";
import {Box} from "@mui/material";
import {Body2} from "@unipal/ui/typography/Typography";
import CircleOfferIcon from "@unipal/icons/CircleOfferIcon";
import colors from "res/colors";
import {useResources} from "contexts/ResourcesProvider";

interface Props {
    offers: Offer[]
    onSelect?: (id: string) => Promise<void>
}

const OffersList: FC<Props> = (props) => {
    const {strings} = useResources()
    const [loading, setLoading] = useState(false)

    const onSelect = async (id: string) => {
        // NOTE: This is to prevent clicking multiple items at the same time.
        if (!loading) {
            setLoading(true)
            await props.onSelect!(id)
            setLoading(false)
        }
    }

    const offers = props.offers
        .sort((a, b) => Number(b.isPremium) - Number(a.isPremium))

    return (
        <VerticalFlexLayout spacing={2}>
            {offers.length ?
                offers.map(o =>
                    <CollapsibleOfferCard
                        key={o.id} offer={o}
                        onSelect={props.onSelect && onSelect}/>)
                :
                <Box sx={{
                    p: 2,
                    border: `2px solid ${colors.gray}`,
                    borderRadius: "8px",
                    backgroundColor: colors.disabledFill
                }}>
                    <HorizontalFlexLayout
                        wrap={"nowrap"}
                        spacing={1}
                        alignItems={"center"}>
                        <CircleOfferIcon
                            color={colors.gray}/>
                        <Body2 color={colors.gray}>
                            {strings("NoActiveOffersMessage")}
                        </Body2>
                    </HorizontalFlexLayout>
                </Box>}
        </VerticalFlexLayout>
    )
}

export default OffersList
