import React, {FC, PropsWithChildren, useState} from "react";
import {Link, useNavigate} from "react-router-dom"
import {
    Button, Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import {
    Comment,
    Dashboard,
    ExitToApp,
    Home,
    Notifications,
    Campaign,
    Paid,
    Mail,
    Translate,
    Settings
} from "@mui/icons-material";
import AppBar from "components/common/AppBar";
import {GridItem, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {useAuth} from "contexts/AuthProvider";
import {useResources} from "contexts/ResourcesProvider";
import appSettings from "appSettings";
import ChangeLanguageDialog from "components/common/ChangeLanguageDialog";
import {useMerchantAppData} from "contexts/MerchantAppDataProvider";
import ChatIcon from "@unipal/icons/ChatIcon";
import colors from "res/colors";

const AdminAppBar: FC<PropsWithChildren> = (props) => {
    const {clearAuth} = useAuth()
    const {strings} = useResources()
    const navigate = useNavigate()
    const {reload} = useMerchantAppData()

    const [open, setOpen] = useState(false)
    const [showLogout, setShowLogout] = React.useState(false);

    const toggleLogout = () => {
        setShowLogout(ps => !ps)
    }

    const logout = async () => {
        setShowLogout(ps => !ps)
        clearAuth()
        await navigate("/account/login")
    }

    const list = [
        [strings("Dashboard"), <Dashboard/>, 'dashboard'],
        [strings("TransactionsHistory"), <Paid/>, 'purchases'],
    ]

    if (appSettings.allowedFeatures?.includes("ratings")) {
        list.push([strings("StudentReviews"), <Comment/>, 'ratings'])
        list.push([strings("ChatPageTitle"), <ChatIcon color={colors.gray}/>, 'chat'])
    }

    if (appSettings.allowedFeatures?.includes("notifications")) {
        list.push([strings("Notifications"), <Notifications/>, 'feeds'])
    }

    if (appSettings.allowedFeatures?.includes("campaigns")) {
        list.push([strings("Campaigns"), <Campaign/>, 'campaigns'])
    }

    list.push([strings("ContactUs"), <Mail/>, 'contact-us'])

    const drawerList = (<>
        <VerticalFlexLayout>
            <GridItem style={{flexGrow: 1}}>
                <List>
                    {list.map((t, i) => (
                        <ListItemButton key={i} component={Link} to={'/' + (t[2] as string).toLowerCase()}>
                            <ListItemIcon>{t[1]}</ListItemIcon>
                            <ListItemText primary={t[0]}/>
                        </ListItemButton>
                    ))}
                </List>
            </GridItem>
            <Divider/>
            <List>
                <ListItemButton component={Link} to={'/'}>
                    <ListItemIcon><Home/></ListItemIcon>
                    <ListItemText primary={strings("Home")}/>
                </ListItemButton>
                <ListItemButton onClick={() => setOpen(true)}>
                    <ListItemIcon><Translate/></ListItemIcon>
                    <ListItemText primary={strings("Language")}/>
                </ListItemButton>
                {appSettings.allowedFeatures?.includes("settings") &&
                    <ListItemButton component={Link} to={'/settings/integrations'}>
                        <ListItemIcon><Settings/></ListItemIcon>
                        <ListItemText primary={strings("Settings")}/>
                    </ListItemButton>}
                <ListItemButton onClick={toggleLogout}>
                    <ListItemIcon><ExitToApp/></ListItemIcon>
                    <ListItemText primary={strings("Logout")}/>
                </ListItemButton>
            </List>
        </VerticalFlexLayout>

        <Dialog open={showLogout}>
            <DialogTitle>{strings("LogoutConfirmationMessage")}</DialogTitle>
            <DialogActions>
                <Button onClick={toggleLogout} color="primary">{strings("Cancel")}</Button>
                <Button onClick={logout} color="secondary">{strings("Logout")}</Button>
            </DialogActions>
        </Dialog>

        <ChangeLanguageDialog
            open={open}
            onChange={async () => {
                setOpen(false)
                await reload()
            }}/>
    </>)

    return (
        <AppBar drawerList={drawerList}>
            {props.children}
        </AppBar>
    )
}

export default AdminAppBar
