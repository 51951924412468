import React, {CSSProperties, PropsWithChildren, ReactElement} from "react";
import {Grid, GridDirection, GridSize, GridSpacing, GridWrap} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

interface Props {
    alignContent?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
    alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
    alignSelf?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
    justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
    direction?: GridDirection;
    spacing?: GridSpacing;
    wrap?: GridWrap;

    container?: boolean;
    item?: boolean;
    zeroMinWidth?: boolean;

    className?: string;
    style?: CSSProperties;
    dir?: "rtl" | "ltr" | undefined;

    lg?: boolean | GridSize;
    md?: boolean | GridSize;
    sm?: boolean | GridSize;
    xl?: boolean | GridSize;
    xs?: boolean | GridSize;

    sx?: SxProps<Theme>;
}

const FlexLayout: React.FC<PropsWithChildren<Props>> = (props) => (
    <Grid
        alignSelf={"center"}
        alignItems={"flex-end"}
        alignContent={"flex-end"}
        justifyContent={"stretch"}
        {...props}>
        {renderChildren(props.children as ReactElement)}
    </Grid>
)

const VerticalFlexLayout: React.FC<PropsWithChildren<Props>> = (props) => (
    <Grid
        {...props}
        container
        direction="column"
        justifyContent={props.justify ?? "flex-start"}
        alignItems={props.alignItems ?? "stretch"}
        spacing={props.spacing}>

        {renderChildren(props.children as ReactElement)}

    </Grid>
)

const HorizontalFlexLayout: React.FC<PropsWithChildren<Props>> = (props) => (
    <Grid
        {...props}
        container
        direction="row"
        justifyContent={props.justify ?? "flex-start"}
        alignItems={props.alignItems ?? "stretch"}
        spacing={props.spacing}>

        {renderChildren(props.children as ReactElement)}

    </Grid>
)

const GridItem: React.FC<PropsWithChildren<Props>> = (props) => (
    <Grid
        item
        {...props}>
        {props.children}
    </Grid>
)

function renderChildren(children?: ReactElement) {
    return React.Children.map(children, (child) => {
        if (!child || child.type === Grid || child.type === GridItem) {
            return (child);
        } else {
            return (
                <Grid item>
                    {child}
                </Grid>
            );
        }
    })
}

export {
    FlexLayout,
    GridItem,
    VerticalFlexLayout,
    HorizontalFlexLayout,
}