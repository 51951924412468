import React, {createContext, FC, PropsWithChildren, useContext} from "react";
import {useTranslation} from "react-i18next";
import Strings from "models/Strings";

interface ResourcesContext {
    strings: (key: keyof Strings, ...values: any[]) => string
}

export const resourcesContext = createContext<ResourcesContext>({
    strings: (key) => key
})

export const useResources = () => useContext(resourcesContext);

const ResourcesProvider: FC<PropsWithChildren> = (props) => {
    const {t} = useTranslation()

    const strings = (key: keyof Strings, ...values: any[]) => {
        let value = t(key)

        const placeholders = key.split('_')
        if (placeholders.length > 1) {
            for (let i = 0; i < placeholders.length - 1; i++) {
                if (values.length > i) {
                    value = value.replace(`{${i}}`, `${values[i]}`)
                }
            }
        }

        return value
    }
    return (
        <resourcesContext.Provider
            value={{strings}}>
            {props.children}
        </resourcesContext.Provider>
    )
}

export default ResourcesProvider
