import {FC} from "react";
import IconProps from "@unipal/icons/IconProps";
import {useTheme} from "@unipal/contexts/ThemeProvider";

const RightChevronsIcon: FC<IconProps> = (props) => {
    const {colors} = useTheme()

    return (
        <svg  width={props.size ?? "24"} height={props.size ?? "24"} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M13 17L18 12L13 7" stroke={props.color ?? colors.primary} strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M6 17L11 12L6 7" stroke={props.color ?? colors.primary} strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}

export default RightChevronsIcon
