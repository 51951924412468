import React, {ChangeEvent, FC, useLayoutEffect, useRef, useState} from "react";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {Body1, Body2} from "@unipal/ui/typography/Typography";
import {Box, Divider, InputBase} from "@mui/material";
import Avatar from "@unipal/ui/avatar/Avatar";
import IChatConversation from "@unipal/types/chat/IChatConversation";
import Button from "@unipal/ui/button/Button";
import ChatMessageItem from "@unipal/components/chat/ChatMessageItem";
import IconButton from "@unipal/ui/iconButton/IconButton";
import SendIcon from "@unipal/icons/SendIcon";
import IChatMessage from "@unipal/types/chat/IChatMessage";
import {useResources} from "contexts/ResourcesProvider";
import {useTheme} from "@unipal/contexts/ThemeProvider";

// TODO: Make it less dependent on the parent.
interface Props {
    conversation: IChatConversation
    messages: IChatMessage[]
    load: () => Promise<void>
    send: (msg: string) => Promise<void>
    hasMore: boolean
    maxMessageLength?: number
}

const ChatConversationView: FC<Props> = (props) => {
    const conv = props.conversation

    const {strings} = useResources()
    const {colors} = useTheme()
    const [message, setMessage] = useState<string>()
    const [loading, setLoading] = useState(false)
    const messagesEnd = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        messagesEnd.current
            ?.scrollIntoView({behavior: "auto", block: "end"})
    }, [props.messages])

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setMessage(e.target.value)
    }

    const onSend = async () => {
        if (message) {
            setLoading(true)
            await props.send(message)
            setMessage('')
            setLoading(false)
        }
    }

    return (
        <VerticalFlexLayout
            wrap={"nowrap"}
            sx={{height: "100%"}}>
            <Box px={3} py={2}>
                <HorizontalFlexLayout
                    spacing={2}
                    wrap={"nowrap"}>
                    <Avatar
                        name={conv.name}
                        image={conv.image}/>
                    <GridItem>
                        <Body1 fontWeight={"bold"}>
                            {conv.name}
                        </Body1>
                        <Body2 color={colors.copy}>
                            {conv.description}
                        </Body2>
                    </GridItem>
                </HorizontalFlexLayout>
            </Box>
            <Divider/>
            <GridItem
                className={"scroll-bar"}
                sx={{
                    flexGrow: 1,
                    maxHeight: "100%",
                    overflowY: "auto"
                }}>
                <Box p={3}>
                    <VerticalFlexLayout
                        spacing={4}>
                        {props.hasMore &&
                            <Button
                                fullWidth
                                size={"large"}
                                onClick={props.load}>
                                <Body1 fontWeight={"bold"}>
                                    {strings("ViewMore")}
                                </Body1>
                            </Button>}
                        {props.messages?.map(m =>
                            <ChatMessageItem message={m}/>
                        )}
                        <div ref={messagesEnd}/>
                    </VerticalFlexLayout>
                </Box>
            </GridItem>
            <Divider/>
            <Box px={2} py={1}>
                <InputBase
                    fullWidth
                    autoFocus
                    value={message}
                    placeholder={strings("DefaultInputPlaceholder")}
                    onChange={onChange}
                    inputProps={{maxLength: props.maxMessageLength}}
                    endAdornment={
                        <IconButton
                            onClick={onSend}
                            disabled={loading || !message}>
                            <SendIcon color={loading || !message ? colors.disabledFill : colors.primary}/>
                        </IconButton>
                    }/>
            </Box>
        </VerticalFlexLayout>
    )
}

export default ChatConversationView
