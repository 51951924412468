import {AxiosResponse} from "axios";
import ApiResponse from "./models/ApiResponse";
import ApiClient from "util/api/client/ApiClient";
import {enqueueSnackbar} from "util/SnackbarManager";
import {DataPaginationInput} from "util/api/models/DataPagination";

class BaseApi {
    protected async get<T>(path: string) {
        return this.handleResponse<T>(async () => {
            return await ApiClient.get<T>(path)
        })
    }

    protected async post<T>(path: string, body?: any) {
        return this.handleResponse<T>(async () => {
            return await ApiClient.post<T>(path, body)
        })
    }

    protected async put<T>(path: string, body?: any) {
        return this.handleResponse<T>(async () => {
            return await ApiClient.put<T>(path, body)
        })
    }

    protected async patch<T>(path: string, body?: any) {
        return this.handleResponse<T>(async () => {
            return await ApiClient.patch<T>(path, body)
        })
    }

    protected async delete<T>(path: string, body?: any) {
        return this.handleResponse<T>(async () => {
            return await ApiClient.delete<T>(path, {data: body})
        })
    }

    protected getPaginationQuery<T>(pagination: DataPaginationInput<T>): String {
        const encode = encodeURIComponent;
        return Object.keys(pagination).map(key => {
            // @ts-ignore
            const value = pagination[key]
            if (value) {
                let param = `${encode(key)}=`
                if (value instanceof Array) {
                    param += value.map(v => encode(v)).join(',')
                } else {
                    param += encode(value)
                }
                return param
            }

            return ""
        }).filter(p => p).join('&')
    }

    protected handleResponse<T>(callback: (() => Promise<AxiosResponse<T>>)) {
        return new Promise<T | undefined>(async (resolve, reject) => {
            await callback()
                .then(response => {
                    const data = response.data as ApiResponse<T> | undefined
                    if (data?.success) {
                        return resolve(data.data)
                    } else {
                        if (data?.errors) {
                            enqueueSnackbar({errors: data.errors, type: "error"})
                        } else {
                            enqueueSnackbar({message: data?.message, type: "error"})
                        }

                        return reject(data)
                    }
                })
                .catch((error) => {
                    const data = error.response.data
                    if (data) {
                        if (data.errors) {
                            enqueueSnackbar({errors: data.errors, type: "error"})
                        } else {
                            enqueueSnackbar({message: data.message, type: "error"})
                        }

                        return reject(data)
                    } else {
                        //NOTE: This handles ApiClient Request/Response errors
                        enqueueSnackbar({message: "Internal Error!", type: "error"})
                        console.log("ApiClient Error", error)
                    }

                    return resolve(undefined)
                })
        })
    }
}

export default BaseApi
