const colors = {
    primary: "#001a9c",
    secondary: "#ff5900",
    premium: "#F8B133",
    success: "#51B529",
    error: "#B50000",
    text: "#161616",
    gray: "#818181",
    copy: "#4D4D4D",
    warning: "#F1C21B",
    disabledFill: "#EAEAEA"
}

export default colors
