import Strings from "models/Strings";

const strings_en: Strings = {
    AppLogo: `${process.env.PUBLIC_URL}/assets/full-logo.svg`,
    AppDescription: "Enjoy unlimited discounts at over 150+ places in Bahrain. Get your Digital Student ID & access a world of student benefits! Join Bahrain’s Biggest Student Offers App!",
    "AgreeToCampaignTerms": "By checking this box, I agree to the Terms & Conditions.",
    "AgreeToCampaignTermsErrorMessage": "Please agree to the T&C to proceed.",
    "AllTime": "All Time",
    "Apply": "Apply",
    "AppName": "Unipal",
    "AverageRating": "Average Rating",
    "Branch": "Branch",
    "NotEligible": "Not Eligible",
    "CampaignRequestFailureMessage": "Sorry! Something went wrong and we were unable to send your request. Please try again later or contact the Unipal team directly to get help.",
    "CampaignRequestSuccessMessage": "Your request has been successfully sent to Unipal. The Unipal Team will review and confirm your request within 48 hours.",
    "Campaigns": "Campaigns",
    "Cancel": "Cancel",
    "CardNumber": "Card Number",
    "Close": "Close",
    "Comment": "Comment",
    "Company": "Company",
    "Conditions": "Conditions",
    "ConfirmPurchase": "Confirm Purchase",
    "Connect": "Connect",
    "Connected": "Connected",
    "ContactUs": "Contact us",
    "Count": "Count",
    "CreatePurchaseSuccessMessage_Amount": "Confirmed! Student has saved {0}.",
    "Currency_AE": "AED",
    "Currency_BH": "BHD",
    "Currency_CZ": "CZK",
    "Currency_SA": "SAR",
    "Custom": "Custom",
    "Dashboard": "Dashboard",
    "DataWillBeAvailableSoon": "Data will be available soon.",
    "Date": "Date",
    "DateRange": "Date Range",
    "Details": "Details",
    "Discount": "Discount",
    "DropUsMessage": "Drop us a message",
    "EmailAddress": "Email Address",
    "EnterValidAmount": "Please enter a valid amount.",
    "EnterVerificationValuePlaceholder": "Enter ID or Voucher",
    "EstGrossRevenue": "Est. Gross Revenue",
    "FirstTimerStudents": "New First-Time Students",
    "FirstTimerStudentsDescription_ShopName": "Students that have visited {0} for the first-time ever.",
    "ForgotPassword": "Forgot your password? Contact us.",
    "GotIt": "Got it",
    "Home": "Home",
    "IntegrationsPageTip": "Integrate Unipal with your other systems to supercharge your workflow.",
    "IntegrationsPageTitle": "Integrations",
    "IntegrationSuccessPageMessage": "Our team will now complete the configuration and reach out to you to roll out the integration.",
    "IntegrationSuccessPageTitle": "Request Sent!",
    "InternalErrorMessage": "Internal Error! Please contact the Admin.",
    "LandingPageMessage": "Join over 200+ brands and connect with the student population.",
    "Language": "Language",
    "LanguageSelection": "Language Selection",
    "Leave": "Leave",
    "Login": "Login",
    "Logout": "Logout",
    "LogoutConfirmationMessage": "Are you sure you want to logout?",
    "LoyalStudents": "Loyal Students",
    "LoyalStudentsDescription": "Number of students that have made more than 8 purchases and are considered loyal customers.",
    "MaleVsFemale": "Male vs Female",
    "Message": "Message",
    "Month": "Month",
    "Name": "Name",
    "NoDataAvailable": "No data are available!",
    "NoPermissionToAccessApp": "Sorry! It seems like you don't have enough permission to access the app!",
    "Notifications": "Notifications",
    "Now": "now",
    "Offer": "Offer",
    "PageNotFoundMessage": "We're sorry, the page you requested could not be found.",
    "PageNotFoundTitle": "Page Not Found",
    "Participants": "Participants",
    "Participate": "Participate",
    "Password": "Password",
    "PhoneNumber": "Phone Number",
    "PhoneOrEmail": "Phone/Email",
    "Position": "Position",
    "PremiumOffer": "Haiba Offer",
    "Rating": "Rating",
    "RedeemVoucherSuccessMessage_Amount": "Confirmed! Voucher has been redeemed for {0}.",
    "RequestLeaveCampaign": "I would like to stop my participation in this campaign.",
    "RequestParticipateInCampaign": "I would like to participate in this campaign.",
    "RetentionRate": "Retention Rate",
    "ReturningStudents": "Returning Students",
    "ReturningStudentsDescription_ShopName": "Retained students that have visited {0} before.",
    "ReturnToDashboard": "Return to Dashboard",
    "Review": "Review",
    "Saving": "Saving",
    "SavingAmount": "Saving amount",
    "ScanQrButtonText": "Scan Unipal ID",
    "Settings": "Settings",
    "ShopDashboard_ShopName": "{0}'s Dashboard",
    "StandardOffer": "Standard Offer",
    "StudentEligibleForTheseOffers": "This student is eligible for these offers:",
    "StudentNotEligibleForAnyOffers": "This student is NOT eligible for any offer.",
    "StudentReviews": "Student Reviews",
    "StudentVerification": "Student Verification",
    "Submit": "Submit",
    "SubmitRequest": "Submit Request",
    "TermsConditions": "Terms & Conditions",
    "ThisMonth": "This Month",
    "TotalPurchases": "Total Purchases",
    "TotalRatings": "Total Ratings",
    "TotalReviews": "Total Reviews",
    "TransactionsHistory": "Transactions History",
    "TryAgain": "Try again!",
    "Type": "Type",
    "University": "University",
    "UnverifiedStudent": "Unverified student",
    "Username": "Username",
    "Verified": "Verified",
    "VerifiedPurchase": "Verified Purchase",
    "UnverifiedPurchase": "General Review",
    "VerifiedStudent": "Verified student",
    "VerifyByValueButtonText": "Verify by ID or Voucher",
    "Voucher": "Voucher",
    "WannaBecomePartnerMessage": "Want to become a partner? Contact us.",
    "WeGotYourMessage": "We got your message!",
    "WeWillContactYouMessage": "The Unipal team will contact you within 24 hours.",
    "YouAreNotParticipatingInCampaign": "You are not participating in this campaign",
    "YouAreParticipatingInCampaign": "You are participating in this campaign",
    "ReplyMessagePlaceholder": "Your reply...",
    "ViewMore": "View More",
    "You": "You",
    "UnipalTeam": "Unipal Team",
    "StudentRatingsTabTitle": "Ratings from Purchases",
    "StudentFeedbacksTabTitle": "Other Reviews",
    "StudentRatingsTabDescription": "Ratings & Reviews is categorized into 2 types: verified purchases and other reviews. Only ratings from verified purchases are displayed publicly on the App, other reviews is only shared with you.",
    "AccountNotLiveTitle": "Your account is not live!",
    "AccountNotLiveMessage": "Complete your profile set up so we can put you live 😀.",
    "UpdateAboutShopPageTitle": "About your shop",
    "UpdateAboutShopPageMessage": "Tell us about your shop. This is what our students see when they access any of the pages associated with your shop.",
    "WebsiteFieldLabel": "Website (optional)",
    "InstagramFieldLabel": "Instagram (optional)",
    "Back": "Back",
    "Continue": "Continue",
    "UpdateShopSocialPageTitle": "Social Media Links",
    "UpdateShopSocialPageMessage": "Students love social media! Make sure you add your accounts so they engage with you!",
    "UpdateOfferDetailsPageTitle": "Offer Details",
    "UpdateOfferDetailsPageMessage": "Tell us what the students are getting from your shop. This is what our students see when they access any of the pages associated with your shop.",
    "OfferTypePercentage": "Percentage",
    "OfferTypeFixed": "Fixed Amount",
    "OfferTypeFieldLabel": "Offer Type",
    "OfferValueFieldLabel": "Offer Value",
    "ZoomFieldLabel": "Zoom",
    "CompleteProfilePageTitle": "Profile complete!",
    "CompleteProfilePageMessage": "Your profile is ready to be reviewed! Our team will review your profile and get back to you in 3-5 days!",
    "SubmitForReview": "Submit for review",
    "UploadImage": "Upload image",
    "DragDropImageTip": "or drag and drop an image to upload",
    "InvalidShopImagesMessage": "Please upload all the required images.",
    "ManageShopMediaPageTitle": "Media Management",
    "ManageShopMediaPageMessage": "This is where you show off, because you have every right to. Remember, a picture speaks a thousand words so make sure you add the best ones!",
    "LivePreviewLabel": "Live Preview",
    "UploadLogoLabel": "Logo",
    "UploadCoverLabel": "Cover",
    "UploadThumbnailLabel": "Thumbnail",
    "UploadLogoTip": "Upload an image with a 1:1 aspect ratio.",
    "UploadCoverTip": "Upload an image with a 1:1 aspect ratio.",
    "UploadThumbnailTip": "Upload an image with a 16:9 aspect ratio.",
    "LivePreviewTip": "This is how it will be shown in the app for the students.",
    "ViewRatings": "View Ratings",
    "TotalRatings_Count": "{0} ratings",
    "ProfileSetupTitle": "Profile Setup",
    "Upload": "Upload",
    "CompleteNow": "Complete Now",
    "ManageBranchesPageTitle": "Branch Details",
    "ManageBranchesPageMessage": "Add all your branches for them to appear on the Unipal Map!\n\nNote: The Unipal Map is the Students’ most liked feature on the App 😉.",
    "ImportGoogleMapsLocationsTitle": "Import from Google Maps",
    "ImportGoogleMapsLocationsMessage": "You can easily import your locations from your Google Maps.",
    "ImportGoogleMapsLocationsSteps": "Easy steps to directly export your locations from Google Maps directly:<ol style=\"margin:0\"><li style=\"margin-top:16px\">Go to <a target=\"_blank\" href=\"https://mymaps.google.com\" rel=\"noreferrer\"><strong>https://mymaps.google.com</strong></a> and login to your Google Maps account</li><li style=\"margin-top:16px\">Click on the Map you want to export</li><li style=\"margin-top:16px\">Click on the button on the top right corner (the one with 3 dots)</li><li style=\"margin-top:16px\">From the dropdown menu, select <strong>\"Export to KML/KMZ\"</strong> to download the file</li><li style=\"margin-top:16px\">And that's it, now simply select or upload the file here</li></ol>",
    "ImportGoogleMapsLocationsInvalidFileError": "The file you uploaded is either not valid, or it doesn't contain any location.",
    "GetGoogleMapsCoordinatesTitle": "How can I get the coordinates?",
    "GetGoogleMapsCoordinatesSteps": "Adding your location from Google Maps:<ol style=\"margin:0\"><li style=\"margin-top:16px\">Go to Google Maps: <a target=\"_blank\" href=\"https://www.google.com/maps/\" rel=\"noreferrer\"><strong>https://www.google.com/maps</strong></a></li><li style=\"margin-top:16px\">Right click on the branch you want to add</li><li style=\"margin-top:16px\">Click on the first row with the numbered coordinates and it will copy it</li><li style=\"margin-top:16px\">Paste the coordinates here and that's it!</li></ol>",
    "UploadFile": "Choose a file",
    "DragDropFileTip": "or drag and drop a file here to upload",
    "ViewInMap": "View in Map",
    "Import": "Import",
    "AddBranch": "Add Branch",
    "Save": "Save",
    "Add": "Add",
    "Coordinates": "Coordinates",
    "BackToDashboard": "Back to dashboard",
    "InvalidCoordinatesMessage": "Please enter valid coordinates.",
    "ProfileDetailsRejectedTitle": "Your profile has not been approved!",
    "ProfileDetailsBeingReviewedMessage": "Your profile changes are currently under review by our team.",
    "Resubmit": "Resubmit",
    "FromLabel": "From:",
    "ToLabel": "To:",
    "UnauthorizedPageTitle": "Unauthorized!",
    "Reply": "Reply",
    "Chat": "Chat",
    "ViewReplies_Count": "View {0} Replies",
    "ReplyPublicly": "Reply Publicly",
    "HideReplies": "Hide Replies",
    "DefaultInputPlaceholder": "Type a message",
    "ChatPageTitle": "Student Chats",
    "ChatPageMessage": "Conversations in the chat box is only visible to you and the student you're chatting with. Chats are not visible to the public.",
    "EmptyConversationViewMessage": "Select a conversation",
    "EmptyConversationsListMessage": "You don't have any conversations yet!",
    "NoActiveOffersMessage": "You don't have any active offers at the moment!",
    "SubmitReplyConfirmationTitle": "This reply will be shown to the public and cannot be edited.",
    "SubmitReplyConfirmationMessage": "<strong>You can only submit one reply.</strong><br/>If you wish to talk with the student privately first, use the chat feature.",
}

const strings_ar: Strings = {
    AppLogo: `${process.env.PUBLIC_URL}/assets/full-logo.svg`,
    AppDescription: "Enjoy unlimited discounts at over 150+ places in Bahrain. Get your Digital Student ID & access a world of student benefits! Join Bahrain’s Biggest Student Offers App!",
    "AgreeToCampaignTerms": "بتحديد هذه الخانة، أوافق على الشروط والأحكام.",
    "AgreeToCampaignTermsErrorMessage": "يُرجى الموافقة على الشروط والأحكام للمتابعة.",
    "AllTime": "في جميع الأوقات",
    "Apply": "تطبيق",
    "AppName": "يونيبال",
    "AverageRating": "متوسط التقييمات",
    "Branch": "الفرع",
    "NotEligible": "غير مؤهل",
    "CampaignRequestFailureMessage": "آسفين! الظاهر في خلل وما قدرنا نرسل طلبك. حاول مرة ثانية بعد شوية، أو تواصل مع فريق يونيبال مباشرة إذا تحتاج اي المساعدة.",
    "CampaignRequestSuccessMessage": "تم إرسال طلبك بنجاح. راح يتواصل معك فريق يونيبال خلال 48 ساعة.",
    "Campaigns": "الحملات",
    "Cancel": "الغاء",
    "CardNumber": "رقم البطاقة",
    "Close": "اغلاق",
    "Comment": "التعليق",
    "Company": "الشركة",
    "Conditions": "الشروط",
    "ConfirmPurchase": "تأكيد الشراء",
    "Connect": "ربط",
    "Connected": "مرتبط",
    "ContactUs": "اتصل علينا",
    "Count": "العدد",
    "CreatePurchaseSuccessMessage_Amount": "ممتاز! الطالب وفر {0}.",
    "Currency_AE": "د.إ",
    "Currency_BH": "د.ب",
    "Currency_CZ": "ك.ج",
    "Currency_SA": "ر.س",
    "Custom": "مخصص",
    "Dashboard": "لوحة التحكم",
    "DataWillBeAvailableSoon": "المعلومات سوف تتوفر قريبا",
    "Date": "التاريخ",
    "DateRange": "النطاق الزمني",
    "Details": "التفاصيل",
    "Discount": "الخصم",
    "DropUsMessage": "ارسل لنا رسالة",
    "EmailAddress": "البريد الالكتروني",
    "EnterValidAmount": "الرجاء ادخال رقم صحيح.",
    "EnterVerificationValuePlaceholder": "ادخل رقم البطاقة او الكوبون",
    "EstGrossRevenue": "إجمالي الإيرادات المقدرة",
    "FirstTimerStudents": "طالب جديد/اول مرة",
    "FirstTimerStudentsDescription_ShopName": "الطلاب الذين زاروا {0} للمرة الأولى.",
    "ForgotPassword": "نسيت رقمك السري؟ اتصل علينا",
    "GotIt": "تم",
    "Home": "الصفحة الرئيسية",
    "IntegrationsPageTip": "اربط تطبيق يونيبال مع تطبيقاتك الثانية عشان تعزز سير عملك في جميع فروعك.",
    "IntegrationsPageTitle": "ربط التطبيقات",
    "IntegrationSuccessPageMessage": "فريقنا راح يتواصل معك عشان نكمل تهيئة حسابك ونواصل في ربط التطبيق.",
    "IntegrationSuccessPageTitle": "وصلنا طلبك!",
    "InternalErrorMessage": "خطأ داخلي! يرجى الاتصال بالمسؤول.",
    "LandingPageMessage": "انضم إلى أكثر من 200 علامة تجارية وتواصل مع الطلبة.",
    "Language": "اللغة",
    "LanguageSelection": "اختيار اللغة",
    "Leave": "خروج",
    "Login": "تسجيل الدخول",
    "Logout": "تسجيل الخروج",
    "LogoutConfirmationMessage": "متأكد تبغا  تسجل الخروج؟",
    "LoyalStudents": "الطلاب المخلصين",
    "LoyalStudentsDescription": "عدد الطلاب الذين أجروا أكثر من 8 عمليات شراء ويعتبرون عملاء مخلصين.",
    "MaleVsFemale": "الذكور مقابل الإناث",
    "Message": "الرسالة",
    "Month": "الشهر",
    "Name": "الاسم",
    "NoDataAvailable": "لا توجد بيانات متاحة!",
    "NoPermissionToAccessApp": "آسفين! ما عندك  الإذن الكافي للوصول إلى التطبيق!",
    "Notifications": "الإشعارات",
    "Now": "الآن",
    "Offer": "العرض",
    "PageNotFoundMessage": "معليش! الصفحة اللي تدور عليها غير موجودة.",
    "PageNotFoundTitle": "الصفحة غير موجودة",
    "Participants": "المشاركين",
    "Participate": "شارك",
    "Password": "الرقم السري",
    "PhoneNumber": "رقم الهاتف",
    "PhoneOrEmail": "رقم الهاتف/البريد الالكتروني",
    "Position": "الوظيفة",
    "PremiumOffer": "عرض الهيبة",
    "Rating": "التقييم",
    "RedeemVoucherSuccessMessage_Amount": "ممتاز! تم استرداد القسيمة بقيمة {0}.",
    "RequestLeaveCampaign": "ودي أوقف مشاركتي في هذه الحملة.",
    "RequestParticipateInCampaign": "ودي أشترك في هذه الحملة.",
    "RetentionRate": "معدل البقاء",
    "ReturningStudents": "عودة الطلاب",
    "ReturningStudentsDescription_ShopName": "الطلاب الذين احتفظوا بزيارتهم لـ{0} من قبل.",
    "ReturnToDashboard": "ارجع إلى لوحة التحكم",
    "Review": "تعليق",
    "Saving": "التوفير",
    "SavingAmount": "مبلغ التوفير",
    "ScanQrButtonText": "امسح بطاقة يونيبال",
    "Settings": "الإعدادات",
    "ShopDashboard_ShopName": "لوحة تحكم {0}",
    "StandardOffer": "العرض الأساسي",
    "StudentEligibleForTheseOffers": "هذا الطالب مؤهل للحصول على العروض التالية:",
    "StudentNotEligibleForAnyOffers": "هذا الطالب غير مؤهل لأي عرض.",
    "StudentRatingsTabTitle": "التقييمات من المعاملات",
    "StudentReviews": "تقييمات الطلاب",
    "StudentVerification": "توثيق الطالب",
    "Submit": "إرسال",
    "SubmitRequest": "ارسل الطلب",
    "TermsConditions": "الشروط والاحكام",
    "ThisMonth": "هذا الشهر",
    "TotalPurchases": "مجموع المشتريات",
    "TotalRatings": "عدد التقييمات",
    "TotalReviews": "عدد التعليقات",
    "TransactionsHistory": "تاريخ المعاملات",
    "TryAgain": "حاول مرة ثانية!",
    "Type": "النوع",
    "University": "الجامعة",
    "UnverifiedStudent": "طالب غير موثف",
    "Username": "اسم المستخدم",
    "Verified": "موثّق",
    "VerifiedPurchase": "معاملة موثقة",
    "UnverifiedPurchase": "تعليق عام",
    "VerifiedStudent": "طالب موثق",
    "VerifyByValueButtonText": "ادخل رقم البطاقة او الكوبون",
    "Voucher": "قسيمة",
    "WannaBecomePartnerMessage": "ودك تكون شريك؟ اتصل علينا",
    "WeGotYourMessage": "وصلتنا رسالتك",
    "WeWillContactYouMessage": "فريق يونيبال راح يتواصل معك!",
    "YouAreNotParticipatingInCampaign": "انت غير مشارك في هالحملة",
    "YouAreParticipatingInCampaign": "انت مشارك في هالحملة",
    "ReplyMessagePlaceholder": "ردك...",
    "ViewMore": "عرض المزيد",
    "You": "أنت",
    "UnipalTeam": "فريق يونيبال",
    "StudentFeedbacksTabTitle": "تقييمات أخرى",
    "StudentRatingsTabDescription": "التقييمات والتعليقات مقسمة إلى نوعين: تقييمات الشراء الموثقة والتقييمات الأخرى. بس التقييمات الموثقة تظهر علناً في التطبيق، أما التقييمات الأخرى بتكون سرية بينك وبين الطالب بس.",
    "AccountNotLiveTitle": "حسابك مب مفعل للحين!",
    "AccountNotLiveMessage": "كمل إعداد حسابك عشان نقدر نفعله لك 😀.",
    "UpdateAboutShopPageTitle": "نبذة عن متجرك",
    "UpdateAboutShopPageMessage": "خبرنا أكثر عن محلك. هذا اللي يشوفونه طلابنا لما يدخلون أي صفحة مرتبطة بمحلك.",
    "WebsiteFieldLabel": "الموقع الإلكتروني (اختياري)",
    "InstagramFieldLabel": "الإنستغرام (اختياري)",
    "Back": "الرجوع",
    "Continue": "التالي",
    "UpdateShopSocialPageTitle": "روابط وسائل التواصل الاجتماعي",
    "UpdateShopSocialPageMessage": "الطلاب يحبون وسائل التواصل الاجتماعي! تأكد من إضافة حساباتك عشان يتفاعلوا معك!",
    "UpdateOfferDetailsPageTitle": "تفاصيل العرض",
    "UpdateOfferDetailsPageMessage": "قول لنا شنو الطلاب راح يستفيدون من محلك. هذا اللي طلابنا يشوفونه لما يدخلون أي صفحة مرتبطة بمحلك.",
    "OfferTypePercentage": "نسبة مئوية",
    "OfferTypeFixed": "مبلغ ثابت",
    "OfferTypeFieldLabel": "نوع العرض",
    "OfferValueFieldLabel": "قيمة العرض",
    "ZoomFieldLabel": "التكبير",
    "CompleteProfilePageTitle": "اكمل ملفك الشخصي!",
    "CompleteProfilePageMessage": "بروفايلك جاهز للمراجعة! فريقنا راح يراجع المعلومات ويرد عليك خلال 3-5 أيام!",
    "SubmitForReview": "قدم البروفايل للمراجعة",
    "UploadImage": "حمل صورة",
    "DragDropImageTip": "أو اسحب وافلت صورة للتحميل",
    "InvalidShopImagesMessage": "ما عليك أمر، حمل كل الصور المطلوبة.",
    "ManageShopMediaPageTitle": "إدارة الوسائط",
    "ManageShopMediaPageMessage": "هذا هو المكان اللي تتألق فيه، لأن عندك كل الحق. تذكر، الصورة عن ألف كلمة، فتأكد تضيف أحلى الصور!",
    "LivePreviewLabel": "المعاينة الحية",
    "UploadLogoLabel": "الشعار",
    "UploadCoverLabel": "الغلاف",
    "UploadThumbnailLabel": "الصورة المصغرة",
    "UploadLogoTip": " صورة بنسبة عرض إلى ارتفاع 1:1.",
    "UploadCoverTip": " صورة بنسبة عرض إلى ارتفاع 1:1.",
    "UploadThumbnailTip": "حمل صورة بنسبة عرض إلى ارتفاع 16:9.",
    "LivePreviewTip": "الطلاب راح يشوفونه بهالشكل في التطبيق.",
    "ViewRatings": "عرض التقييمات",
    "TotalRatings_Count": "{0} تقييم",
    "ProfileSetupTitle": "إعداد الحساب",
    "Upload": "تحميل",
    "CompleteNow": "كمل الحين",
    "ManageBranchesPageTitle": "تفاصيل الفروع",
    "ManageBranchesPageMessage": "أضف جميع الفروع الخاصة بك لكي تظهر في خريطة يونيبال!\n\nملاحظة: خريطة يونيبال هي الميزة المفضلة لدى الطلاب على التطبيق 😉.",
    "ImportGoogleMapsLocationsTitle": "استيراد من خرائط جوجل",
    "ImportGoogleMapsLocationsMessage": "تقدر تستورد مواقعك من خرائط جوجل بسهولة.",
    "ImportGoogleMapsLocationsSteps": " خطوات سهلة لتصدير مواقعك مباشرة من خرائط جوجل بشكل مباشر:<ol style=\"margin:0\"><li style=\"margin-top:16px\">روح الى <a target=\"_blank\" href=\"https://mymaps.google.com\" rel=\"noreferrer\"><strong>https://mymaps.google.com</strong></a> وادخل لحسابك في خرائط جوجل</li><li style=\"margin-top:16px\">اضغط على الخريطة اللي تبي تصديرها</li><li style=\"margin-top:16px\">اضغط على الزر اللي في الزاوية اليمنى العلوية (الثلاث نقاط)</li><li style=\"margin-top:16px\">من القائمة، اختار <strong>\"تصدير إلى KML/KMZ\"</strong> عشان تحمل الملف</li><li style=\"margin-top:16px\">وسلامتك، الحين اختر أو حمل الملف تحت</li></ol>",
    "ImportGoogleMapsLocationsInvalidFileError": "الملف اللي حملته إما غير صالح، أو لمافيه أي موقع صالح.",
    "GetGoogleMapsCoordinatesTitle": "شلون اقدر احصل الإحداثيات؟",
    "GetGoogleMapsCoordinatesSteps": "إضافة موقعك من خرائط جوجل:<ol style=\"margin:0\"><li style=\"margin-top:16px\">روح الى خرائط جوجل: <a target=\"_blank\" href=\"https://www.google.com/maps/\" rel=\"noreferrer\"><strong>https://www.google.com/maps</strong></a></li><li style=\"margin-top:16px\">اضغط بالزر الأيمن على الفرع اللي تبي تضيفه</li><li style=\"margin-top:16px\">اضغط على أول خيار في القائمة، اللي فيه الإحداثيات المرقمة عشان تنسخها</li><li style=\"margin-top:16px\">الصق الإحداثيات هنا، وسلامتك!</li></ol>",
    "UploadFile": "اختر ملف",
    "DragDropFileTip": "أو اسحب وإفلت الملف هنا للتحميل",
    "ViewInMap": "اعرض على الخريطة",
    "Import": "استيراد",
    "AddBranch": "إضافة فرع",
    "Save": "حفظ",
    "Add": "إضافة",
    "Coordinates": "الإحداثيات",
    "BackToDashboard": "ارجع للوحة التحكم",
    "InvalidCoordinatesMessage": "الإحداثيات غير صحيحة.",
    "ProfileDetailsRejectedTitle": "معلومات حسابك ما تمت الموافقة عليها!",
    "ProfileDetailsBeingReviewedMessage": "معلومات حسابك حاليًا قيد المراجعة من قبل فريقنا.",
    "Resubmit": "إعادة التقديم",
    "FromLabel": "من:",
    "ToLabel": "إلى:",
    "UnauthorizedPageTitle": "غير مصرح!",
    "Reply": "رد",
    "Chat": "الدردشة",
    "ViewReplies_Count": "عرض {0} ردود",
    "ReplyPublicly": "الرد علناً",
    "HideReplies": "إخفاء الردود",
    "DefaultInputPlaceholder": "اكتب رسالتك",
    "ChatPageTitle": "دردشات الطلاب",
    "ChatPageMessage": "المحادثات في صندوق الدردشة بينك وبين الطالب اللي تدردش معه بس. المحادثات ما راح تكون ظاهرة للعامة.",
    "EmptyConversationViewMessage": "اختار محادثة",
    "EmptyConversationsListMessage": "ماعندك محادثات للحين!",
    "NoActiveOffersMessage": "ما عندك أي عروض فعّالة حاليًا طال عمرك!",
    "SubmitReplyConfirmationTitle": "هذا الرد راح يظهر للعامة وما بتقدر تغيره.",
    "SubmitReplyConfirmationMessage": "<strong>تقدر ترسل رد واحد بس.</strong><br/>إذا حاب بالأول تتواصل مع الطالب بشكل خاص، استخدم ميزة الدردشة.",
}

export {
    strings_en,
    strings_ar
}
