import { AxiosRequestConfig, AxiosResponse } from "axios";
import HandlerPriority from "./enums/HandlerPriority";
import ApiClientHandlerResult from "./models/ApiClientHandlerResult";

interface Handler<T extends AxiosRequestConfig | AxiosResponse> {
    callback: ((obj: T) => Promise<ApiClientHandlerResult<T>>),
    priority: HandlerPriority
}

class ApiClientHandlersManager {

    private _requestHandlers: Handler<AxiosRequestConfig>[] = [];
    private _responseHandlers: Handler<AxiosResponse>[] = [];

    get requestHandlers() {
        return this._requestHandlers;
    }

    get responseHandlers() {
        return this._responseHandlers;
    }

    addRequestHandler(callback: ((request: AxiosRequestConfig) => Promise<ApiClientHandlerResult<AxiosRequestConfig>>),
        priority = HandlerPriority.NORMAL) {
        this._requestHandlers.push({ callback: callback, priority: priority });
        this._requestHandlers.sort((h1, h2) => (h1.priority < h2.priority) ? 1 : -1);
    }

    addResponseHandler(callback: ((response: AxiosResponse) => Promise<ApiClientHandlerResult<AxiosResponse>>),
        priority = HandlerPriority.NORMAL) {
        this._responseHandlers.push({ callback: callback, priority: priority });
        this._responseHandlers.sort((h1, h2) => (h1.priority < h2.priority) ? 1 : -1);
    }

    // TODO: Only allow the owner of this instance to clear all handlers,
    // and provide different methods to only remove the handler of the target/caller

    clearRequestHandlers() {
        while (this._requestHandlers.length) {
            this._requestHandlers.pop();
        }
    }

    clearResponseHandlers() {
        while (this._responseHandlers.length) {
            this._responseHandlers.pop();
        }
    }

}

export default ApiClientHandlersManager;

