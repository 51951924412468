import React from "react";
import {FieldProps} from "formik";
import {BaseTextFieldProps, TextField} from "@mui/material";

interface Props extends FieldProps, BaseTextFieldProps {
}

const InputFieldComponent: React.FC<Props> =
    ({field, form: {touched, errors}, ...props}) => (
        <TextField
            {...field}
            {...props}
            variant="outlined"
            helperText={errors[field.name] as string}
            error={(errors[field.name] && touched[field.name]) as boolean}
            fullWidth
        />
    );

export default InputFieldComponent;