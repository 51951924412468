import React, {FC, PropsWithChildren} from "react";
import {useResources} from "contexts/ResourcesProvider";
import {Helmet} from "react-helmet";

interface Props {
    title?: string
    description?: string
}

const HtmlMetaDecorator: FC<PropsWithChildren<Props>> = (props) => {
    const {strings} = useResources()

    let title = (props.title ? `${props.title} - ` : "") + strings("AppName")
    if (title.length > 55) {
        title = title.slice(0, 52) + "..."
    }

    let description = props.description || ""
    if (description.length > 200) {
        description = description.slice(0, 197) + "...";
    }

    return (
        <Helmet>
            <title>{title}</title>
            <meta key="description" name="description" content={description}/>
            {props.children}
        </Helmet>
    )
}


export default HtmlMetaDecorator
