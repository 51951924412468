import React, {FC, useCallback, useRef, useState, useEffect} from 'react';
import {Box, List, ListItemButton, Paper} from "@mui/material";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import HtmlMetaDecorator from "components/common/HtmlMetaDecorator";
import colors from "res/colors";
import Api from "util/api/Api";
import {useResources} from "contexts/ResourcesProvider";
import appSettings from "appSettings";
import {useNavigate, useSearchParams} from "react-router-dom";
import LatestReply from "models/LatestReply";
import {Body1, Body2, Heading5} from "@unipal/ui/typography/Typography";
import ChatNavItem from "@unipal/components/chat/ChatNavItem";
import {toChatMessage, toConversation} from "@unipal/utils/mapping";
import RatingRepliesControl from "pages/ratings/RatingRepliesControl";

const LatestReplies: FC = () => {
    const {strings} = useResources()
    const [params] = useSearchParams()
    const navigate = useNavigate()

    const id = params.get("id")
    const type = params.get("type")

    const [replies, setReplies] = useState<LatestReply[]>([])
    const [selected, setSelected] = useState<LatestReply>()
    const [hasMore, setHasMore] = useState(false)
    const token = useRef<string>()

    const load = useCallback(async () => {
        await Api.ratings
            .getLatestReplies(token.current)
            .then(data => {
                token.current = data?.pagingToken
                setHasMore(Boolean(data?.pagingToken))

                if (data?.items) {
                    setReplies(ps => [...ps, ...data.items])
                }
            })
            .catch(() => {
            })
    }, [])

    useEffect(() => {
        if (id && type) {
            void async function fetchData() {
                await Api.ratings
                    .getRating(id, type)
                    .then(data => {
                        if (data) {
                            // @ts-ignore
                            // TODO: This should satisfies the properties required
                            //  for the RatingRepliesControl, better to use a VM.
                            //  Also remove the extra 'id' and 'ratingId' checking below.
                            setSelected({...data})
                        }
                    })
                    .catch(() => {
                    })
            }()
        }
    }, [id, type])

    useEffect(() => {
        if (!appSettings.allowedFeatures?.includes("ratings")) {
            navigate("/")
            return
        }

        void async function fetchData() {
            await load()
        }()
    }, [load, navigate])

    const renderReplies = replies.map(r => {
        const conv = toConversation(r)
        const msg = toChatMessage(
            r, r.studentName, r.shopName,
            r.shopLogo, "SHOP", strings
        )
        return (
            <ListItemButton
                sx={{padding: 0}}
                selected={r.id === selected?.id}
                onClick={() => setSelected(r)}>
                <ChatNavItem
                    conversation={conv}
                    message={msg}/>
            </ListItemButton>
        )
    })

    return (<>
        <HtmlMetaDecorator
            title={strings("ChatPageTitle")}/>

        <Box p={4}>
            <VerticalFlexLayout spacing={4}>
                <GridItem>
                    <Heading5 fontWeight={"bold"}>
                        {strings("ChatPageTitle")}
                    </Heading5>
                    <Body2
                        paddingTop={2}
                        color={colors.copy}>
                        {strings("ChatPageMessage")}
                    </Body2>
                </GridItem>
                <Paper>
                    <HorizontalFlexLayout
                        sx={{height: 600}}
                        wrap={"nowrap"}>
                        <GridItem
                            className={"scroll-bar"}
                            xs={4}
                            sx={{
                                maxHeight: "100%",
                                borderRight: "1px solid #e0e0e0",
                                overflowY: "auto"
                            }}>
                            {replies.length ?
                                <List component={"nav"}>
                                    {renderReplies}
                                    {hasMore &&
                                        <ListItemButton
                                            disableGutters
                                            onClick={load}>
                                            <Body1
                                                width={"100%"}
                                                color={colors.primary}
                                                fontWeight={"bold"}
                                                textAlign={"center"}>
                                                {strings("ViewMore")}
                                            </Body1>
                                        </ListItemButton>}
                                </List>
                                :
                                <VerticalFlexLayout
                                    justify={"center"}
                                    alignItems={"center"}
                                    sx={{height: "100%"}}
                                    spacing={2}>
                                    <img width={80} height={80}
                                         alt={"empty"}
                                         src={`${process.env.PUBLIC_URL}/assets/empty-folder.svg`}/>
                                    <Body2
                                        color={colors.gray}>
                                        {strings("EmptyConversationsListMessage")}
                                    </Body2>
                                </VerticalFlexLayout>}
                        </GridItem>
                        <GridItem xs={8}>
                            {selected ?
                                <RatingRepliesControl
                                    {...selected}
                                    id={selected.ratingId || selected.id}
                                    type={selected.ratingType || selected.type}/>
                                :
                                <VerticalFlexLayout
                                    justify={"center"}
                                    alignItems={"center"}
                                    sx={{height: "100%"}}
                                    spacing={2}>
                                    <img width={80} height={80}
                                         alt={"conversation"}
                                         src={`${process.env.PUBLIC_URL}/assets/conversation-icon.png`}/>
                                    <Body2
                                        color={colors.gray}>
                                        {strings("EmptyConversationViewMessage")}
                                    </Body2>
                                </VerticalFlexLayout>}
                        </GridItem>
                    </HorizontalFlexLayout>
                </Paper>
            </VerticalFlexLayout>
        </Box>
    </>)
}

export default LatestReplies
