import {FC} from "react";
import IconProps from "@unipal/icons/IconProps";
import {useTheme} from "@unipal/contexts/ThemeProvider";

const SendIcon: FC<IconProps> = (props) => {
    const {colors} = useTheme()

    return (
        <svg width={props.size ?? "24"} height={props.size ?? "24"} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M22 2L11 13" stroke={props.color ?? colors.primary} strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M22 2L15 22L11 13L2 9L22 2Z" stroke={props.color ?? colors.primary} strokeWidth="2"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default SendIcon
