import {Box, IconButton, useTheme} from "@mui/material";
import React from "react";
import RightChevronsIcon from "@unipal/icons/RightChevronsIcon";
import LeftChevronsIcon from "@unipal/icons/LeftChevronsIcon";
import RightChevronIcon from "@unipal/icons/RightChevronIcon";
import LeftChevronIcon from "@unipal/icons/LeftChevronIcon";

interface Props {
    count: number
    page: number
    rowsPerPage: number
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

function PaginationActions(props: Props) {
    const theme = useTheme()
    const {count, page, rowsPerPage, onPageChange} = props

    const onFirstPageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, 0);
    }

    const onBackClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    }

    const onNextClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    }

    const onLastPageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <Box sx={{flexShrink: 0, ml: 2.5}}>
            <IconButton onClick={onFirstPageClick} disabled={page === 0}>
                {theme.direction === 'rtl' ? <RightChevronsIcon/> : <LeftChevronsIcon/>}
            </IconButton>
            <IconButton onClick={onBackClick} disabled={page === 0}>
                {theme.direction === 'rtl' ? <RightChevronIcon/> : <LeftChevronIcon/>}
            </IconButton>
            <IconButton onClick={onNextClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
                {theme.direction === 'rtl' ? <LeftChevronIcon/> : <RightChevronIcon/>}
            </IconButton>
            <IconButton onClick={onLastPageClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
                {theme.direction === 'rtl' ? <LeftChevronsIcon/> : <RightChevronsIcon/>}
            </IconButton>
        </Box>
    )
}

export default PaginationActions
