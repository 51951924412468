import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {TableFooter, TablePagination, TableSortLabel, Typography} from "@mui/material";
import {DataPaginationOutput} from "util/api/models/DataPagination";
import {useResources} from "contexts/ResourcesProvider";
import colors from "res/colors";
import PaginationActions from "@unipal/common/PaginationActions";

type SortDirection = 'asc' | 'desc'

export interface Column<T> {
    id: keyof T
    label: string
}

interface Props<T> {
    size?: "small" | "medium"
    fetchData: (limit: number, offset: number, sortBy?: keyof T, sortDirection?: SortDirection) => Promise<DataPaginationOutput<T>>
    columns: Column<T>[]
    renderRow: (rowData: T, index: number, data?: T[]) => (string | JSX.Element)[]
    rowsPerPage?: number
    sortBy?: keyof T
    sortDirection?: SortDirection
    onClick?: (item: T) => void
}

const DynamicDataTable = <T extends any>(props: Props<T> & { children?: ReactNode }) => {
    const {strings} = useResources()

    const [page, setPage] = useState(0)
    const [sortDirection, setSortDirection] = useState<SortDirection>(props.sortDirection ?? 'asc')
    const [sortBy, setSortBy] = useState<keyof T | undefined>(props.sortBy)
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState<DataPaginationOutput<T>>();


    const _load = props.fetchData

    const load = useCallback(async () => {
        const limit = props.rowsPerPage ?? 5
        const offset = limit * page

        setIsLoading(true)
        setState(await _load(limit, offset, sortBy, sortDirection))

        setIsLoading(false)
    }, [page, sortBy, sortDirection, props.rowsPerPage, _load])

    useEffect(() => {
        void async function fetchData() {
            await load()
        }()
    }, [load])


    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handlerSorting = (newSortBy: keyof T) => () => {
        const dir = sortBy === newSortBy && sortDirection === "asc" ? "desc" : "asc"
        setSortDirection(dir)
        setSortBy(newSortBy)
        setPage(0)
    }

    const rowsPerPage = props.rowsPerPage ?? 5
    // NOTE: Used to add empty space when reaching the last page and the available row are less than the rowsPerPage.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (state?.total ?? 0)) : 0;

    return (<>
        <TableContainer sx={{position: 'relative'}}>
            {/* NOTE: This is used to block the interaction with the table while data are loading. */}
            {isLoading && <div style={{width: '100%', height: '100%', position: 'absolute', zIndex: 1}}/>}
            <Table size={props.size}>
                <TableHead>
                    <TableRow>
                        {props.columns.map(c => (
                            <TableCell>
                                <TableSortLabel
                                    active={sortBy === c.id}
                                    direction={sortDirection}
                                    onClick={handlerSorting(c.id)}>
                                    <strong>{c.label}</strong>
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {state?.data?.map((r, i) =>
                        // TODO: Add the 'key' and allow the parent to set the 'key' to be able to manage the row.
                        <TableRow
                            sx={{
                                cursor: props.onClick ? "pointer" : "default",
                                '&:hover': {backgroundColor: props.onClick ? colors.primary + "10" : "inherit"},
                                '&:last-child td, &:last-child th': {border: 0}
                            }}
                            onClick={() => {
                                if (props.onClick) {
                                    props.onClick(r)
                                }
                            }}>
                            {props.renderRow(r, i + (page * rowsPerPage)).map(i =>
                                <TableCell style={{whiteSpace: "pre-line"}} align={"left"}>{i}</TableCell>
                            )}
                        </TableRow>
                    )}

                    {!isLoading && !(state?.total ?? 0) &&
                    <TableRow style={{height: 53}}>
                        <TableCell colSpan={props.columns.length}>
                            <Typography component={"p"} variant={"caption"} align={"center"}>
                                {strings("NoDataAvailable")}
                            </Typography>
                        </TableCell>
                    </TableRow>}

                    {emptyRows > 0 && (
                        <TableRow style={{height: 53 * emptyRows}}>
                            <TableCell colSpan={props.columns.length}/>
                        </TableRow>
                    )}
                </TableBody>

                {(state?.total ?? 0) > rowsPerPage &&
                <TableFooter>
                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            rowsPerPage={rowsPerPage}
                            count={state?.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                            ActionsComponent={PaginationActions}/>
                    </TableRow>
                </TableFooter>}
            </Table>
        </TableContainer>
    </>)
}

export default DynamicDataTable
