import React, {FC, useState} from "react";
import IconButton from "@unipal/ui/iconButton/IconButton";
import CheckIcon from "@unipal/icons/CheckIcon";
import colors from "res/colors";
import CloseIcon from "@unipal/icons/CloseIcon";
import TrashIcon from "@unipal/icons/TrashIcon";
import {Collapse} from "@mui/material";
import {HorizontalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import Button from "@unipal/ui/button/Button";

interface Props {
    onConfirm: () => Promise<void>
    value?: string
}

// TODO: Support more variants, like showing a message with the confirmation button.
// TODO: Show loading.
const DeleteConfirmation: FC<Props> = (props) => {
    const [open, setOpen] = useState(false)

    return (
        <HorizontalFlexLayout
            alignItems={"center"}
            wrap={"nowrap"}>
            <Collapse
                in={open}
                orientation={"horizontal"}>
                <IconButton
                    onClick={async e => {
                        e.stopPropagation()
                        await props.onConfirm()
                    }}>
                    <CheckIcon color={colors.success}/>
                </IconButton>
            </Collapse>
            <Collapse
                in={open}
                orientation={"horizontal"}>
                <IconButton
                    onClick={e => {
                        e.stopPropagation()
                        setOpen(false)
                    }}>
                    <CloseIcon color={colors.copy}/>
                </IconButton>
            </Collapse>
            <Collapse
                in={!open}
                orientation={"horizontal"}>
                {props.value ?
                    <Button
                        color={"error"}
                        variant={"outlined"}
                        onClick={e => {
                            e.stopPropagation()
                            setOpen(true)
                        }}>
                        {props.value}
                    </Button>
                    :
                    <IconButton
                        onClick={e => {
                            e.stopPropagation()
                            setOpen(true)
                        }}>
                        <TrashIcon color={colors.error}/>
                    </IconButton>}
            </Collapse>
        </HorizontalFlexLayout>
    )
}

export default DeleteConfirmation
