import React, {FC, useCallback} from "react";
import {VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {Body1, Heading1} from "@unipal/ui/typography/Typography";
import {useResources} from "contexts/ResourcesProvider";
import {Box, Paper} from "@mui/material";
import Api from "util/api/Api";
import images from "@unipal/res/images";
import {useNavigate} from "react-router-dom";
import paths from "@unipal/utils/paths";
import LoadingButton from "@unipal/ui/button/LoadingButton";

const ShopDetailsConfirmationControl: FC = () => {
    const {strings} = useResources()
    const navigate = useNavigate()

    const submit = useCallback(async () => {
        await Api.shops
            .completeProfileAsync()
            .then(() => navigate(paths.dashboard()))
    }, [navigate])

    return (
        <Paper
            sx={{backgroundImage: `url('${images.decorationBg}')`}}>
            <Box p={5}>
                <VerticalFlexLayout spacing={4}>
                    <Heading1>
                        {strings("CompleteProfilePageTitle")}
                    </Heading1>
                    <Body1 whiteSpace={"pre-wrap"}>
                        {strings("CompleteProfilePageMessage")}
                    </Body1>
                    <LoadingButton
                        variant={"contained"}
                        onClick={submit}>
                        {strings("SubmitForReview")}
                    </LoadingButton>
                </VerticalFlexLayout>
            </Box>
        </Paper>
    )
}

export default ShopDetailsConfirmationControl
