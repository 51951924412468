import React, {FC, useMemo, useState} from "react";
import Branch from "models/Branch";
import {GridItem, HorizontalFlexLayout, VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";
import {useResources} from "contexts/ResourcesProvider";
import {Controller, useForm} from "react-hook-form";
import Api from "util/api/Api";
import InputField from "@unipal/common/InputField";
import Button from "@unipal/ui/button/Button";
import InfoIcon from "@unipal/icons/InfoIcon";
import {Box, Collapse} from "@mui/material";
import {Body2} from "@unipal/ui/typography/Typography";
import colors from "res/colors";

interface Props {
    branch?: Branch
    onComplete: (branch: Branch) => void
    onClose: () => void
}

interface Fields {
    name: string
    altName: string
    coordinates: string
}

const UpsertBranchControl: FC<Props> = (props) => {
    const branch = props.branch
    const coordinates = branch?.latitude && branch?.longitude ?
        `${branch.latitude},${branch.longitude}` : ''

    const {strings} = useResources()

    const [open, setOpen] = useState(false)
    const form = useForm<Fields>({
        defaultValues: {
            name: branch?.name || '',
            altName: branch?.altName || '',
            coordinates: coordinates
        }
    })
    // NOTE: Must call the isDirty before rendering in order to enable the state update.
    const isDirty = useMemo(() => form.formState.isDirty, [form.formState.isDirty])

    const onSubmit = form.handleSubmit(async fields => {
            if (!isDirty) {
                props.onClose()
                return
            }

            form.clearErrors()

            const coords = fields.coordinates.split(',')
            if (coords.length !== 2 || !Number(coords[0]) || !Number(coords[1])) {
                form.setError("coordinates", {message: strings("InvalidCoordinatesMessage")})
                return
            }

            const input: Branch = {
                id: branch?.id || '',
                name: fields.name,
                altName: fields.altName,
                latitude: Number(coords[0]),
                longitude: Number(coords[1])
            }

            const output = branch?.id ?
                await Api.branches.updateBranchAsync(input) :
                await Api.branches.createBranchAsync(input)

            if (output) {
                await props.onComplete(output)
            }
        }
    )

    return (
        <form onSubmit={onSubmit}>
            <VerticalFlexLayout spacing={2}>
                <GridItem className={"force-ltr"}>
                    <Controller
                        name="name"
                        control={form.control}
                        render={({field, fieldState: {error}}) => (
                            <InputField
                                fullWidth
                                required
                                label="Branch Name"
                                fieldError={error}
                                {...field} />
                        )}/>
                </GridItem>
                <GridItem className={"force-rtl"}>
                    <Controller
                        name="altName"
                        control={form.control}
                        render={({field, fieldState: {error}}) => (
                            <InputField
                                fullWidth
                                required
                                label="اسم الفرع"
                                fieldError={error}
                                {...field} />
                        )}/>
                </GridItem>
                <Controller
                    name="coordinates"
                    control={form.control}
                    render={({field, fieldState: {error}}) => (
                        <InputField
                            fullWidth
                            required
                            label={strings("Coordinates")}
                            fieldError={error}
                            {...field} />
                    )}/>
                <GridItem>
                    <Collapse in={open}>
                        <Box p={2}
                             bgcolor={`${colors.primary}10`}
                             borderRadius={1}>
                            <Body2 marginBottom={2} dangerouslySetInnerHTML={{
                                __html: strings("GetGoogleMapsCoordinatesSteps")
                            }}/>
                            <Button
                                size={"small"}
                                onClick={() => setOpen(ps => !ps)}>
                                {strings("GotIt")}
                            </Button>
                        </Box>
                    </Collapse>
                    <Collapse in={!open}>
                        <Button
                            startIcon={<InfoIcon size={16}/>}
                            size={"small"}
                            onClick={() => setOpen(ps => !ps)}>
                            {strings("GetGoogleMapsCoordinatesTitle")}
                        </Button>
                    </Collapse>
                </GridItem>
                <HorizontalFlexLayout
                    spacing={2}
                    alignItems={"center"}
                    justify={"flex-end"}>
                    <Button
                        onClick={props.onClose}>
                        {strings("Cancel")}
                    </Button>
                    <Button
                        type={"submit"}
                        variant={"contained"}>
                        {branch?.id ? strings("Save") : strings("Add")}
                    </Button>
                </HorizontalFlexLayout>
            </VerticalFlexLayout>
        </form>
    )
}

export default UpsertBranchControl
