import React, {CSSProperties, FC} from "react";
import {VerticalFlexLayout} from "@unipal/ui/flexLayout/FlexLayout";

interface Props {
    size?: "small" | "medium";
}

const Loading: FC<Props> = (props: Props) => {
    const size = props.size === "small" ? 16 : 20;
    const style: CSSProperties = {
        width: size,
        height: size
    };

    return (
        <div className="loader">
            <span style={style}/>
            <span style={style}/>
            <span style={style}/>
        </div>
    );
}

export const LoadingPage: FC = () => {
    return (
        <VerticalFlexLayout
            alignContent={"center"}
            justify={"center"}
            style={{height: "100%"}}>
            <Loading/>
        </VerticalFlexLayout>
    );
}

export default Loading;